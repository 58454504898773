.jobi{
    &-lg{
        font-size: 60px;
    }
}

/*.jobi{
    &:before{
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: cover;
    }

    &-lg:before{
        width: 80px;
        height: 80px;
    }

    &-alternator:before{
        background-image: url("../images/jobs/alternator.png");
    }

    &-alternator-and-belt:before{
        background-image: url("../images/jobs/alternator_and_belt.png");
    }

    &-control-arm:before{
        background-image: url("../images/jobs/control-arm.png");
    }

    &-deflector:before{
        background-image: url("../images/jobs/deflector.png");
    }

    &-exhaust-systems:before{
        background-image: url("../images/jobs/exhaust_systems.png");
    }

    &-floor-mat:before{
        background-image: url("../images/jobs/floor_mat.png");
    }

    &-front-brake:before{
        background-image: url("../images/jobs/front_brake.png");
    }

    &-light-bulb:before{
        background-image: url("../images/jobs/light_bulb.png");
    }

    &-oil-pan-gasket:before{
        background-image: url("../images/jobs/oil_pan-gasket.png");
    }

    &-other:before{
        background-image: url("../images/jobs/other.png");
    }

    &-power-steering:before{
        background-image: url("../images/jobs/Power_Steering.png");
    }

    &-rear-brake:before{
        background-image: url("../images/jobs/rear_brake.png");
    }

    &-shock-strut-replacement:before{
        background-image: url("../images/jobs/Shock-Strut-Replacement.png");
    }

    &-tire:before{
        background-image: url("../images/jobs/TIRE.png");
    }

    &-wheel-bearing:before{
        background-image: url("../images/jobs/Wheel_Bearing.png");
    }

}*/