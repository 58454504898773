.btn {
	cursor: pointer;
	position: relative;
	display: inline-block;
	padding: 12px 10px;
    margin-top: 20px;
	text-decoration: none;
	font-family: $font-family--secondary; 
	font-size: em($base__font-size);
	font-weight: $font-weight--bold;
	color: $white;
    text-align: center;
	vertical-align: middle;
	background: $color-primary; 
    transform-style: preserve-3d;
	overflow: hidden;
    text-transform: uppercase; 
    border-radius: 5px;
	&-square{
		border-radius: 0;
	}
	
    @media only screen and (min-width: $nohover-width) {
        &:not(.disabled):hover{
            text-decoration: none;

            &:after {
                text-decoration: none;
                opacity: 0;
                transform: scale3d(1,1,1) skew(25deg,0) translate3d(-100%,0,0);
            }


            &:after {
                opacity: 0.35;
                transform: scale3d(1.2,1.2,1.2) skew(25deg,0) translate3d(0,0,0);
            }
        }
    } 
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: $white;
        transform: scale3d(1,1,1) skew(25deg,0) translate3d(-100%,0,0);
        @extend %transition-base;
    }
    &.disabled {
        cursor: default;
        background-color: $gray-light;
        border: 0;
        &:hover {
            background-color: $gray-light;
        }
    }
	
	&:focus {
		outline: none;
	}
    &-background-shark{
            background: linear-gradient(0deg, rgba(49,50,54,1) 0%, rgba(49,50,54,1) 63%, rgba(49,50,54,1) 100%); /* w3c */
        }
	&--browse{
		float:left;
		margin-right: 10px;
	}
	
	&--upper {
		text-transform: uppercase;
	}
    
    &--text-centered {
        text-align: center;
    }
    
	&--icon{
		padding-left: 50px;

		&:before {
			position: absolute;
			color: $white;
			top:50%; 
			transform: translateY(-50%);
			font-family: $font-family--icon;
			font-weight: $font-weight--thin;
			z-index: 1;
		}

		&:after {
			padding-left: 20px;
		}

	} // close icon

    &--inverse{
        background: transparent;
        border: 1px solid $color-primary;
        @extend %transition-base;
        color: $black;
        margin-bottom: 20px;
        &:hover{
            background: $gray-base;
            color: $black;
        }
	}
	&--black{
        background: $black !important;
        @extend %transition-base;
        color: $white;
        margin-bottom: 20px;
        &:hover{
            background: $gray-base;
            color:$white;
        }
    }
    &--transparent{
        background: transparent;
        border: 1px solid $color-primary;
        @extend %transition-base;
        color: $white;
        margin-bottom: 20px;
         &:hover{
            background: $gray-base;
            color: $black;
        }

	}
	
	&--warning{
        background: #4eb48f !important;
        @extend %transition-base;
        color: $white;
        margin-bottom: 20px;

    }

	&--reset {
		position: relative;
		padding-top: 13px;
		padding-bottom: 13px;
		padding-left: 55px;
		width: 100%;

		&:before {
			content: "\f01e";
			position: absolute;
			left: 12px; top: 5px;
			font-size: 30px;
			font-family: $font-family--icon;
		}

	}

	&--search {
		width: 100%;
		text-transform: uppercase;
		text-align: center;

		padding: 5px;
		vertical-align: middle;

		&:before {
			content: "\f021";
			left: 0;
			font-size: 30px;
			font-family: $font-family--icon;
			padding-right: 20px;
		}

		&[data-js-sticky] {
			min-width: 150px;
			box-shadow:         2px 2px 17px 0px rgba(50, 50, 50, 0.5);
		}
	}

	

	&--view {
		width: 100%;
		text-align: center;

		@media screen and (min-width: $medium-width){
			position: absolute;
			z-index: 150;
			width: 85%;
			top: 0;
		}
		
		&:before {
			content: "\f03a";
			left: 15px;
			font-size: 20px;
			font-family: $font-family--icon;
			margin-right: 15px;
		}
	}

	&--download {
		width: 100%;
		margin: 20px 0;
		&:before {
			content: "\f019";
			left: 15px;
			font-size: 20px;
			font-family: $font-family--icon;
			margin-right: 15px;
		}
	}
    
    &--big {
        font-size: em(18px);
        
        @media only screen and (min-width: $small-width) {
            font-size: em(14px);
        }
        
        @media only screen and (min-width: $medium-width) {
            font-size: em(16px);
        }
	}
	
	&--sm{
		font-size: 0.8em;
		.fa{
			font-size: 1.2em;;
		}
	}
	
	&--xs{
		font-size: 0.8em;
		line-height: 1em;
		padding-top: 5px;
		padding-bottom: 5px;
		.fa{
			font-size: 1.2em;;
		}
	}
    
    &--left-align {
        text-align: left;
    }s

    &--fluid {
  		width: 100%;
  	}

	&.is-inactive {
        opacity: 0.25;
		cursor: default;
		color: #fff;
		background: rgba(166,166,166,0.5) !important;
	    user-select: none;
	    &:after {
	    	display: none;
	    }
	}

  &__submit {

  	&.profile-account {
  		width: 35%;
  		margin-left: 15px;
  		margin-right: 15px;
  	}

  }

  &__return {
  	float: right;
  }

  &__cancel {
  	text-align: center;

  	&.profile-account {
  		width: 35%;

  	}
  }

  &.js-iconpicker{
		padding: 2px 15px 2px 2px;
		width: 45px;
		height: 36px;
		position: relative;
		background-color: transparent!important;
		cursor: default;
		color: black;
	  	&:not(:disabled){
			border: 1px solid $gray-dark;
			cursor: pointer;
		  	&:before{
				content: "";
				position: absolute;
				pointer-events: none;
				cursor: pointer;
				border-color: #888 transparent transparent;
				border-style: solid;
				border-width: 5px 4px 0;
				height: 0;
				right: 7px;
				position: absolute;
				top: 50%;
				width: 0;
				transform: translateY(-50%);
			}
		}
	}


	&.js-media-image{
		img{
			width: 20px;
			height: 20px;
			object-fit: contain;
		}
		.fa{
			width: 20px;
			height: 20px;
			line-height: 20px;
    		display: inline-block;
		}
		&.disabled{
			.fa{
				color: black;
			}
		}
	}

	&-dropdown{
		position: relative;
		display: inline-block;
		z-index: 10;
		&-list{
			text-align: left;
			height: 0;
			overflow: hidden;
			display: inline-block;
			position: absolute;
			top:100%;
			left: 0;
    		right: 0;
			background-color: $white;
			border: 1px solid $gray-light;
			border-top: 0;
			border-bottom: 0;
			margin-top: -5px;
			z-index: -1;
			border-radius: 0 0 5px 5px;
			>*{
				padding: 5px 5px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: block;
				&:first-child{
					margin-top: 5px;
				}
				&:hover{
					background-color: $gray-light;
				}
			}
		}
		.btn{
			margin: 0 !important;
			width: 100%;
			border-bottom: 1;
		}

		&:hover{
			.btn-dropdown-list{
				height: inherit;
				border-bottom: 1px solid $gray-light;
			}
		}
	}
}

// Custom buttons

.btn-removal {
	border: 0;
	padding: 4px 8px;
	background-color: $color-warning;
	color: #fff;
	font-size: 12px;
	border-radius: 3px;
	font-weight: $font-weight--bold;
	cursor: pointer;
	@extend %transition-base;

	@media only screen and (min-width: $nohover-width) {
		&:hover {
			background-color: $color-warning-dark;
		}
	}
}
// slick-slider
.icon-arrow {
		width: 60px;
		height: 45px;
		background-repeat: no-repeat;
		display: block;
        -webkit-mask-image: url(/dist/images/arrow-left-color.svg);
        mask-image: url(/dist/images/arrow-left-color.svg);
        mask-repeat: no-repeat;
        background-color: $gray-base;
        background-size: 50%;
         @extend %transition-base;

	&--right {
		-webkit-transform:scaleX(-1);
        -moz-transform:scaleX(-1);
        -ms-transform:scaleX(-1);
        -o-transform:scaleX(-1);
    	transform:scaleX(-1);
	}
    &:hover{
        background-color: $color-primary; 
        @extend %transition-base;
        
    }
}
.slick-lightbox-close {
     @extend %transition-base;
    &:hover:before {
        color: $color-primary;
         @extend %transition-base;
    }
}
