.price-list-import-container {
  margin: 20px;
  overflow: hidden;
  .file-content-table-container {
    overflow: auto;
    display: block;
    height: 100%;
    width: 95vw;
    table {
      th,td {
        padding: 5px;
        text-align: center;
      }
    }

    
  }
}

.container-settings--pricelist{
  overflow: hidden;
}
