.container-search-tool{
    width: 100%;
    padding: 10px;
    left: 0;
    right: 0;
    .select2-selection__arrow b{
        display: none;
    }

    .container-partial--search-engine {
        padding: 1px 0 0;  
           
        @media screen and (min-width: $small-width) {
            background-color: transparent;
            min-height: inherit; 
        }
        @media screen and (min-width: $medium-width) {
        }
        button {
            text-transform: uppercase;
            @extend %transition-base;
           
            &:hover {
                cursor: pointer;
                @extend %transition-base;
                color: $white;
                background-color: $color-primary; 
            }
        }
        .border-bottom-0{
            @media screen and (min-width: $medium-width) { 
                border-bottom-width: 0!important;
            }
        }
        .border-bottom-1{
            @media screen and (min-width: $medium-width) { 
                border-bottom-width: 1px!important;
            }
        }
        
        .container-form .selectbox{
            @media screen and (min-width: $small-width) {          
                width: 32.7%;  
                margin-bottom: 0;  
            }
         
             &:after{
                border-color: #888 transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0;
                height: 0;
                right: 7px;
                position: absolute; 
                top: 45%;
                width: 0;
            }
            &.container-quantity:after{
                border-style: none;
            }
            select {
                 -webkit-appearance:none;
                -moz-appearance:none;
                -ms-appearance:none;
                appearance:none;
            }
        } 
        .container-icon{
            height: 40px;
            @media screen and (min-width: $medium-width) { 
                height: 55px;
            }
            width: 90px;
            &:hover{
                cursor: pointer;
            }
         }
        .container-panel-search-engine {
           
            .active {
                display: block!important;
            }
            .container-heavy-trucks {
                display: none;
                .container-trucker, .container-fleet-manager {
                    display: none;
                }
            }
            .container-recreational-vehicles{
                display: none;
                .container-vr {
                    display: none;
                }
            }
            .container-light-vehicles {
                display: none;
                .container-car-repair-shop, .container-motorist {
                    display: none;
                }
            }
            input[type='text'] {
                 padding: 15px 10px;
            }
           
        }
        
        .container-search-engine{
            margin: auto;
            z-index: 20;
            @media screen and (min-width: $small-width) {
                  top: inherit;
            }
            .container-search-fields{
                width: 100%;
                @media screen and (min-width: $small-width) {
                    display: inline-block; 
                }
            }
        }
        .tiredefinition{
            .letter{
                flex-grow: 0;
                padding: 0 10px;
                line-height: 50px;
                font-size: 28px;
                width: 40px;
                text-align: center;
                background: $color-primary;
                color:$white;
            }
            @media screen and (min-width: $small-width) {
                display: flex;
                flex-wrap: nowrap;
                >*{
                    flex-grow: 1;
                    
                    &:not(.letter){
                        //width: 100%;
                        width: 33%;
                    }
                    .selectbox{
                        flex-grow: 1;
                        width: inherit;
                    }
                }
                .letter{
                    margin-left: 5px;
                }
            }
        }
        .divider{
            border-bottom: 1px solid $gray-base;
            height: 1px;
            width: 100%;
            @media screen and (min-width: $small-width) {
                margin-top: 10px;
                margin-bottom: 10px; 
            }
            @media screen and (min-width: $medium-width) {
                width: 1px;
                height: 105px;
                margin: 0 50px;
                float: left;
                border-left: 1px solid $gray-base;
            }
        }
        .go-button, .go-button-validate{
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            //padding: 16px 20px;
            background-color: $gray-light;
            color: #000;
            font-family: $font-family--secondary;
            @media screen and (min-width: $medium-width) {
               // padding: 16px 20px;
            }
        }
        .go-button{
             &:hover{
                color: #FFF;
                cursor: pointer;
            }
        }
        .go-button-validate{
            color: $gray-base;
            padding: 10px;
            height: 50px;
            width: 60px;
            &:hover{
                cursor: default;
                background-color: $gray-light;
                color: $gray-base;
                }
            &.valid{
                background-color: $color-primary; 
                color: $white;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .input-part-number {
            width: 100%;
        }
        .loading-icon{
            font-size: 16px;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(255,255,255,.75);
            padding: 30px;
            color: #fff;
            margin-top: 5px;
            .fa-spin {
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                color: $color-primary;
            }
        }
        .tab-content {
            position: relative;
            color: $white;
            left:0;
            right:0;
            text-align: center;
            @media screen and (min-width: $small-width) {
                right: inherit;
                position: relative;
            }
            @media screen and (min-width: $medium-width) {
                text-align: left;
            } 
             .box--gray {
                h3,.h3 {
                    margin-top: 0;
                    font-size: 13px;
                } 
            }  
            .description {

                margin: auto;
                font-family: $font-family--secondary;
                font-size: 13px;
                z-index: 2;
                padding: 0 10px;
                position: relative;
                background-color: $color-secondary;
                display: inline-block;
                top: 5px;
                @media screen and (min-width: $medium-width) {
                    margin: inherit; 
                }
            }
             h3,.h3 {
                text-align: left;
                margin-top: 45px;
                margin-bottom: 20px;
                color: $color-secondary;
            }
            .tab-pane {
                width:100%;
                display: none;
               
                &.active {
                    display: block;
                    margin-bottom: 10px; 
                }
                 .make{
                    @media screen and (min-width: $xsmall-width) {
                        width: 100%;
                    }
                }
                .model{
                    @media screen and (min-width: $xsmall-width) {
                        width: 100%;
                    }
                }
                .search-by-garage-select{
                    @media screen and (min-width: $xsmall-width) {
                        width: 100%;
                    }
                    &:after{
                        border-color: #888 transparent transparent;
                        border-style: solid;
                        border-width: 5px 4px 0;
                        height: 0;
                        left: 50%;
                        margin-left: -4px;
                        position: absolute;
                        top: 50%;
                        width: 0;
                    }
                }
                .vin, .search-part-number-text{
                        width: -moz-calc(100% - 65px);
                        width: -webkit-calc(100% - 65px);
                        width: -o-calc(100% - 65px);
                        width: calc(100% - 65px);
                 }
            }
            .tab-pane-car-import-file .description{
                 margin-bottom: 17px;
            }
            .tab-menu {
                list-style: none;
                display: inline-flex;
                font-family: $font-family--secondary;
                margin-top: 10px;
                margin-bottom: 20px;
                width: 100%;
                .tab {
                    height: 42px;
                    text-align:center;
                    padding: 5px 10px;
                    background-color: $gray-light; 
                    border: 1px solid $gray-xdark;
                    color: $black;
                    @extend %transition-base;
                    font-size: 10px;
                    width: 50%;
                    &:not(:last-of-type){
                        border-right: 0;
                    }
                     @media screen and (min-width: $xsmall-width) {
                        font-size: 13px;
                     }
                    &:hover {
                        background-color: $gray-base;
                        color: $white;
                        @extend %transition-base;
                        cursor: pointer;
                    }
                    &.active,
                    &.active:hover {
                        background-color: $color-primary;
                        color: $white;
                    }
                    &__text{
                        transform: translateY(-50%);
                        top: 50%;
                        position: relative;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .container-search-tool {
        padding: 0;
       
    } 

    .selectbox .search-by-garage-select {
        height: 50px;
    }
   
    .select2-container .select2-selection--single{
        height: 50px;
    }
 
}
.container-search-tool--banner{
    .slick-lightbox-close{
        display: none;
    }
}
.select2-container--default .select2-results>.select2-results__options{
    max-height: 400px;
}
select.js-select-image + .select2{
    width: 65px!important;
    .select2-selection--single {
        padding: 2px 10px;
        height: 36px;
    }
}
.select-image{
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    img{
        vertical-align: middle;
        max-height: 30px;
    }
}
.container-search-tool--overlay{
    background-color: rgba(0, 0, 0, .5);
    z-index: 999;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}
.select2-container--default{
    &.select2{
        width: 100%!important;
    }
    .select2-selection--single{
            font-family: $font-family--secondary;
            border-radius: 0;
            padding: 15px 10px;
            //height: 36px;
            font-size: 14px;
        .select2-selection__rendered{
            line-height: inherit;
            padding: 0;
            color: $gray-base; 
        }
       
    }
}
.select2-results__option, .select2-search__field{
    font-family: $font-family--secondary;
    font-size: 14px;
}
.box--gray, .box--white,.box--transparent {
    background-color: #f5f5f5;
    color: $gray-base;
    border: 1px solid $gray-light;
    padding: 18px;
    h3 {
        margin-top: 0;
    } 
}
.box--white {
    background-color: $white;
    border: 0; 
}
.box--transparent {
    background-color: transparent;
}
.container-disclaimer {
    .box--gray {
        margin-bottom: 50px;
        margin-top: 50px;
        text-transform: uppercase;
        color: $gray-xdark;
        font-size: 18px;
        text-align: justify;
        font-family: $font-family--primary
    }
}
.container-search-tool-toggle {
    display: none;
    margin-top: 10px;
     .btn--send-criteria {
        //display: block;
        margin: auto;
        width: 280px;
        @media screen and (min-width: $small-width) { 
            display: inline-block;
            width: inherit;
        }
    }
    .box--gray {
        background-color: $gray-xlight;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }
    h3 {
        color: $gray-base;
        padding-top: 0;
    }
    .selectbox {
        width: 280px;
    }

}
.parttype-pop-header {
    color: $white;
    display: none;
    background-color: $color-secondary;
    margin: auto;
    left: 0;
    right: 0;

    h4 {
        font-size: 20px;
        font-weight: normal; 
    }
    .slick-lightbox-close {
        color: $white;
    }
}
.parttype-pop-footer {
    background-color: $color-primary--darken;
    display: none;
    margin-top: 0;
    border-radius: 0;
     margin: auto;
    left: 0;
    right: 0;
    &:hover {
        cursor: default; 
    }
    &:not(.disabled) {
        background-color: $color-primary;
        color: $white;
        &:hover {
            cursor: pointer; 
        }
    }
}
.selectbox {
    width: 100%;
    display: inline-block;
    text-align: left;
    position: relative;
    vertical-align: middle;
    margin-bottom: 10px;
  
    &:after {
        content: '';
        border-color: #888 transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0;
        height: 0;
        right: 7px;
        position: absolute;
        top: 45%;
        width: 0;
    }
    .select2-selection--single {
        height: 50px;
        padding: 15px 10px;
        outline: none;
        .select2-selection__arrow {
            top: 10px;
            display: none;
        }
    }
    
}
/*///////LOADING OVERLAY///////*/

 .loading-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        z-index: 1000;
    }
    .loading-icon-overlay{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-align: center;
    }
    .loading-icon-overlay i{
        position: relative;
        top: 45%;
        font-size:100px;
        color: $color-primary;
    }
.container-loading-interface{
    padding-top: 30px;
    max-width: 1000px;
    text-align: center;
    font-size: 20px;
    color:white;
    h1{
        font-size: 45px;
        @media screen and (max-width:$small-width - 1px){
            font-size: 20px;
        }
    }
    h2{
        font-size: 30px;
        margin-top: 40px;
        span{
            font-size: 40px;
        }
        @media screen and (max-width:$small-width - 1px){
            font-size: 18px;
        }
    }

    a:hover{
        color:$color-primary;
    }
    img{
        max-width: 100%;
    }
    p{
        margin: 50px 0;
        @media screen and (max-width:$small-width - 1px){
            margin: 30px 0; 
        }
        /*.where-to-buy{
            display: inline-block;
            position: relative;
            button,a[href]{
                background: transparent;
                font-size: 14px;
                position: absolute;
                right: 0;
                bottom: -20px;
                font-family: $font-family--primary;
                font-weight: normal;
                text-transform: uppercase;
                &:hover{
                    background: transparent !important;
                    text-decoration: none;
                }
            }
        }*/
    }
    p,.p {
        font-size: 16px;
        i.fa-map-marker{
            font-size: 2em;
            margin-right: 5px;
            vertical-align: bottom;
        }
    }
    .lenny-face{
        font-size: 100px;
        white-space: nowrap;
        //display: inline-block;
    }

    .container-prefilter{
        max-width: 600px;
        .cta-prefilter{
            text-align: left;
            margin-bottom: 20px;

            small{display: block;}
            .title{ 
                display: block; 
                color: $gray-base ;
                font-weight: bold;
                font-size: 1.2em
            }
            width: 100%;
            max-width: 250px;
            display: inline-flex;
            padding: 10px;
            border: 1px solid $gray-base;
            color:$gray-base;
            border-radius: 10px;
            text-decoration: none;

            .prefilter-icon:after{
                border-color: $gray-base;
            }

            /*@media screen and (max-width:$small-width - 1px){
                width: 100%;
                padding-left: 50px;
                position: relative;
                .prefilter-icon{
                    position: absolute;
                    left: 10px;
                    top:50%;
                    transform: translateY(-50%);
                    padding: 7px;

                    .fa{
                        font-size: 31px;
                    }
                    .icon-delivery{
                        display: none;
                    }
                    .icon-instore:before{
                        font-size: 27px;
                    }
                }
            }*/
            &[href]{
                border-color: $color-primary;
                color:white;
                .prefilter-icon:after{
                    border-color: $color-primary;
                }
                .title{
                    color: $color-primary;
                }
                &:hover{
                    small{color:white;}
                    color:$color-primary;
                    background: rgba($color-primary, 0.1)
                }
            }
        }
        .prefilter-icon{
            position: relative;
            display: inline-block;
            padding: 20px;
            transition: all 250ms ease;
            height: 100%;
            width: 110px;
            &.icon-delivery:before{
                font-size: 36px;
            }

            &.icon-instore:before{
                font-size: 70px;
            }

            .fa{
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 70px;
            }
            .icon-instore{
                &:before{
                    font-size: 40px;
                }
            }
            .icon-delivery {
                &:before{
                    font-size: 25px;
                }
            }

            @media screen and (max-width:$small-width - 1px){
                height: 45px;
                width: 45px;

                &.icon-delivery:before{
                    font-size: 14px;
                }
    
                &.icon-instore:before{
                    font-size: 25px;
                }
            }

            &:before{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            /*&:after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid $color-primary;
                border-radius: 50%;
            }*/
        }
        .link-checkbox,.link-checkbox-disabled{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            -webkit-transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;
            .fa{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                display: block;
                font-size: 120px;
                color: $color-primary;
                opacity: 0;
                -webkit-transition: all 500ms ease;
                -moz-transition: all 500ms ease;
                -ms-transition: all 500ms ease;
                -o-transition: all 500ms ease;
                transition: all 500ms ease;
            }
        }
        .link-checkbox:hover,.link-checkbox.checked{
            background: rgba($color-primary,0.1);
            
            .fa{
                opacity: 1;
            }
        }
        .link-checkbox-disabled{
            background: rgba($color-warning-dark,0.1);
            .fa{
                color: $color-warning-dark;
                opacity: 1;
            }
        }
    }
    .container-loading{
        font-size: 100px;
        color: $color-primary;
    }
}


.container-search-tool--modal{
    display: none;
    position: fixed;
    top: calc(50%);
    transform: translate(-50%,-50%);
    margin: auto;
    z-index: 1000;
    padding: 20px;
    width: 90%;
    left: 50%;
    padding-bottom: 30px;
    .container-search-tool{
        .container-partial--search-engine{
            padding: 0;
            .container-form {
                .container-search-fields{
                    @media screen and (min-width: $medium-width) {
                        @include clearfix();
                        margin: 0 -2.5px;
                    }
                }
                .selectbox {
                    width: 100%;
                    @media screen and (min-width: $medium-width) {
                        width: calc((100%/3) - 5px);
                        margin: 0 2.5px;
                        float: left;
                    }
                    @media screen and (min-width: $medium-width) {
                    // width: calc((100%/3) - 10px);
                    
                    }
                }
            }
            .tab-content{
                top: 0;
                position: relative;
                margin-bottom: -20px;
                .container-search-tool{
                    padding: 0;
                }
                .tab-pane .vin {
                    width: -o-calc(100% - 65px);
                    width: calc(100% - 65px);
                }
                .tab-menu{
                    .tab{
                        width: calc(50% );
                        margin-right: 7px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            } 
        }

    }
     input[type='text'] {
        padding: 15px 10px;
    }
    .slick-lightbox-close{
        right: 40px;
        top: 0px;
        height: 0;
        width: 0;
        &:hover{
            cursor: pointer;
        }
    }
    select {
        padding: 16px 10px!important;
    }
    .title{
        color: $white;
    }
}