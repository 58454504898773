.page-warning {
    padding: 60px;
    text-align: center;
    h1{
        color: $color-secondary;
        padding-bottom: 20px;
        font-size: 45px;
    }
    p{
        color: $color-secondary;
        font-family: $font-family--secondary;
    }
	&__404 {
        max-width: inherit;
        background-image: url(../images/404.jpg);
        background-size: cover;
        background-position: center;
        height: calc(100vh - 73px);
        h1,p{
            color: $white;
        }
        p{
            font-size: 20px;
            text-shadow: 2px 2px 4px black, -1px -1px 4px black;
        }
	}
    &__403 {

    }
}

.container-warning{
    background: $gray-xlight;
    color: $black;
    text-align: left;
    @include flex();
    flex-wrap: nowrap;
    padding: 5px;
    &-icon{
        font-size: 70px !important;
        padding: 0 15px;
        align-self: center;
        color: $gray-base ;
        //float: left;
    }
    &-text{
        p{
            color: $black;
            margin: 5px 0;
            a[href]{
                color: $color-primary;
                font-style: italic;
            }
        }
        
    }
}