
.container-best-sellers {
    background: #fff;

    .container-brand-part-type {
        text-align: center;
        border-right: 0;
        border-bottom: 0;
        position: relative;
        float: left;
        padding: 5px;
        width: 100%;
        .box{
            border: 1px solid $color-primary;
            padding: 20px;
            width: 100%;
            cursor: pointer;
            outline: 0px solid $color-primary;
            outline-offset: -0px;
            transition: all 150ms ease;
            &:hover{
                outline: 5px solid $color-primary;
                outline-offset: -5px;
            }
        }
        //padding: 40px;
        .container-img {
            position: relative;
            
            height: 150px;
            object,img {
                display: block;
                position: absolute;
                top:50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
        
        
        .info{
            margin-top: 10px;
            h3{
                margin-bottom: 0;
            }

            object,img {
                max-width: 100%;
                display: block;
                margin: auto;
                max-height: 52px;
            }

            .price{
                margin-top: 3px;
                border-top:1px solid $gray-light;
                padding-top: 3px;
                font-size: 20px;
                font-weight: bold;
            }
        }

        @media screen and (min-width: $small-width) {
            width: 33.3333%;
            .box{
                @include flex(column);
                height: 275px;
            }
            .container-img {
                height: initial;
                flex-grow: 1;
            }
        }

        @media screen and (min-width: $medium-width) {
            width: 20%;
            &:first-child{
                width: 40%;
                .box{
                    height: 560px;
                }

                .info{
                    object,img {
                        max-height: 102px;
                    }
                }
            }
        }
    }
    
}


.container-partial--best-sellers{
    .partial-title{
        padding: 0;
        display: inline-block;
        margin: auto;
        border-bottom: 3px solid  $color-primary;
        color: $black;

        @media screen and (max-width:$small-width) {
            font-size: 34px;
            width:100%;
        }
    }
}

.item-bestseller{
    &__container{
        list-style: none;
    }
    &__img{
        height: 150px;
        width: 100%;
        object-fit: contain;
    }
    &__title{
        text-align: center;
        margin: $spacing-base 0;
    }
}