.site-footer {
	//margin-top: 20px; 
	font-family: $font-family--secondary;
	font-size: 14px;
	text-align:center;
	position: relative;
    width: 100%;
    @media screen and (min-width: $small-width) {
       // margin-top: 0;
    }
	@media screen and (min-width: $medium-width) {
		text-align:left;
	}
	&__background-container{
		background-color: $color-primary;
	}

	&__copyright {
		padding-top: $spacing-base-vertical;
		padding-bottom: $spacing-base-vertical;
		background-color: $color-primary;
		font-size: em(12px);
		color: $white;
		text-align: center;
        .container-copyright{
            text-align: center;
            @media screen and (min-width: $medium-width) {
                text-align: left;
            }
        }
        .container-policy{
            text-align: center;
             @media screen and (min-width: $medium-width) {
                text-align: right;
            }
            a{
                display: block;
                margin-top: 10px;
                @media screen and (min-width: $medium-width) {
                    padding-left: 20px;
                    margin-top: 0;
                     display: inline-block;
                }
            }
        }
    }
    .open_hours {
        list-style: none;
    }
    .divider-menu {
        display: none;
        margin-left: 20px!important;
        border-left: 1px solid $white;
        @media screen and (min-width: $medium-width) {
            display: inline; 
        }
    }
	&__disclaimer{
		font-size: 12px;
		color: $gray-light;
	}   
	p {
		color: $white;
		margin-bottom:20px;
    }

    .site-footer-information {
        background: $gray-xdark;
        color: $gray-light;
        padding: 60px 0 15px;
        h2{
            font-size: 16px;
            margin: 0;
            text-transform: uppercase;
        }
        p{
            color: $gray-light;
            margin-top: 15px;
        }
        img{
            max-width: 100%;
        }
        .menu{
            list-style: none;
            text-transform: uppercase;
            font-size: 14px;
            li{
                margin:10px 0;
            }
        }
    }

    &.otto-site-footer{
        .site-footer__copyright{
            background-color: #252525 !important;
            color:$color-primary;
        }
        .container-copyright{
            opacity: 0.5;
        }
        .container-cards{
            img{
                max-width: 150px;
            }
        }

        @media screen and (min-width:$medium-width) {
            .container-menu{
                padding-left: 50px;
            }
        }
        @media screen and (max-width:$small-width - 1px) {
            .container-menu,.container-contact{
                -webkit-order: 1;
                -ms-flex-order: 1;
                order: 1;
            }

            .container-social-media, .container-cards{
                -webkit-order: 2;
                -ms-flex-order: 2;
                order: 2;
            }

            .container-policy{
                -webkit-order: 3;
                -ms-flex-order: 3;
                order: 3;
                font-size: 0.8em;
            }
        }
    }

    .container-social-media{
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            @include flex();
            li{
                display: inline-block;
                width: 33%;
                margin-bottom: 1.5em;
                a{
                    background: black;
                    color: white;
                    width: 51px;
                    height: 51px;
                    position: relative;
                    display: block;
                    border-radius: 50%;
                    font-size: 26px;
                    margin: auto;
                    .fa{
                        position: absolute;
                        top:50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }
            }
        }
    }
}
