.layout-why-sell{

    &.site-page__banner{
        height: 675px;
        position: relative;
        >*{
            color: $white;
            position: absolute;
            top:calc(50% + 50px);
            left:50%;
            transform: translate(-50%,-50%);
            text-align: center;
        }
        h1{
            color: $white;
            text-align: center;
            font-size: 80px;
            img{
                max-height: 85px;
            }
        }
    }
    .wysiwyg-container{
        h2{
            font-size: 40px;
            color: $color-primary;
            text-align: center;
            font-weight: bold;
        }
        h3{
            font-weight: normal;
            font-size: 30px;
        }
        
        p,ul,ol,table{
            font-family: $font-family--secondary;
            font-size: 16px;
            line-height: 1.6em;
        }
        p,table,li{
            margin-bottom: 20px;
        }
    }

    &-services{
        background-color: #ee6322;//$color-primary;
        color: white;
        padding-left: 30px;
        padding-right: 30px;
        h2,h3,h4{
            font-size: 30px;
            line-height: inherit;
            margin-bottom: 0;
        }
        p{
            font-size: 24px;
        }

        h2,h3,h4,p{
            color: white;
        }

        .text-number {
            font-family: Khand,"Helvetica Neue",Helvetica,Arial,sans-serif;
            font-size: 70px;
            color: #F28D00;
            font-weight: 600;
            //margin: 0 30px;

            margin-right: 20px;
            max-width: 100px;
        }

        .ico img {
            margin-right: 20px;
            max-width: 100px;
        }

        .row .flex{
            align-items: center;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    &.where-to-buy{
        margin-top: 53px;
        background: url(../images/banner-pricing.jpg);
        background-size: cover;
        .pellet{
            width: 300px;
            height: 300px;
            border-radius: 50%;
            background: $black;
            border: 8px solid $color-primary;
            box-shadow: 0px 20px 20px rgba($black, 0.5);
            //margin-bottom: -45px;
            margin: -24px 0;
            text-align: center;
            color: white;
            font-size: 35px;
            padding: 10px;
            text-transform: uppercase;
            img{
                max-width: 90%;
            }
            /*&:before{
                display: block;
                content: "";
                background: url(../images/map-marker-yellow.png);
                background-size: contain;
                height: 176px;
                width: 176px;
                margin: -70px auto -3px;
            }*/
        }

        .btn{
            font-size: 30px;
        }

        h2{
            cursor: default;
            &:hover{
                &:after{
                    display: none;
                }
            }
        }
        p{
            color: white;
        }
    }
}