@font-face {
  font-family: 'jobico';
  src:  url('../fonts/jobico.eot?6q1ujj');
  src:  url('../fonts/jobico.eot?6q1ujj#iefix') format('embedded-opentype'),
    url('../fonts/jobico.ttf?6q1ujj') format('truetype'),
    url('../fonts/jobico.woff?6q1ujj') format('woff'),
    url('../fonts/jobico.svg?6q1ujj#jobico') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="jobi-"], [class*=" jobi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jobico' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jobi-ac-blower:before {
  content: "\e90a";
}
.jobi-ac-fan:before {
  content: "\e90b";
}
.jobi-accessories:before {
  content: "\e90c";
}
.jobi-ball-joint:before {
  content: "\e90d";
}
.jobi-fluid:before {
  content: "\e90e";
}
.jobi-fuel-pump:before {
  content: "\e90f";
}
.jobi-fuel-tank:before {
  content: "\e910";
}
.jobi-hardware:before {
  content: "\e911";
}
.jobi-ignition-tuneup:before {
  content: "\e912";
}
.jobi-lift-support:before {
  content: "\e913";
}
.jobi-link-kit:before {
  content: "\e914";
}
.jobi-oil-pan:before {
  content: "\e915";
}
.jobi-oxygen-sensor:before {
  content: "\e91e";
}
.jobi-parking-brake-cable:before {
  content: "\e91f";
}
.jobi-rack-and-pinions:before {
  content: "\e920";
}
.jobi-spark-plug:before {
  content: "\e921";
}
.jobi-suspension-top-plate:before {
  content: "\e922";
}
.jobi-tie-rod-end:before {
  content: "\e923";
}
.jobi-timing-chain:before {
  content: "\e924";
}
.jobi-tools:before {
  content: "\e925";
}
.jobi-transmission:before {
  content: "\e926";
}
.jobi-window-motor:before {
  content: "\e927";
}
.jobi-wiper-linkage:before {
  content: "\e928";
}
.jobi-head-gasket:before {
  content: "\e909";
}
.jobi-heater-core:before {
  content: "\e907";
}
.jobi-exhaust:before {
  content: "\e908";
}
.jobi-brake-master-cylinder:before {
  content: "\e906";
}
.jobi-filter:before {
  content: "\e905";
}
.jobi-water-pump:before {
  content: "\e904";
}
.jobi-ac-condenser:before {
  content: "\e91d";
}
.jobi-emissions:before {
  content: "\e91a";
}
.jobi-drive-belt:before {
  content: "\e91b";
}
.jobi-oil-pump:before {
  content: "\e91c";
}
.jobi-valve-job:before {
  content: "\e900";
}
.jobi-wheel-bearing:before {
  content: "\e901";
}
.jobi-wheel-bearing-replacement-rear:before {
  content: "\e902";
}
.jobi-wheel-bearing-replacement-front:before {
  content: "\e903";
}
.jobi-valve-cover-gasket:before {
  content: "\e916";
}
.jobi-wiper:before {
  content: "\e917";
}
.jobi-radiator:before {
  content: "\e918";
}
.jobi-power-steering:before {
  content: "\e919";
}
.jobi-oil-pan-gasket:before {
  content: "\e92c";
}
.jobi-tire:before {
  content: "\e92d";
}
.jobi-suspension-rear:before {
  content: "\e92e";
}
.jobi-suspension-front:before {
  content: "\e92f";
}
.jobi-steering-and-suspension:before {
  content: "\e930";
}
.jobi-shock-strut-replacement:before {
  content: "\e931";
}
.jobi-rear-brake:before {
  content: "\e932";
}
.jobi-front-and-rear-brakes:before {
  content: "\e934";
}
.jobi-floor-mats:before {
  content: "\e935";
}
.jobi-oil-change:before {
  content: "\e951";
}
.jobi-muffler:before {
  content: "\e952";
}
.jobi-light-bulb:before {
  content: "\e953";
}
.jobi-lamp-replacement:before {
  content: "\e954";
}
.jobi-hose:before {
  content: "\e955";
}
.jobi-clutch:before {
  content: "\e957";
}
.jobi-engine-tuneup:before {
  content: "\e959";
}
.jobi-engine-replacement:before {
  content: "\e95a";
}
.jobi-engine-overhaul:before {
  content: "\e95b";
}
.jobi-deflector:before {
  content: "\e95e";
}
.jobi-cv-shaft:before {
  content: "\e95f";
}
.jobi-control-arm:before {
  content: "\e960";
}
.jobi-bulb-interior:before {
  content: "\e961";
}
.jobi-battery:before {
  content: "\e969";
}
.jobi-alternator:before {
  content: "\e96a";
}
.jobi-alternator-and-belt:before {
  content: "\e96b";
}
.jobi-alignment:before {
  content: "\e96c";
}
.jobi-ac-system-service:before {
  content: "\e96d";
}
.jobi-ac-compressor:before {
  content: "\e96f";
}
.jobi-ac-compressor-kit:before {
  content: "\e970";
}
