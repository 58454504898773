.loading{
    border: 1px solid $color-primary;
    width: 100%;
    height: 10px;
    padding: 2px;
    &-bar{
        background-color: $color-primary;
        height: 100%;
        width: 0%;
    }
}