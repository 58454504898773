.c--table{
    &-holder{
        background: #eaeaea;
        height: 500px;
        table{
            width: 100%;
            thead{
                background: #d3d3d3;
            }
            tbody tr:nth-child(2n){
                background: #eaeaea;
            }
            tbody tr:nth-child(2n+1){
                background: #f3f3f3;
            }
            tbody td{
                vertical-align: center;
            }
            th,td{
                text-align: left;
                padding: 5px;
                &.right{
                    text-align: right;
                }
                &:first-child{
                    padding-left: 20px;
                }
            }
            .btn{
                margin-top: 0;
                padding: 0 10px;
                line-height: 25px;
                &--sm{
                    font-size: 0.8em;
                    line-height: 1em;
                }
                &--xs{
                    font-size: 0.8em;
                    line-height: 1em;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }
        .clone{
            display: none;
        }
    }
    &-scroll{
        width: 100%;
        border-spacing: 0;
        height: 100%;
        th,
        td,
        tr,
        thead,
        tbody { display: block; }
        thead tr {
            /* fallback */
            width: 97%;
            /* minus scroll bar width */
            width: -webkit-calc(100% - 16px);
            width:    -moz-calc(100% - 16px);
            width:         calc(100% - 16px);
        }

        tr:after {
            content: ' ';
            display: block;
            visibility: hidden;
            clear: both;
        }

        tbody {
            height: calc(100% - 40px);
            overflow-y: scroll;
            overflow-x: hidden;
        }

        tbody td,
        thead th {
            width: 14.28571428571429%;
            float: left;
            padding: 5px;
        }

        tr th {
            line-height: 30px;
        }

    }

    &-row{
        &--dragable{
            max-width: 20px;
            min-width: 20px;
            width: 20px;
            height: inherit;
            cursor: move;
            padding: 0 !important;
            position: relative;
            &:before{
                position: absolute;
                top: 3px;
                left:3px;
                right: 3px;
                bottom: 3px;
                content: "";
                display: block;
                background-image: radial-gradient(black 1px, transparent 0);
                background-size: 4px 4px;
                background-position:center;
            }
        }
    }
    &-cell{
        &-action{
            white-space: nowrap;
        }
    }
}