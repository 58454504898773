.tpl {
    position: relative;
    &--maintenance {
        background-color: $color-primary;
        text-align: center;
        color: white;
        margin: 0;
        font-family: Arial;
        left: 0;
        right: 0;

        img {
            width: 300px;
        }
    }
    &.tpl--validation {
        text-align: center;
        .box--validation {
            width: 100%;
            height: 600px;
            overflow-y: scroll;

        }
    }
}