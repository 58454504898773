.c-smart-search{
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($black,.5);
    padding: 30px 30px;
    overflow: auto;
    @media screen and (min-width: $small-width){
        @include flex(column);
        flex-wrap: nowrap;
    }
    .container-part{
        background-color: white;
    } 
    &--modal{
        background: $white;
        max-width:$container-width;
        width: 100%;
        margin: 0 auto;
    }

    &--title{
        background: $color-secondary;
        color: $white;
        max-width:$container-width;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 10px 20px;
        font-size: 20px;
        @media screen and (min-width: $small-width){
            font-size: 30px;
        }
    }

    form.input-search-bar{ 
        input[type=search]{
            border-radius: 0;
            height: 45px;
        }
        button {
            height: 45px;
            border-radius: 0;
        }
    }

    .vehicule-definition{
        //border: 1px solid $color-primary;
        background: $gray-xdark;
        .field-group{
            //border-bottom: 1px solid $color-primary;
            padding: 10px 15px;
            select,input{
                height: 40px;
                width: 100%;
                padding: 5px;
                font-size: 16px;
                background: $white;
            }
            &.selectbox{
                .select2-selection{
                    padding: 0;
                    height: auto;
                    &__rendered{
                        padding: 5px;
                        height: 40px;
                        line-height: 30px;
                        font-size: 16px;
                    }
                    &__arrow{
                        top:0;
                        height: 100%;
                    }
                }
                &:after{
                    right: 20px;
                }
            }
        }
        button{
            min-height: 40px;
            border-radius: 0;
            padding: 0 20px;
            background: $color-primary;
            color: $white;
        }
        textarea{
            display: none;
        }

        @media screen and (min-width: $small-width){
            @include flex();
            flex-wrap: nowrap;
            .field-group{
                width: 33.333333%;
            }
        }
    }

    .smart-search{
        &-result{
            flex-grow: 1;
            overflow: auto;
            background: transparent;
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
            }
            .h2{
                color: $black;
                margin: 20px 0 10px;
                border-bottom: 1px solid $gray-light;
                font-size: 20px;
                font-weight: bold;
                .fa{
                    color: $gray-light;
                }
                padding: 20px 15px;
            }
            .h3{
                font-size: 16px;
                font-weight: normal;
                color: $black;
                margin: 20px 0 10px;
            }

            li .box{
                display: block;
                border: 1px solid $color-primary;
                padding: 20px;
                background: $white;
                font-weight: 700;
                text-align: center;
                height: 100%;
            }
            >.box{
                border: 1px solid $color-primary;
                padding: 0;
                background: $white;
                @media screen and (min-width: $small-width){
                    @include flex();
                    flex-wrap: nowrap;
                }
            }

            .tooltip {
                position: relative;
                display: inline-block;
                .tooltiptext {
                    visibility: hidden;
                    min-width: 200px;
                    background-color: $shark;
                    color: #fff;
                    text-align: left;
                    border-radius: 2px;
                    padding: 5px;
                    position: absolute;
                    z-index: 1;
                    font-size: 16px;
                    left: 50%;
                    bottom: -10px;
                    transform: translateX(-50%) translateY(100%);
                    font-weight: normal;
                    &:before{
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 5px 10px 5px;
                        border-color: transparent transparent $shark transparent;
                        content: "";
                        display: block;
                        position: absolute;
                        top: -10px;
                        left: 50%;
                        transform: translateX(-5px);

                    }
                }
                &:hover .tooltiptext {
                    visibility: visible;
                }
            } 

            .left-side{
                background: $gray-xlight;
            }
            .right-side{
                .h3{
                    padding:25px 30px;
                    margin: 0;
                }
                /*.brands .container-brand{
                    margin: 10px 30px;
                }*/
            }
            .container-category,.container-brands{
                ul{
                    margin-left: 20px;
                }
                >.categories>ul,>.manufacturers>ul{
                    margin-left: 0;
                    .submenu-handle,.manufacturer{
                        padding: 10px 0;
                        display: block;
                        cursor: pointer;
                    }
                    .js-icon{
                        font-size: 25px;
                    }
                }
            }
            .container-brand{
                border: 1px solid #cccecd !important;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                .chk{
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        
        &-product{
            @include flex();
            flex-wrap: nowrap;
            padding: 5px;
            border: 1px solid transparent;
            margin: 10px;

            .container-img{
                width: 70px;
                margin-right: 10px;
                img{
                    max-width: 70px;
                    max-height: 70px;
                    margin: 0;
                }
            }
            +.chk{
                position: absolute;
                top: 5px;
                left: 5px;
                width: 20px;
                height: 20px;
            }
            &.active{
                background: rgba($color-primary,0.1);
                border: 1px solid $color-primary;
            }

            +.cart-controller .btn{
                padding: 4px 14px;
                margin-left: 100px;
                font-size: 10px;
                &:not(.btn--inverse){
                    background: $color-primary !important;
                    margin-top: 0;
                    &:after{
                        display: none !important;
                    }
                    
                }
                &:disabled {
                    background: #666!important;
                }
            }
        }
    }
}