.corporate--registration {
  text-align: center;
  padding: 80px 20px;
  font-family: $font-family--secondary;
  position: relative;

  .add-succursale {
    display: none;
  }
  [data-container=succursales]{
    [data-container=succursale]:first-child{
      hr{
        display: none;
      }
    }
  }

  button {
    margin: 10px 0;
  }

  h1 {
    font-size: 38px;
    text-transform: none;
    font-weight: normal;
    font-family: 'open sans';
    @media screen and (min-width: $small-width) {
      font-size: 38px;
      text-transform: none;
    }
    font-family: 'open sans';
    font-family: $font-family--primary;
    color: $color-secondary;

  }


  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 10px;
    min-height: 40px;

    > [class*=col-] {
      > .selectbox {

        &:after {
          right: 0;
          margin: 0 10px 0 0;
        }
      }
    }
  }

  label {
    padding-bottom: 5px;
    margin-top: 5px;
  }

  .selectbox {
    display: block;

    select {
      width: 100% !important;
      @media screen and (min-width: $small-width) {
        width: 50%;
      }
    }

    &--no_arrow {
      &:after {
        display: none;
      }
    }
  }


  .container-description {
    padding-left: 0;
    padding-right: 0;
    @media screen and (min-width: $small-width) {
      padding-left: 20px;
      padding-right: 20px;
      position: absolute;
    }

  }

  &.container-partial--export {
    background-color: $color-secondary;
    z-index: 1001;
    position: fixed !important;
    width: 90%;
    margin: auto;
    top: 100px;
    padding: 25px;
    left: 0;
    right: 0;
    @media screen and (min-width: $small-width) {
      top: 200px;
      width: 500px;
    }

    .btn {
      width: 100%;
    }

    .chk-export {
      width: 25px;
      height: 25px;
    }

    .close-export {
      display: block;
      width: 30px;
      height: 30px;
      top: 0;
      right: 10px;

      &:before {
        font-size: 40px;
      }
    }

    p, label {
      font-family: $font-family--secondary;
      font-size: 13px;
      color: $white;
    }

    .selectbox {
      select {
        width: 100% !important;
      }

      &::after {
        display: none;
        border-color: transparent;
      }
    }

    .title {
      font-size: 20px;
      font-family: $font-family--primary;
      border-bottom: 1px solid $gray-light;
      color: $white;
    }
  }

  form {
    @media screen and (min-width: 500px) {
      margin: 0 auto;
    }

    .form-group {
      @extend %clearfix;
      position: relative;

      label:first-child {
        display: block;
        margin: 12px 0 0;
      }

      &.has-error {
        input[type=text], input[type=password], input[type=email], input[type=phone], input[type=number], select, textarea {
          border-color: $color-warning;
        }

        input[type=radio] + .radio-wrapper,
        input[type=checkbox] + .checkbox-wrapper {
          &:before {
            border-color: $color-warning;
          }

          &:after {
            color: $color-warning;
          }
        }

        input[type=radio] + .radio-wrapper:after,
        input[type=radio] + .checkbox-wrapper:after {
          background: $color-warning;
        }
      }
    }
  }

  .fields-enterprise {
    display: none;
  }

  &.has-error, .error {
    color: $color-warning;

    input[type=radio] + .radio-wrapper,
    input[type=checkbox] + .checkbox-wrapper {
      &:before {
        border-color: $color-warning;
      }

      &:after {
        background: $color-warning;
      }
    }
  }

  input[type=text], input[type=email], input[type=password], input[type=phone], input[type=number], textarea {
    background-color: $white;
    border: 1px solid $gray-base;
    color: $black;
    font-family: $font-family--secondary;
    font-size: 14px;
    padding: 16px 10px;

    &.small {
      padding: 0 10px;
      height: 40px;
      font-size: 12px;
    }

    &[readonly] {
      color: $base__font-color;
      background-color: rgba($white, 0.5);
    }

    @media screen and (min-width: $medium-width) {
      /*padding: 8px 10px;*/
    }
  }

  .message-sucess {
    padding-bottom: 20px;
    color: $color-secondary;
  }

  .selectbox {
    position: relative;
    vertical-align: middle;
    @extend %transition-base;
    display: block;
    margin-bottom: 10px;
    @media screen and (min-width: $small-width) {
      display: inline-block;
    }

    select {
      background-color: $white;
      color: $base__font-color;
      border: 0;
      margin: 0;
      height: 50px;
      border: 1px solid rgba($gray-base, 1);
      font-family: $font-family--secondary;
      font-size: 13px;
      @extend %transition-base;
      min-width: 100px;
      width: 100% !important;
      padding: 0px 10px;

      &.small {
        height: 40px;
      }

      &[disabled] {
        background-color: rgba($white, 0.5);
      }
    }

    &:after,
    &:before {
      //content: "";
      //position: absolute;
      //pointer-events: none;
      //cursor: pointer;
      //@extend %transition-base;
    }

  }

  #corporate-form {
    .info-bulle {
      transition: all .5s ease;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(25% - 40px);
      transform: translateY(0);
      z-index: 10;

      &__icon {
        position: absolute;
        top: 7px;
        font-size: 31px !important;
        left: -33px;
      }

      &__text {
        border: 1px solid #878787;
        padding: 10px;
        background: #fff;
        opacity: 0;
        transition: all .5s ease;
        transform: translateY(-100%) translateY(54px);
      }

      &:hover {
        .info-bulle__text {
          opacity: 1;
        }
      }
    }

    fieldset {
      padding: 10px;
      margin: 10px;
      //max-width: calc(50% - 20px);
      border: 1px solid;
      @media screen and (max-width: $small-width) {
        max-width: calc(100% - 20px);
      }
    }

    legend {
      font-size: 19px;
      text-align: left;
      margin: 10px;
      padding: 0 30px;
    }

    @media screen and (max-width: $small-width) {
      .info-bulle {
        display: none !important;
      }
    }

    .page-loader {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: fixed;
      background: rgba(255, 255, 255, .5);
      z-index: 1000;
      display: none;

      &.active {
        display: initial;
      }

      .js-loading-icon.loading-icon.color--primary {
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .btn__add {
      text-align: left;
      padding: 17px 10px;
      background-color: #f3f3f3;
      transition: all .5s ease;
      cursor: pointer;
      position: relative;
      margin-bottom: 2px;

      &.checked {
        &:after {
          content: '\f00c';
          font-family: 'FontAwesome';
          background: transparent;
          color: #F28D00;
          font-size: 30px;
          top: 37px;
          right: 10px;
          height: 100%;
          position: absolute;
          transform: translateY(-50%);
        }

        &:before {
          background: transparent;
        }
      }

      &.error {
        &:after {
          content: '\f12a';
          font-family: 'FontAwesome';
          font-size: 30px;
          top: 35px;
          right: -1px;
          height: 100%;
          position: absolute;
          transform: translateY(-50%);
          background: 0 0;
        }

        &:before {
          background: transparent;
        }
      }

      &:hover {
        background-color: #F28D00;

        &:before {

          transform: translate(0, -50%) scale(1.01);
        }

        &:after {

          transform: translate(0, -50%) scale(1.01);
        }
      }

      &:before {
        transition: all .5s ease;

        content: ' ';
        width: 2px;
        height: 30px;
        background: #F28D00;
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &:after {
        transition: all .5s ease;

        content: ' ';
        width: 30px;
        height: 2px;
        background: #F28D00;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .full-screen-view {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, .5);
      z-index: 10000;
      display: none;

      &.active {
        display: inline;
      }

      &__close {
        transition: all .5s ease;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        background: #aaa;
        transform: translate(50%, -50%);
        cursor: pointer;

        &:hover {
          background-color: #bbb;
        }

        &:after {
          content: '';
          width: 11px;
          transform: rotate(-45deg);
          left: 4px;
          top: calc(50% - 1px);
          height: 1px;
          background: #fff;
          position: absolute;
        }

        &:before {
          content: '';
          width: 11px;
          transform: rotate(45deg);
          left: 4px;
          top: calc(50% - 1px);
          height: 1px;
          background: #fff;
          position: absolute;
        }
      }

      &__background {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &__container {
        max-width: 450px;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 20px;

        &__vehicles {
          margin-bottom: 30px;
        }
      }

    }

    .custom-form {
      &__checkbox-label {
        text-align: left !important;
        font-size: 14px;
        width: calc(100% - 37px);
        display: block;
        float: left;
        cursor: pointer;
        padding-top: 8px;
      }

      &__checkbox {
        display: block;
        text-align: left;
        vertical-align: middle;
        width: 18px;
        margin-right: 10px;
        margin-top: 19px;
        float: left;

        label{
          span{
            transform: none;

          }
        }
      }
    }

    .radio-set {
      position: relative;
      padding-left: 10px;
      background: #f3f3f3;
      margin-bottom: 2px;
      transition: all .5s ease;

      &:hover {
        background-color: #F28D00;
        color: #375a21;
      }

      &__label {
        width: calc(100%);
        padding: 15px 50px 15px 17px;
        display: inline-block !important;

        cursor: pointer;
        text-align: left !important;
        transition: all .5s ease;
        margin-top: 0;
      }

      &__input {
        width: 0;
        height: 100%;
        float: left;
        position: absolute;
        top: 0;
        right: 0;
        transition: all .2s ease;
      }
    }

    .main-loader {
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;

      &__list {
        width: 32px;
        left: 100%;
      }
    }

    .container-description {
      .box--gray {
        font-size: 18px;
        text-align: justify;
        font-family: $font-family--primary;
        text-transform: uppercase;
        margin: 20px 0;

        img {
          height: 40px;
        }
      }
    }


    input[type=checkbox], input[type=radio] {
      border: 1px solid #878787;
    }

    input[type=radio]:checked {
      background-color: black;
    }

    &.download-pdf, &.share-page {
      width: 350px;
      z-index: 99;
      position: fixed;
      margin: auto;
      left: 0;
      right: 0;
      background: $gray-light;
      padding: 20px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      font-family: $font-family--secondary;

      button {
        margin-top: 20px;
        font-family: $font-family--primary;
        font-size: 23px;
      }

      input, textarea {
        margin-left: 0;
        margin-right: 0;
        font-family: $font-family--secondary;
        background-color: white;
        margin-bottom: 10px;
        padding: 10px;
        margin-top: 10px;
      }

      .fa-close:hover {
        cursor: pointer;
      }
    }

    .js--fake_input_number {
      background-color: #FFF;
      border: 1px solid #F28D00;
      color: #313236;
      font-size: 14px;
      height: 28px;
      padding: 0;
      width: 100%;

      .js--fake_input_control {
        height: 100%;
        margin-left: 5px;
        flex: none;

        .more, .less {
          width: 21px;
          height: 50%;
          position: relative;
          display: block;
          cursor: pointer;
          border-left: 1px solid #F28D00;

          &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .more:before {
        border-width: 0 5px 10px 5px;
        border-color: transparent transparent #F28D00 transparent;
      }

      .less:before {
        border-width: 10px 5px 0 5px;
        border-color: #F28D00 transparent transparent transparent;
      }

      input[type=number] {
        //color: $color-primary;
        float: right;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 20px;
        //font-weight: 700;
        margin-top: -10px;
        padding: 5px 10px;
        width: 60px;
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
        margin: 0;
        outline: 0;
        border: 0 !important;
        max-height: 100%;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      @media #{$phone-landscape-size}{
        height: 40px;
        .js--fake_input_control {
          .more, .less {
            height: 100% !important;
            float: right;
            width: 39px;

            &:after {
              content: '';
              border: 1px solid #F28D00;
              border-radius: 30px;
              top: 50%;
              left: 50%;
              height: 24px;
              width: 24px;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              display: block;
              position: absolute;
            }

            &:before {
              border: 0 !important;
              height: inherit !important;
              width: inherit !important;
              font-style: normal;
              font-size: 27px;
              text-align: center;
              color: #F28D00;
            }
          }

          .more {
            &:before {
              content: "+" !important;
            }
          }

          .less {
            &:before {
              content: "_" !important;
              transform: translate(-50%, -50%) translateY(-11px) !important;
            }
          }
        }

      }
    }

    fieldset {
      padding-left: 20px;
      padding-right: 20px;
    }

    /**/
    .fieldset-full-width {
      width: 100%;
      margin: 0;
      max-width: 100%;
    }

    .disclaimer {
      color: #aaa;
      font-size: 12px;
      text-align: left;
      line-height: 1.3;
      margin-top: 20px;
    }

    .float-none {
      float: none !important;
    }

    input[type=radio],
    input[type=checkbox] {
      display: none;
    }

    input[type=radio]:checked + .radio-wrapper,
    input[type=checkbox]:checked + .checkbox-wrapper {
      background-color: #7bca4b;

      &:after {
        content: ' ';
        width: 15px;
        display: block;
        height: 15px;
        //border: 1px solid #F28D00;
        background: #F28D00;
        top: 50%;
        right: 6px;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }
    input[type=radio]:checked + .radio-wrapper:after{
      border-radius: 100px;
    }

    .radio-wrapper,
    .checkbox-wrapper {
      position: absolute;
      transform: translate(-10px, -50%);
      top: 50%;
      right: 0;

      &:after {
        width: 0px;
        height: 0px;
        display: block;
        background: #F28D00;
        top: 50%;
        right: 18px;
        transition: all .2s ease;
        transform: translate(-100%, -50%);
      }

      &:before {
        content: ' ';
        width: 21px;
        display: block;
        height: 21px;
        border: 1px solid #F28D00;
        background: #fff;
        top: 50%;
        right: 0;
        position: absolute;
        transform: translate(-50%, -50%);
        transition: all .5s ease;
        cursor: pointer;
      }
    }

    .radio-wrapper:before {
        border-radius: 100px;
    }

    .form-group-captcha {
      margin: 0 auto;

      .disclaimer {
        margin-top: 0;
        text-align: center;
      }
    }

  }

}