.container-contact {
	font-family: $font-family--secondary;
	button{
		width: 100%;
		@media screen and (min-width: $small-width) { 
			width: 50%;
			margin-left: auto!important;
			margin-right: auto!important;
		}
		@media screen and (min-width: $medium-width) {
    		float: right;
    		width: 100%;
    	}
    }
	&-nc{
		@media screen and(min-width: $large-width) {
			  max-width: $xlarge-width;
			  margin-right: auto;
			  margin-left: auto;			
		}
	}
    .contact-form-success {
        max-width: 400px;
        text-align: center;
        margin: auto;
        margin-bottom: 20px;
        padding: 5px;
        background-color: $color-secondary;
        color: $white;
    }
    .container-address{
    	text-align: left;
    	padding-bottom: 20px;
    	
    	@media screen and (min-width: $medium-width) {
    		text-align: left;
    		padding-bottom: 0;
    	}
    	button{
    		float: none;
    		display: block;
    		@media screen and (min-width: $small-width) {
    			
    		}
   		 }
   		 h2{
   		 	padding-bottom:10px; 
			&.title{
				font-size: 4em;
				line-height: 1;
			} 
   		 }
		a{
			color: $color-primary;
			&.tel{
				color:$black;
				text-decoration: none;
				&:hover{
					color: $color-primary;
				}
			}
		}
		.paragraph{
			font-size: 22px;
		}
    }	
	.container-info{
		font-size:18px;
	}
	.container-content{
		padding: 20px;
		@media screen and (min-width: $small-width) {
			padding: 60px;
		}
	}
	.google-map{
        width: 100%;
        height: 400px;
	}

	label {
		text-align: left;
		   padding-left: 0;
		@media screen and (min-width: $small-width) {
    		text-align: right;
    	}
    }
    .selectbox{
    	padding: 0; 

    	select{
    		width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
        }
         &:after {
            margin-top: 55px!important;
            @media screen and (min-width: $small-width) {
                margin-top: 15px!important;
            }
        }
    }
    textarea{
    	height: 105px;
    }
    .wysiwyg-content,.wysiwyg-container{
		padding-bottom: 40px;
        text-align: left;
	}    
	
}