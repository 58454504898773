.container-cart {
    background-color: $white;
    font-family: $font-family--secondary;
    .cart-header{
         text-align: center;
        @media screen and (min-width: $small-width) {
            text-align: left;
        }
        .notice {
            display:block; 
            width:100%;
            padding-top:2px;
            font-size:16px;
            clear:both;
            color:$black;
            text-align: center; 
        }

    }
    .cart-listing {
        padding: 40px 0px;
        .btn-empty-cart, .btn-continue-shopping {
            width: 100%;
            margin-bottom: 0;
            @media screen and (min-width: $small-width) {
                width: auto;
            }
       }
       .btn-empty-cart {
            @media screen and (min-width: $small-width) {
                float: right;
            }
       }
        .item-category {
            margin-bottom: 40px;
            .item-category-header {
                clear:both;
                line-height:40px;
                vertical-align: middle;
                text-align: center;
                height: 120px;
                margin-bottom: 10px;
                padding: 10px; 
                @media screen and (min-width: $small-width) {
                    text-align: left;
                    height: 50px;
                    padding: 0;
                }
               .btn-shop-more {
                    float: none;
                    line-height:30px;
                    vertical-align:middle;
                    width: 100%;
                    font-size:14px;
                    margin-top:0;
                    padding: 10px;
                   
                    @media screen and (min-width: $small-width) {
                        float:right;
                    }
                }
                .vehicle{
                    font-size: 20px;
                    color: $color-primary;
                }
            }
        }
        .container-btn-shop-more, .vehicle{
            @media screen and (min-width: $small-width) {
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .item-list {
            width: 100%;
            font-size: 14px;
            @media screen and (min-width: $small-width) {
                font-size: 16px;
            }
            hr{
                background-color: $gray-light;
            }  
            .item{
                color: $color-secondary;
                padding-top: 10px;
                
                .item-part-type-number, .item-manufacturer, .item-unit-price, .item-quantity, .item-total, .item-delete, .item-price, .item-description, .item-part-type, .item-store{
                    padding: 10px;
                    font-size: 12px;
                    @media screen and (min-width: $medium-width) {
                        font-size: 16px;
                        text-align: center;
                    }
                }
                .item-description {
                    text-align: left;
                }
                .item-part-type {
                    text-align: left;
                    @media screen and (min-width: $small-width) {
                        text-align: center;
                    }

                }
                .item-quantity {
                    .fa-spin {
                        display: inline-table;
                        color: $color-primary;
                    } 
                    input{
                        border: 1px solid #DCDCDC; 
                        text-align: right;
                        width: 30px; 
                        padding: 5px;
                        @media screen and (min-width: $small-width) { 
                            width: 40px; 
                            padding: 0;
                        }
                    }
                }
                .item-price, .item-unit-price,  .item-total{
                    text-align: center; 
                }             
                .item-part-type-number{
                     font-weight: bold;
                     white-space: nowrap;
                      @media screen and (min-width: $small-width) {
                        font-weight: normal; 
                     } 
                }
                .item-delete{
                    text-align: center;
                    
                }
             
                &:not(:last-of-type){
                    border-bottom: 1px solid $gray-light;
                }
                
                td {
                    padding: 10px;
                }
                &.wait-to-merge td {
                    background-color:#ffb900;
                }
                .actions i {
                    font-size:24px;
                    cursor:pointer;
                    color: $color-primary;
                }
            }
        }
        .no-items {
            display:block;
            width: 100%;
            text-align:center;
            line-height:100px;
            vertical-align:middle;
            font-style: italic;
            font-size: 18px;
           
            
        }
        .item-labels{
            text-align: left;
            font-size: 12px;
            border-bottom: 1px solid $gray-light;
            @media screen and (min-width: $medium-width) {
                font-size: 16px;
            }
            .item-label{
                padding: 10px;
                font-weight: bold;
                text-align: center;
                
            }
        }
    }
    .fa-map-marker {
        font-size: 24px;
        cursor: pointer;
     }
     .fixed-element {
        width: 100%;
     }
    
    .grand-total {
        height:50px;
        border-top: 1px solid $gray-light;
        font-size: 12px;
            @media screen and (min-width: $medium-width) {
                font-size: 20px;
            }
     
        .amount, .total-label{
            text-align: right;
            top: 50%;
            transform: translateY(-50%); 
            font-weight:bold;
            padding: 10px;
             @media screen and (min-width: $small-width) {
                text-align: center;
             }
           
        }
        .total-label{
            @media screen and (min-width: $small-width) {
                text-align: right;
            }
        }
    
    }
    .logo-stripe, .logo-credit-cards {
        width: 150px;
    }
    .mobile-height-resize {
        
        @media screen and (max-width: $small-width) {
            height: inherit;
        }
    }
    .no-items {
        margin: 0;
    }

    .sub-amount{
        text-align: left;
        @media screen and (min-width: $small-width) {
            text-align: right;
        }
    }

    .toolbar {
        background: $gray-xlight;
         @media screen and (min-width: $medium-width) {
            padding: 20px;
        }
         .action-buttons {
            text-align: center;
            margin-top:30px;
            @media screen and (min-width: $small-width) {
                text-align: initial; 
            }
           
             .btn {
                width: 100%;
                margin: 10px 0;
                height: 80px;
                text-align: center;
                @media screen and (min-width: $small-width) {
                    padding: 25px;
                    text-align: inherit;
                }
            }
            .container-toggle-applicative{
                margin-top: 11px!important;
                height: 80px!important;
                font-size: 14px!important;
                border: 1px solid #DCDCDC!important;
                background-color: #DCDCDC!important;
            }
            input:checked+.hide-pricing:not(.disabled){
                background-color:$color-primary;
                &:hover{
                    background-color: $color-primary;
                }
            }
        } 
         button.btn-export, button.btn-continue-shopping {
            @media screen and (min-width: $small-width) {
                padding-right: 30px!important;
            }
        }
         .col-md-5 button.btn-export {
            @media screen and (min-width: $small-width) {
                text-align: right!important;
            }
        }
         .container-button {
            button.btn-back {
                 @media screen and (min-width: $small-width) {
                    padding: 25px; 
                    background-image: url(/dist/images/arrow-left-white.svg);
                    background-repeat: no-repeat;
                    background-size: 22%;
                    background-position: left 10px center;
                    padding-left: 30%;
                }
            }
             button.btn-order-now {
                background-image: url(/dist/images/arrow-right-white.svg);
                background-position: right 10px center;
                background-repeat: no-repeat;
                background-size: 22%;
            }
        }
        h2 {
            font-family: $font-family--primary;
            color: $color-secondary;
        }
    }
 }
.container-confirmation{
    background-color: $white;
     .item-labels{
        text-align: center;
        font-weight: bold;
        padding: 5px 0; 
        border-bottom: 1px solid $gray-light;

        .item-label{
             padding: 10px;
              font-size: 10px;
             @media screen and (min-width: $medium-width) {
                font-size: 16px;
             }
            
            
        }
    }
 
    .item-list{
         
        .item-row{
             border-bottom: 1px solid $gray-light;
             padding-bottom: 10px;

            div{
                padding: 0 10px;
            }
        } 
    }
    .price-row div{
            padding: 0 10px;
        }
  
}

.delivery-method-radio {
    margin-top: 5px;
}