.nav {
   &__container--lang {
        font-weight: bold;
        color: $color-primary;
        font-size: 15px;
        z-index:1;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            padding-left: 17px;
            text-align: left;
        } 
        a:not(:last-of-type):after {
            content: "|";
            font-weight: normal; 
            color: $gray-base; 
            padding-left: 5px;
            padding-right: 5px;
        }
   }
   &__container--landing{
       img{
           width: 100px;
            @media screen and (min-width: $medium-width) {
                width: 200px;
            } 
        }

        @media screen and (max-width: $medium-width - 1px) {
            text-align: center;
        } 
   }
   .nav__container--change-location{
        button{
            padding-left: 16px;
            position: relative;
            margin-top: 6px;
            cursor: pointer;
            i.fa{
                position: absolute;
                top:50%;
                left: -4px;
                transform: translateY(-50%);
                font-size: 18px;
            }
        }
    }
}