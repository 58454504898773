.container-partial {
    padding: 10px;
    @media screen and (min-width: $small-width) {
        padding: 60px;
    }
    &--help {
        background-color: $color-secondary;
        position: absolute;
        width:100%;
        padding: 10px;
        z-index: 99;
        color: #fff;
        padding: 20px;
        display:none;
        top: 50px;
        position: absolute;
        padding-top: 10px;
         @media screen and (min-width: $small-width) {
            top: 45px;
            width: 250px; 
            height: auto;
        }
        .btn {
            width: 100%; 
            &.disabled {
                opacity: 0.5;
                background-color: $color-primary;
                &.btn--inverse {
                    background-color: $color-secondary;
                    border: 1px solid $color-primary;
                }
                &:hover {
                    cursor: default;
                }
            }
        } 
        .button-back-menu {
            display:block;
            float: right;
            bottom: 5px;
            position: relative;
            cursor:pointer;
            @media screen and (min-width: $small-width) {
                display:none; 
            }
        }
        .fa-arrow-circle-o-left {
            font-size: 25px;
            vertical-align: middle;
             @media screen and (min-width: $small-width) {
                display: none;
            }
        }
        .close-help {
            display: none;
            font-style: normal;
            @media screen and (min-width: $small-width) {
                display: block;
                width: 30px;
                height: 30px;
                top: 0;
                right: 0;
            }
            &:before {
                font-size: 30px;
            }
            &:hover{
                cursor: pointer;
            }
        }
        .text:first-of-type {
            padding-top: 10px;
        }
        .title {
            font-size: 20px;
        
        }

        .phone{
            font-size: 1.5em;
            font-weight: bold;
        }
    }
    &--modal-session-expired {
        z-index: 99;
        background-color: $color-secondary;
        padding: 20px;
        position: absolute;
        box-shadow: 0 10px 10px rgba(0,0,0,.35);
        margin: auto;
        left: 0;
        right: 0;
        width: 500px;
        height: 300px;
        .slick-lightbox-close {
            cursor: pointer;
            display: block;
            width: 30px;
            height: 30px;
            top: 0;
            right: 10px;
            &:before {
                font-size: 40px;
            }
        }
        .title {
            font-size: 20px;
            font-family: Khand,"Helvetica Neue",Helvetica,Arial,sans-serif;
            border-bottom: 1px solid #DCDCDC;
            color: #FFF;
        }
    }
    &--my-profile {
        background-color: $color-secondary;
        width:100%;
        padding: 10px;
        z-index: 99;
        color: #fff;
        padding: 20px;
        display:none;
        top: 50px;
        position: absolute;
        padding-top: 10px;
        box-shadow: inset 0 7px 20px -7px #000;
         @media screen and (min-width: $small-width) {
            top: 45px;
            width: 250px; 
            height: auto;
        }
         .btn {
            &.disabled {
                opacity: 0.5;
                &.btn--inverse {
                    background-color: $color-secondary;
                    border: 1px solid $color-primary;
                }
                &:hover {
                    cursor: default;
                }
            }
        } 
         .title {
            font-size: 20px;
             border-bottom: 1px solid $gray-light;
        } 
    }
    &--my-garage, &--help, &--subscription,&--change-location {
        background-color: $color-secondary;
        width:100%;
        padding: 10px;
        z-index: 99;
        color: #fff;
        padding: 20px;
        display:none;
        top: 50px;
        position: absolute;
        box-shadow: inset 0 7px 20px -7px #000;
         @media screen and (min-width: $small-width) {
            top: 45px;
            width: 250px; 
            height: auto;
            box-shadow: none;
        }
        button{
            cursor: pointer;
        }
        .addvehicule {
            width: 100%;
            margin-top: 10px;
        }
        .add-a-vehicule {
            margin-top: 15px;
            .box--gray {
                background-color: transparent;
                border: none;
                padding: 0;
            }
             h3,.h3 {
                display: none;
            }
        }
        .output{
            display: block;
            text-align: center;
        }
        .button-back-menu {
            float: right;
            font-size: .8125em;
            color: $color-primary; 
            text-transform: uppercase;
             @media screen and (min-width: $small-width) {
                display:none; 
            }
        }
        .fa { 
            font-size:16px;
        }
        .fa-angle-left {
            color: $color-primary;
             @media screen and (min-width: $small-width) {
                display: none;
            }
        }
        .close-my-garage {
            display: none;
            font-style: normal;
            @media screen and (min-width: $small-width) {
                display: block;
                width: 30px;
                height: 30px;
                top: 0;
                right: 0;
            }
            &:before {
                font-size: 30px;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .removevehicule, .removelocation{
            float:right;
        }
        select, .select2-selection--single {
            background-color: $white;
            width:100%;
            margin-bottom: 20px;
            color: $gray-base;
            font-family: $font-family--secondary;
            font-size: 13px;
            padding: 16px 10px;
            height: inherit;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none; 
             @media screen and (min-width: $small-width) {
                 padding: 8px 10px;
                margin-bottom: 5px;
            }
        }
        .text-my-garage {
            margin-bottom:10px;
        }
         .title {
            font-size: 20px;
             border-bottom: 1px solid $gray-light;
        } 
        .vehicules {
            font-family: $font-family--secondary;
            font-size: 13px;
            line-height: 20px;
             padding-top: 10px;
          
            .vehicule-data:hover {
                cursor: pointer;
                text-decoration: underline;
            }
            .vehiculerow {
                margin-bottom: 5px;
                font-size: 14px;
                @media screen and (min-width: $small-width) {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }
    }
    &--my-garage {
        .selectbox:after {
            @media screen and (max-width: $small-width) {
              top: 35%;
            }
        }
    }
    &--promo{
        background: $black;
    }
    &--promo, &--promo-landing {
        height: 100%;
        background-image:none;
        background-size:cover;
        //position: relative;
        //background-position: 50%;
        .full{
            background-size: cover;
            //background-position: center;
        }
        a {
            font-size: 20px;
            color: $white;
            @extend %transition-base;
            &:hover {
                color: $color-secondary;
                text-decoration: none;
                @extend %transition-base;
                .fa {
                     color: $color-secondary;
                     @extend %transition-base;
                }
            }
        }
        .fa {
            vertical-align: middle;
            margin-bottom: 25px;
            color: white;
            @extend %transition-base;
        }
        h1 {
            font-size: 60px;
        }
        img {
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: auto;
            margin-bottom: 0px;
        }
        &-mobile {
            background-color: $color-primary;
            z-index: 2;
            position: relative;
            .fa {
                vertical-align: middle;
                margin-bottom: 25px;
            }
            h1 {
                font-size: 30px;
            }
        }
        p {
            font-size: 20px;
        }
        .strap {
            background-color: rgba(0,0,0,0.75);
            top: 50px;
            position: relative;
            text-align: center;
            font-size: 55px;
            padding: 40px;
            color: white;
            margin: 0 100px;
         }
    }
     &--promo-landing {
        background-image:url('/dist/images/bg.svg');
    }
    h2 {
        text-align: center;
        font-family: $font-family--primary;
        color: $color-primary;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 3px;
        padding-bottom: 20px;
        font-size: 30px;
        .color-secondary {
            color: $color-secondary;
        }
    }
    h3,.h3 {
        text-align: center;
        font-family: $font-family--secondary;
        letter-spacing: 1px;
        color: $black;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        margin-top:10px;
        padding: 0;
        line-height: inherit;
    }
    h4 {
        color: $color-secondary;
        font-size: 22px;
        text-transform: uppercase;
    }
    &--registration {
        .browser-alert {
            font-family: $font-family--primary;
            line-height: 10px;
            padding: 5px;
            padding-bottom: 10px;
        } 
        .fa {
            vertical-align: middle;
            margin-bottom: 5px;
            font-size :22px;
            margin-right: 5px;
        }
    }
    &--registration-confirmed {
        .container-description {
            position: absolute;
            bottom: 40px;
        }
    }
    &--search-engine {
        h4{
            font-size: 24px; 
            @media screen and (min-width: $small-width) {
                font-size: 36px; 
            }
        }
        h1 {
            text-transform: uppercase;
            color: $gray-xdark;
            font-size: 50px;
            border-bottom: 1px solid $color-primary;
        }
    }
    &--login, &--registration, &--registration-confirm, &--registration-confirmed, &--forgot-password, &--reset-password {
        top: 20px;
         @media screen and (min-width: $small-width) {
            top: inherit;
        }
        h1 {
            text-transform: uppercase;
            border-bottom: 1px solid $color-primary;
            font-size: 50px;
            color: $color-secondary; 
        }
    }
    &--registration-confirm {
        padding: 20px;
       .container-description {
            bottom: 40px;
            position: absolute;
             left: 0;
            right: 0; 
       }
    }
    &--subscription {
        background-color: $color-secondary;
        position: absolute;
        width:100%;
        padding: 10px;
        z-index:400;
        color: #fff;
        padding: 20px;
        display:none;
        position: absolute;
        padding-top: 10px; 
         @media screen and (min-width: $small-width) {
            top: 45px;
            width: 250px; 
            height: auto;
        }
        .close-subscription {
            display: none;
            @media screen and (min-width: $small-width) {
                display: block;
                width: 30px;
                height: 30px;
                top: 0;
                right: 0;
            }
            &:before {
                font-size: 30px;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .fa-arrow-circle-o-left {
            font-size:25px;
            vertical-align: middle;
             @media screen and (min-width: $small-width) {
                display: none;
            }
        }
        .btn {
            width: 100%;
        }
         .fa-close {
            position: relative;
             @extend %transition-base;
             display: none;
              @media screen and (min-width: $small-width) {
                display: block;
              }
            &:hover {
                cursor: pointer;
                color: $color-primary;
                @extend %transition-base;
            }
        }
        p {
            font-family: $font-family--secondary;
            font-size: 13px;
            a {
                font-weight: bold;
                color: $color-primary;
            }
            &.text {
                border-top: 1px solid $gray-base;
                padding-top: 10px; 
            }
        }
        .title {
            font-size: 20px;
        }
    }
    &--user-account {
        .error {
            padding-top: 5px;
        }
        &.intro {
            border-bottom: 2px solid $gray-light;
        }
    }

    &--our-brands {
        overflow: hidden;
        background-position: -600px -200px;
        background-repeat: no-repeat;
        background-color: $white;
        padding: 0;
        @media screen and (min-width:$medium-width) {
            @include flex();
            flex-wrap: nowrap;
        }
        .slick-arrow {
            display: none !important;
        }
        &-cta{
            background: url(../images/nos-marques.jpg) no-repeat $color-primary;
            color: #fff;
            background-size: cover;
            text-align: center;
            padding: 20px;

            .title {
                font-size: 40px;
                color: #fff;
                line-height: 1.15em;
                margin: 0 auto 5px;
                max-width: 475px;
            }
            .subtitle {
                font-size: 20px;
                color: #fff;
                text-transform: uppercase;
                max-width: 475px;
                margin: auto;
            }
        }
        .container-brands {
          padding: 0 10px;
            margin-left: 0;
            margin-right: 0;

              .slick-list{
                width: 100%;
                margin: 10px auto;
                @media screen and (max-width: $small-width ) {
                  max-width: 100%;
                }

              }
            .container-logo{
              width: calc(100%/2);
              float: left;
              @media screen and (min-width:$small-width) {
                width: calc(100%/5);
              }
              @media screen and (min-width:$medium-width) {
                width: calc(100%/7);
              }
            }
        }

        .container-logo {
            text-align: center;
            padding: 5px;
            min-height: 100px;
            img {
                width: 90%;
                @extend %transition-base;
                &:hover {
                    @media screen and (min-width: $medium-width) {
                        transform:scale(1.2,1.2);
                    }
                   
                }
            }
        }
    }

    &--why-otto{
        .partial-title{
            font-size: 35px;
            //max-width: 900px;
            margin: 0 auto 10px;
            line-height: 40px;
        }
        .partial-text{
            text-transform: initial;
            font-size: 25px;
            max-width: 900px;
            margin: auto;
            font-weight: normal;
            line-height: 32px;
        }

        .cta--why-buy-otto{
            background:$color-primary url(../images/why-to-buy.jpg) center;
        }
        .cta--why-sell-otto{
            background:rgb(242,78,0)  center;
        }

        .cta--why-buy-otto,.cta--why-sell-otto{
            position: relative;
            background-size: cover;
            
            display: block;
            width: 100%;
            font-size: 50px;
            height: 400px;
            float: left;
            cursor: pointer;

            @media screen and (min-width:$small-width) {
                height: 320px;
                width: 50%;
                font-size: 40px;
            }

            @media screen and (min-width:$medium-width) {
                height: 520px;
                font-size: 60px;
            }

            span{
                position: absolute;
                color: #FFF;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                
                font-weight: bold;
                letter-spacing: 2px;
                z-index: 1;
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top:0;
                left:0;
                bottom: 0;
                right: 0;
                background-color: rgba(242,78,0, 0.0);
                transition: background 150ms ease;
            }
            &:hover{
                &:after{
                    background-color: rgba(242,78,0, 0.6);
                }
            }

        }
    }

    &--where-to-buy{
        .site-page__title {
            background-color: #F28D00;
            h2{
                color: white;
                font-size: 40px;
            }
        }

        .site-page__content{
            position: relative;
            .map{
                height: 440px;
            }
            .cta--where-to-buy{
                position: absolute;
                top:50%;
                left: 50px;
                transform: translateY(-50%);
                img {
                    max-height: 260px;
                }
                .btn:not(.btn--transparent):not(.btn--inverse):not(.disabled){
                    border-radius: 0;
                    position: 0;
                    font-size: 25px;
                    white-space: nowrap;
                    overflow: visible;
                    background-color: #1e73be;
                    transition: background 150ms ease;
                    &:after{
                        border-top: 28px solid transparent;
                        border-bottom: 28px solid transparent;
                        border-left: 32px solid #1e73be;
                        content: '';
                        display: block;
                        position: absolute;
                        transform: none;
                        width: 0;
                        height: 0;
                        left: 100%;
                        opacity: 1;
                        background: transparent;
                        transition: border-left 150ms ease;
                    }
                    &:hover{
                        background-color: lighten(#1e73be, 10);
                        &:after{
                            border-left-color:lighten(#1e73be, 10);
                        }
                    }
                }

                @media screen and (max-width:$small-width) {
                    position: absolute;
                    top: 2%;
                    left: 2%;
                    transform: inherit;
                    height:94%;
                    img {
                        max-height: 110px;
                    }
                    .btn {
                        position:absolute;
                        bottom:0;
                        &:not(.btn--transparent):not(.btn--inverse):not(.disabled) {
                            font-size: 18px;
                            &:after {
                                border-top: 24px solid transparent;
                                border-bottom: 24px solid transparent;
                            }
                        }
                    }
                }
            }

            /*&:after{
                position: absolute;
                content:"";
                display: block;
                right: 0;
                top:0;
                width: 230px;
                height: 245px;
                background: url(../images/map-element.svg);
            }*/
        }

    }
}