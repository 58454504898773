/* discount matrix modal */
.discount-matrix-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.discount-matrix-modal-container p {
  color: black;
}

.matrix-container {
  border: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  position: relative;

  [class*="col-"]:not(.sourcing-from-label) {
    padding: 0 5px 0 5px;
    text-align: center;
  }
}

.matrix-container option {
  color: black;
}

.matrix-modal {
  .c--popup-box {
    padding: 20px 5px 20px 5px;
  }
  @media (min-width: 768px) {
    .c--popup-box {
      padding: 20px;
    }
  }

  .c--popup-footer {
    text-align: center;
  }
}
.matrixes-non-edit-blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  overflow: hidden;
  top: 0;
  left: 0;
}
.matrix-blocker {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: #fff;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discount-matrix-modal-row-container {
  width: 100%;
}

.discount-matrix-modal-row-container select {
  font-weight: 700;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: center;
  text-align-last: center;
  padding: 7px;
}
.matrix-rate-operator {
  font-weight: 700;
  border-radius: 7px;
  text-align: center;
  width: auto;
  border: 3px solid black;
  white-space: nowrap;
  display: inline;
  max-width: 80%;
}

.matrix-rate-input {
  min-width: 20px;
  max-width: 65%;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  display: inline-block;
  padding: 7px;
  color: black;
  border-radius: 5px;
  vertical-align: bottom;
}

.matrix-price-type-selector {
  border: 1px solid;
  border-radius: 7px;
}

.btn-show-edit-matrixes-modal {
  font-size: 1.5em;
}

/* Pricing structure editor */
.pricing-structures-list {
  border: 1px solid #dcdcdc;
  background-color: #f5f5f5;
  list-style-type: none;
}

.pricing-structures-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #858786;
  .btn-container {
    white-space: nowrap;
  }
}

/* Pricing management */

.pricing-management-editor-container {
  height: 100%;
  border-right: 2px solid #dcdcdc;
}
#pricing-management-categories-container {
  display: flex;
  flex-wrap: wrap;

  .part,
  .subcategory,
  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.pricing-management-body-container {
  height: 100%;
}
.global-pricing-container {
  display: flex;
  align-items: center;
  .matrix-thumbnail-container {
    margin-right: 5px;
  }
  .edit-btn {
    display: inline-flex;
    align-items: center;
    .btn-show-edit-matrixes-modal {
      margin-left: 0;
      display: inline;
    }
  }
}

/*matrix-thumbnail*/
.matrix-thumb-and-btn-container {
  display: flex;
  align-items: center;
}
.matrix-thumbnail-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  .matrix-thumbnail {
    text-align: center;
    margin: 5px;

    .connection-label {
      font-weight: 700;
      color: $color-primary;
    }
  }
}
