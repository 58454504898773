header {
    .logo {
        z-index: 1;
        @media screen and (min-width: $medium-width) {
            margin-left: 20px;
        }
        &:hover {
            cursor: pointer; 
        }
        img {
            width: 150px;
            @media screen and (min-width: $medium-width) {
                width: 200px;
            }
        }
    }
    &.site-header--landing,&.site-header--otto{
        z-index: 202 !important;
        width: calc(50% - 200px);
        min-width: 225px;
        
        @media screen and (min-width: $medium-width) {
            z-index: inherit;
        }
        .container-fluid .row {
            margin-top: 5px; 
             @media screen and (min-width: $small-width) {
                //margin-top: 40px;
            }
         }

        .logo {
            margin-left: 0;
            width: inherit;
            display: inline-block;

            img{
                width: 200px;
            }
        }
        .nav__container--lang,.nav__container--change-location{
            margin-top: 15px;
            padding-left: 10px;
            display: inline-block;
            width: inherit;
            color:white;
            font-weight: normal;
            text-transform: initial;
            button{
                cursor:pointer;
                &:hover{
                    text-decoration: underline;
                }
            }

        }
        .nav__container--change-location{
            margin-top: 4px;
        }
    }
    &.site-header--otto{
        @media screen and (max-width: $medium-width - 1px) {
            //margin-top: 40px;
            display: none;
        }
    }
}