.site-page {
	@extend %clearfix;
	padding: 0px;
	&__banner {
		background-position: left;
		background-repeat: no-repeat;
    	background-size: cover;
    	background-position: center;
		height: 370px;
    	@media screen and (max-width: $medium-width) {
			height: 70vh;
		}
	}
	.box--gray {
		background-color: $gray-xlight;
		border-radius: 3px;
		border-color: transparent;
	}
	.container{
		p{
			color: $black;
			font-family: $font-family--secondary; 
			
		}
	}
	.container-asset, .container-video{
		margin-top: 60px;
		font-size: 12px;
		font-family: $font-family--secondary;
		@media screen and (min-width: $small-width) {	
			margin-top: 40px;
		}
		a:hover{
			text-decoration: none;
		}
		h2{
			border-bottom: 1px solid $color-primary;
			padding-bottom: 0px;
			margin-bottom: 20px;
		}
		img{
			width: 100%;
			margin-bottom: 20px;
		}
		.fa{
			vertical-align: middle;
			margin-right: 10px;
			margin-bottom: 10px;
			float: left;
		}
	}
	 .container-content, .wysiwyg-content{
		p{
			color: $black;
			font-family: $font-family--secondary;
			margin-bottom: 1em;
		}
	}
	.container-contact {
		.container-address {
			.icon {
				@media screen and (max-width: $xsmall-width) {
					display: none;
				}
			}
		}
		.site-page__content {
			@media screen and (max-width: $xsmall-width) {	
				padding: 0;
			} 
		}
	}
	.container-insertion-fluid{
		padding: 40px 0;
		margin-bottom: 40px;
			@media screen and (min-width: $small-width) {				
				margin-bottom: inherit;
				background: url('/dist/images/stripe-gray.png');
				background-repeat: repeat no-repeat;
				background-position: center center;
			}
		img{
			width: 100%;
			padding-bottom: 10px;
			 @media screen and (min-width: $small-width) {
				padding-bottom: inherit;
			}
		}
	}
	.container-insertion{
		@media screen and (min-width: $small-width) {
			display: flex;
    		align-items: center;
    	}
	}
	.container-introduction{
		margin-bottom: 20px;
	}
	.container-main{
		margin-top: 20px;
	}
	.container-pre-footer{
		img{
			margin: auto;
			display: none;
			@media screen and (min-width: $small-width) {
				display: block;
				width: 100%;
			}
			@media screen and (min-width: $medium-width) {
				width: 100%;
			}
		}
	}
	&__content{
		background-color: $gray-xlight;
		@media screen and (min-width: $small-width) {
			padding: 50px 0;
		}
		.container {
			background-color: white;
		}

		ul, ol{
			font-family: $font-family--secondary;
			color: $black;
			margin-left: 30px;
		}
		.sitemap{
			h2{
				padding-bottom: 5px;
				line-height: 30px;
				margin: 0;
				padding: 0;
			}	
			li{
				position: relative;
				&:before{
					content: '';
					display: block;
					position: absolute;
					height: 100%;
					top:0;
					left: 0px;
					border-left: 1px solid $color-primary;
				}
				&:after{
					content: '';
					display: block;
					position: absolute;
					width: 15px;
					top:24.5px;
					left: 0px;
					border-top: 1px solid $color-primary;
				}
			    background-size: 25px;
			    padding-left: 20px;
			    list-style: none;
			    padding-top: 15px;
			    a{
			    	text-decoration: underline;
				}
				
				&:last-child{
					&:before{
						height:24.5px;
					}
				}
				
			}

			/*ul.children li{
				&:first-child{
					padding-top: 15px;
				}
			}*/
		}

	}
	.faq-answer {
	    display: none;
	    margin-top: 20px;
	    margin-left: 30px;
	}
	.faq-question {
	    background-color: $gray-xlight;
	    padding: 10px;
	    font-size: 20px;
	    font-weight: 400;
	    color: $color-secondary;
	    margin-top: 20px;
	   
	}
	h2{
		color: $color-secondary;
		padding-top: 0px;
		padding-bottom: 10px;
		font-size: 30px;
		@media screen and (max-width: $small-width){
			font-size: 17px;
		}
	}
	.icon {
		width: 24px;
		&-svg{
			height: 24px;
			width: 24px;
		}
	}

	.insertion__text{
		text-align: center;
		background-color: $color-secondary;
		padding: 10px;
		@media screen and (min-width: $small-width) {
			text-align: left;
			background-color: transparent;
			padding: 0;
		}
		@media screen and (min-width: $medium-width) {
			text-align: left;
		}
		h2{
			color: $color-primary;
			font-size: 25px;
			font-weight: bold;
			padding-bottom: 0; 
		}
		p{
			color: $white;
			font-family: $font-family--primary;
			font-size: 20px;
		}
	}
	.insertion__image{
		padding-bottom: 20px;
		@media screen and (min-width: $small-width) {
			padding-bottom: 0;
		}
	}
	
	&__faq{
		.faq-answer{
			display: none;
			margin-top: 20px;
			margin-left: 30px;
		}
		.faq-question{
			background-color: #DCDCDC;
    		padding: 10px;
		    font-size: 20px;
		    font-weight: 400;
		    color: #313236;
			margin-top: 20px;
			font-family: $font-family--secondary;
			&:hover{
				cursor: pointer;
			}
		}
		h2{
			margin-top: 20px;
			padding-bottom: 0;
		}
	}
	&.promo{
	.site-page__title{
		background-color: $color-secondary; 
		padding: 80px 0;
	}

	}
	&__title {
		background-color: $gray-xlight;
		color: $color-secondary;
		text-align: center;
	    font-family: $font-family--primary;
	    text-transform: uppercase;
	    font-weight: 700;
	    letter-spacing: 3px;
	    padding: 30px 10px;
	    font-size: 50px;
	}
	&__white {
		background-color: $white;		
	}

}

.map--tools{
	&.full-screen {
		height: 141px!important;
	}
}
.map{
	.full-screen{
		position: absolute;
		top: 0;
		left: 0;
		width:100%;
	}
}
