.site-header .sidebar {
    background-color: rgba(0,0,0,0.85);
    width: 300px;
    position: fixed;
    z-index: 400; 
    top: 46px;
    left: -300px;
    @extend %transition-base;
    &.active {
        left: 0px;
        @extend %transition-base; 
    }
    .container-inset {
        padding: 20px;
        background-color: $color-primary;
        width: 260px;
        margin: 40px 20px 20px 20px; 
        height: 100px;
        color: $white;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: bold;
        &:hover {
           background-color: $color-primary--lighten; 
           cursor: pointer;
            @extend %transition-base;
        }
    }
    .logo {
        width: 180px;
    }
    ul {
        li {
            color: $gray-light;
            padding: 20px 40px !important;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;  
            width: 100%;
            text-align: left;
             @extend %transition-base;
            &:hover {
                cursor: pointer;
                color: $white;
                background-color: $gray-dark;
                 @extend %transition-base;
            }
            a {
               
                font-size: 20px;
                text-transform: uppercase;
                &:hover {
                 text-decoration: none;
                }
            }

            &.indent{
                width: 90%;
                margin-left: 10%;
            }

            span.sidebar--new{
                position: absolute;
                right: 30px;
            }

            li{
                padding: 10px 20px 0;
                border-bottom: 0;  
            }
        }
    }
} 
