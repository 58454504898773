.brands {
    .container-brand {
        height: 110px;
        background-color: $white;
        padding: 0;
        border: 1px solid #cccecd;
          @media screen and (min-width: $small-width) {
             margin: 5px;
         }
         @media screen and (min-width: $medium-width) {
            margin: 10px;
         }
        .brand-item {
            height: 110px;
            margin: auto;
            background-size: contain;
            -moz-transform: scale(0.8);
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
            background-position: center;
            background-repeat: no-repeat;
            @extend %transition-xxslow; 

            &:not(.disabled):hover {
                @media screen and (min-width: $medium-width) {
                    cursor: pointer;
                    -moz-transform: scale(0.95);
                    -webkit-transform: scale(0.95);
                    transform: scale(0.95);
                }
            }
        }
    }
    .container-letter-separator {
        position: relative;
        height: 110px;
        background-color: $gray-light;
        border: 1px solid #cccecd;
        margin: 4px;
         @media screen and (min-width: $small-width) {
             margin: 5px;
         }
        @media screen and (min-width: $medium-width) {
            margin: 10px;
         }
         .letter-separator {
            left: 0;
            right: 0;
            position: absolute;
            text-align: center;
            padding: 5px 0;
            color: $color-secondary;
            font-size: 85px;
            font-weight: bold;
        }
    }
    .paginator {
        display: block;
        margin: 15px auto;
        color: $gray-base;
        .page {
            float: left;
            padding: 5px;
            cursor: pointer;
            &.selected {
                cursor: default;
                font-weight: bold;
                font-size: 20px;
                padding-top: 2px;
                padding-bottom: 0;
            }
        }
        .separator {
            float: left;
            padding: 5px 10px;
            color: $gray-light;
        }
    }
}

.container-brand{
    position: relative;
    padding: 60px;
    @media screen and (min-width: $medium-width) {
        
    }
    a{
        font-family: $font-family--secondary;
        font-size: 14px;
        text-decoration: underline; 
        color: $color-primary;
    }
    .logo-brand{
       width: 150px;
       margin-bottom: 40px
    }
    .subtitle{
        color: $black;
        font-family: $font-family--secondary; 
        font-weight: bold;
        margin-bottom: 20px;
    }
    .title{
        color: $color-primary;
        font-weight: bold;
        font-size: 40px; 
        text-align: center;
    }
    &:after {
        content: '';
        border-color: transparent transparent #333;
        border-width: 0;
        border-style: solid;
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translate(-50%,0);
    }
    &.active{
        &:after{
            border-width: 0 11px 11px!important;
        }
    }
}
.container-logo{
   text-align: center;
    @media screen and (min-width: $medium-width) {
        text-align: left;
    }
}
.container-parttypes{ 
    margin-top: 20px;
    @media screen and (min-width: $medium-width) {
         margin-top: 0;
    }
    .fa-plus-circle, .fa-minus-circle {
        float: left;
        font-size: 25px; 
    }
    .brand-parttype-header {
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid $gray-base;
        vertical-align: middle;
        font-size: 17px;
        font-weight: normal;
        color: $shark;
        text-transform: uppercase;
        font-family: $font-family--secondary;
        margin-top: 15px;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            font-size: 23px;
        }
        &:hover {
            cursor: pointer;
        }
        .fa{
            margin-right: 10px;
        }
    }
    .brand-parttype{
        min-height: 125px;
        border: 1px solid $gray-light;
        border-top: 0;
        border-left: 0;
        padding: 40px;
        text-align: center;
        border-right: 0;
       
        @media screen and (min-width: $medium-width) {
             border-right: 1px solid $gray-light;
             &:nth-of-type(3n), &:last-of-type{
                border-right: 0;
            }
         }
         @media screen and (min-width: $medium-width) {
            border-right: 1px solid $gray-light;
            &:nth-of-type(3n){
                border-right: 1px solid $gray-light;
            }
             &:nth-of-type(4n), &:last-of-type{
                border-right: 0;
            }
         }
       
        h3{
            text-align: center;
            letter-spacing: 1px;
            color: #000;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            height: 45px;
            font-family: $font-family--secondary;
        }
    }
    .brand-parttype-name{
        font-weight: bold;
    }
    .img-product {
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; 
    }
     img{
        margin: 10px;
        width: 50%;
        }
    .row-brand-parttype{
        display: none;
    }
    .brand-subcat-header {
        width: 100%;
        padding: 10px;
        vertical-align: middle;
        border-bottom: 1px solid $gray-light;
        font-size: 14px;
        font-weight: normal;
        color: $gray-base;
        text-transform: uppercase;
        font-family: $font-family--secondary;
        background-color: #eee;
        display: none;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            font-size: 18px;
        }
        &:hover {
            cursor: pointer;
        }
         .fa{
            margin-right: 10px;
        }
    }

}

.page-template-tpl-brands-php{
    .brands {
        .container-brand {
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        top: -20px;
                    }
        }
    }
}
