main {
   //position: relative;
   //margin-top: 50px;
   @media screen and (min-width: $small-width) {
     margin-top: 0;
   }
    @media screen and (min-width: $medium-width) {
        //min-height: 860px;
    }
    .container-home--content{
        margin-top: 250px;
        @media screen and (min-width: $small-width) {
            margin-top: 0;
            margin-top: 100px;
        }
         @media screen and (min-width: $medium-width) {
            margin-top: 0;
        }
    }
    .fullwidth {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0; 
    }
    .mobile-overlay{
        background-color: rgba(0,0,0,.7);
        display: none;
        width: 100%;
        height: 120%;
        position: fixed;
        z-index: 98;
        top: -163px;
    }

    .container-catalog{
        position: relative;
        min-height: 745px;
        height: 100vh;

        @media screen and (max-width:$medium-width - 1px) {
            min-height: 450px;
            height: calc(100vh - 40px);
        }

        @media screen and (max-width:$medium-width - 1px) {
            min-height: 600px;
            height: calc(100vh - 50px);
        }

        .cta-otto{
            position: absolute;
            left: 50%;
            bottom: 25px;
            transform: translateX(-50%);
            color: #fff;
            text-align: center;
            font-size: 23px;
            font-family: $font-family--secondary;
            font-weight: normal;
            .icon{
                display: block;
                margin: auto;
                filter: grayscale(1);
                opacity: 0.7;
                margin-top: 10px;
                margin-bottom: 20px;

                -webkit-transition: all 500ms ease;
                -moz-transition: all 500ms ease;
                -ms-transition: all 500ms ease;
                -o-transition: all 500ms ease;
                transition: all 500ms ease;
            }

            a:hover{
                .icon{
                    filter: none;
                    opacity: 1;
                }
            }
        }
    }
    .container-homepage{
        position: absolute;
        top:50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        h1,.h1{
            position: relative;
            z-index: 12;
            border: 0;
            color: white;
            border:0 !important;
            text-align: center;
            text-transform: none;
            font-size: 60px;
            letter-spacing: 3px;
            font-family: $font-family--primary;

            @media screen and (max-width:$small-width) {
                font-size: 40px;
            }
        }
        h2,.h2{
            display: inline-block;
            color: #fff;
            font-size: 23px;
            text-transform: none;
            padding: 0;
            margin: 0;
            vertical-align: top;
            font-weight: 400;
            letter-spacing: normal;
            font-family: $font-family--secondary;
            line-height: 40px;
            margin-right: 30px;

            @media screen and (max-width:$small-width) {
                font-size: 16px;
            }
        }

        h1 .where-to-buy{
            display: inline-block;
            position: relative;
            button{
                background: transparent;
                font-size: 14px;
                position: absolute;
                right: 0;
                bottom: -10px;
                font-weight: normal;
                &:hover{
                    background: transparent !important;
                }
            }
        }
        
        .container-search-tool .container-partial--search-engine{
            .loading-icon{
                background: rgba(0,0,0,0.5);
                position: fixed;
                left: 0;
                top: 0;
            }
            .tab-menu{
                width: auto;
                white-space: nowrap;
                border-bottom: 1px solid $color-primary;
                margin-top: 0;
                margin-bottom: 25px;

                .tab{
                    background: transparent !important;
                    border: 0;
                    border-bottom: 5px solid transparent;
                    color: white;
                    position: relative;
                    top: 3px;
                    margin-left: 20px;
                    @media screen and (max-width:$small-width) {
                        margin-left: 0px;
                    }
                    &:first-child{
                        margin-left: 0;
                    }
                    .tab__text{
                        font-size: 23px;
                        text-transform: none;
                        @media screen and (max-width:$small-width) {
                            font-size: 16px;
                        }
                    }
                }
                .tab.active{
                    background: transparent !important;
                    border: 0;
                    border-bottom: 5px solid $color-primary;
                }
            }
            .js-search-part-number{
                @include flex();
                flex-wrap: nowrap;
                width: 100%;
                height: 50px;
                /*>*{
                    display: table-cell;
                    width: 100%;
                }*/
                border: 0;
                @media (min-width:$small-width){
                    &,button{
                        height: 85px;
                    }
                    input { border-radius: 10px 0 0 10px; font-size: 20px; width: 100% !important;}
                    button{ 
                        border-radius: 0 10px 10px 0; 
                    }
                }
                .search-bar{
                    width: 100%;
                }
                input {font-size: 20px; width: 100% !important;}
                button{ 
                    padding:0;

                    font-size: 30px;
                }

                input{
                    outline: 0;
                    border: 0;
                    height: 100%;
                }
            }

            .js-search-by-garage-select{
                border: 0;
                @media (min-width:$small-width){
                    height: 85px;
                    border-radius: 10px;
                }
            }

            .container-search-fields{
               
                @media (min-width:$small-width){
                    .selectbox{
                        float: left;
                        width: 33.333%;
                        &:after{
                            border-top-color: $color-primary;
                            right: 14px;
                            top: 50%;
                            transform: translateY(-50%);
                            border-width: 9px 6px 0;
                        }
                        .select2-selection--single, select{
                            font-size: 20px;
                            padding-top: 30px;
                            padding-bottom: 30px;
                            height: 86px;
                        }
                        
                        &:not(:first-child){
                             .select2-selection--single, select{
                                border-left-width: 0;
                             }
                        }
                        &:first-child {

                            .select2-selection--single, select{
                                border-radius: 10px 0 0 10px;
                            }
                        }
                        &:last-child {
                            .select2-selection--single, select{
                                border-radius:0 10px 10px 0;
                        
                            }
                        }
                    }
                }
            }
            form[name="search-by-garage"]{
                .selectbox{
                    &:after{
                        border-top-color: $color-primary;
                        right: 14px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-width: 9px 6px 0;
                    }
                }
                .search-by-garage-select{
                    font-size: 20px; //!important;
                }
            }
        }
        .container-partial--login,.container-partial--forgot-password,.container-partial--reset-password{
            input[type=text],input[type=password]{
                margin-left: -17px;
            }
        }

        .container-partial--login,.container-partial--forgot-password,.container-partial--reset-password,.container-partial--registration,.container-partial--registration-confirm,.container-partial--registration-confirmed{
            h2{
                margin-top: 10px;
                font-size: 50px;
            }
            color: white;
            input[type=text],input[type=password]{
                border-radius: 5px;
                outline: 0;
                height: 60px;
                min-width: 350px;
            }
            .google-policies{
                min-width: 325px;
            }
            .btn{
                line-height: 36px;
                font-size: 1.1em;
                letter-spacing: 2px;
                &--inverse{
                    padding: 20px;
                }
            }
            .form-group{
                margin-bottom: 15px;
            }
            label[for=login_remember_me]{
                max-width: 350px;
                padding-left: 10px;
                margin: 5px auto 0;
                text-align: left;
                input{
                    display: none;
                    &+i{
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        position: relative;
                        margin-right: 5px;
                        &:before,&:after{
                            content:"";
                            position: absolute;
                        }
                        &:before{
                            display: block;
                            top:0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            border: 1px solid $color-primary;
                        }
                        
                        &:after{
                            display: none;
                            top:3px;
                            left: 3px;
                            bottom: 3px;
                            right: 3px;
                            background: $color-primary;
                        }
                    }
                    &:checked+i:after{
                        display: block;
                    }
                }
            }
            button{
                min-width: 350px;
            }
            .fa{
                color: black;
                top: 2px;
                left: 12px;
            }
            .color--secondary, .color--secondary .fa{
                color:white;
            }
            .color--primary .fa{
                color: $color-primary;
            }
            h1{
                color: white;
            }
            .btn--inverse{
                color: white;
            }
            .container-description{
                display: none;
            }
        }

        .container-partial--registration{
            h1+div{
                max-width: 50%;
                margin: auto;
                text-align: center !important;
            }
        }
        
    }

    .container-promo-otto{
        padding-top: 40px;
        padding-bottom: 40px;
        h2{
            font-size: 35px;
            margin-bottom: 60px;
        }
        .otto-tabs{
            text-align: center;
            color: $color-secondary;
            max-width: 900px;
            margin: auto;

            p{
                color: $color-secondary;
                font-size: 25px;
                max-width: 564px;
                margin: auto;
                margin-bottom: 20px;
            }
            
            .icon{
                display: block;
                margin: 0 auto 20px;;
            }
        }
        .container-otto-tab{
            border-bottom: 1px solid $color-primary;
            margin-bottom: 70px;
            font-size: 25px;
            font-weight: bold;
            color: #ccc;

            @media screen and (max-width:$small-width){
                font-size: 18px;
                .icon{
                    max-width: 50px;
                }
            }
            .otto-tab{
                border-bottom: 7px solid transparent;
                margin-bottom: -4px;
                padding-bottom: 15px;
                text-transform: uppercase;
                &.active{
                    color: $color-primary;
                    border-bottom-color: $color-primary;
                    .icon-wallet:before{
                        background-image: url(../images/portefeuilleorange.png);
                      }
                      
                      
                      .icon-region:before{
                        background-image: url(../images/economielocalorange.png);
                      }
                      
                      .icon-leaf:before{
                        background-image: url(../images/environementorange.png);
                      }
                }
                a{
                    padding-bottom: 10px;
                    display: block;
                    cursor: pointer;
                }
            }
        }
        .container-content{
            display: none;
            &.active{
                display: block;
            }
        }

        @keyframes otto-container-fadeIn {
            from {opacity: 0}
            to {opacity: 1}
        }

        &.on-viewport .otto-tabs{
            animation-name: otto-container-fadeIn;
            animation-duration: 2s;
        }
        &.on-viewport .container-content{
            &.active{
                animation-name: otto-container-fadeIn;
                animation-duration: 2s;

                .container-otto-icon-text.on-viewport{
                    @keyframes otto-container-translate {
                        from {opacity: 0; top:50px;}
                        to {opacity: 1; top:0;}
                    }
                    animation-name: otto-container-translate;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    
                    &:nth-child(3n+1){
                        animation-delay: 0s;
                    }
                    &:nth-child(3n+2){
                        animation-delay: .2s;
                    }
                    &:nth-child(3n+3){
                        animation-delay: .4s;
                    }
                    @media screen and (max-width: $medium-width){
                        &:nth-child(2n+1){
                            animation-delay: 0s;
                        }
                        &:nth-child(2n+2){
                            animation-delay: .2s;
                        }
                    }
                    @media screen and (max-width: $small-width){
                        &:nth-child(1n){
                            animation-delay: 0s;
                        }
                    }
                }
            }
        }
        .container-description{
            h3{
                font-size: 40px;
                text-align: center;
                color: $color-secondary;
                margin-bottom: 25px;
            }
            h4{
                font-size: 20px;
                font-family: $font-family--secondary ;
            }
            p{
                color: $color-secondary;
                text-align: center;
                margin-bottom: 70px;
                font-size: 16px;
                font-family: $font-family--secondary ;

                &.subtitle{
                    font-family:  $font-family--primary ;
                    font-size: 25px;
                }
            }
        }

        .container-otto-icon-text{
            position: relative;
            
            min-height: 71px;
            padding-right: 40px;
            top:50px;
            text-align: center;
            p{
                margin-bottom: 16px;
            }
            @media screen and (min-width: $small-width + 1px){
                padding-left: 110px;
                text-align: left;
                p{
                    text-align: left;
                }
                .icon{
                    position: absolute;
                    left: 20px;
                }
            }
           
        }
    }
    .container-otto-foot{
        .background{
            background: url('../images/netcom-bg-lv.jpg') center center no-repeat;
            background-size:cover;
        }
        .container{
            text-align: center;
            max-width: 700px;
            
        }
        h2{
            color:white;
            font-size: 55px;
        }
        p{
            font-size: 35px;
            font-family: $font-family--secondary ;
        }
        .btn{
            border-radius: 0;
            text-transform: initial;
            font-weight: normal;
            font-size: 30px;
            margin-top: 70px;
        }
    }

    &.container-landing-page{
        height: 100vh;
    }
}