
.ticket-container{
    height: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    .half-height{
        height: 50%;
    }
    .btn-ticket{
        display: flex;
        font-size: 20px;
        padding: 5px 10px;
        align-items: center;
        justify-content: center;
        .icon-support {
            height: 35px;
            width: 35px;
            -webkit-mask-image: url(../images/icon-support.png);
            mask-image: url(../images/icon-support.png);
            -webkit-mask-size: contain;
            mask-size: contain;
            -webkit-mask-repeat: no-repeat;
        }
    }
    @media screen and(max-width:$medium-width){
        flex-direction: column;
        .half-height{
            margin-bottom: 5px;
        }
    }
}
.image{
    mask-position: center;
    mask-size: contain!important;
    -webkit-mask-repeat: no-repeat!important;
    -webkit-mask-size: contain!important;
    &--pricingstructure{
        -webkit-mask-image: url(../images/Icon_Price_Structure.svg)!important;
        mask-image: url(../images/Icon_Price_Structure.svg)!important;
    }
    &--princinglist{
        -webkit-mask-image: url(../images/Icon_Price_List.svg)!important;
        mask-image: url(../images/Icon_Price_List.svg)!important;
    }
    &--multy-user{
        -webkit-mask-image: url(../images/icon-multi-user.svg)!important;
        mask-image: url(../images/icon-multi-user.svg)!important;
    }
    &--sellers{
        -webkit-mask-image: url(../images/Icon_Amazon.svg)!important;
        mask-image: url(../images/Icon_Amazon.svg)!important;
    }
    &--profile{
        -webkit-mask-image: url(../images/icon-profile.svg)!important;
        mask-image: url(../images/icon-profile.svg)!important;
    }
    &--jobs{
        -webkit-mask-image: url(../images/icon-jobs.svg)!important;
        mask-image: url(../images/icon-jobs.svg)!important;
    }
    &--subscriptions{
        -webkit-mask-image: url(../images/Icon_Subscription.svg)!important;
    }
}

