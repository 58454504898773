.container-product {
    .addtocard-msg {
        font-size: 10px;
        margin-top: 10px;
        text-align: center;
        @media screen and (min-width:$small-width) {
            margin-top: 0; 
            text-align: right; 
        }
    }
    .btn-back {
        background-image: url(/dist/images/arrow-left-white.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
        background-size: 14%;
        height: 80px;
        margin-bottom: 20px;
        padding-left: 30%;
        text-align: left;
        text-transform: uppercase;
        width: 100%; 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn--where-to-buy {
        padding: 12px;
         @media screen and (min-width:$small-width) {
            float: right;
            margin-bottom: 10px;
            min-width: 100%;
        }
    }

    .container {
        padding: 0;
        @media screen and (min-width:$small-width) {
            padding: inherit;
        }
    }
    .container-asset {
        div {
            min-height: 40px;
        }
        p {
            display: table;
            font-size: 11px;
            word-break: break-word;
            &:hover {
                color: $color-primary;
            }
        }
    }
    .container-btn-back {
        text-align: center;
        @media screen and (min-width:$small-width) {
            text-align: right;
        }
    }
    .container-price-quantity {
        color: $black;
        font-family: $font-family--secondary;
        .addtocart-button {
            width: 98px;
            display: inline-block;
            padding: 7px;
            .fa {
                font-size: 26px; 
            }
            
        }
        .availability {
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
            @media screen and (min-width:$small-width) {
                text-align: right;
            }
        }
        .container-quantity {
            text-align: center;
            @media screen and (min-width:$small-width) {
                text-align: right;
            }
        }
        .quantity-control {
            background-color: #FFF;
            border: 1px solid #DCDCDC;
            color: #313236;
            font-size: 14px;
            height: 40px;
            padding: 0 0 0 5px;
            text-transform: uppercase;
            width: 105px;
            display: inline-flex;
           
            input[type=number] {
                color: $color-primary;
                float: right;
                font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
                font-size: 20px;
                font-weight: 700;
                padding: 5px 10px;
                //width: 60px;
                -moz-appearance: textfield;
                -webkit-appearance: textfield;
                appearance: textfield;
                &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                }
            }
        }
        .divider-fee {
            border: 1px solid $gray-light;
            margin: 0.5em 0;
        }
        .no-price {
            text-align: center; 
            @media screen and (min-width:$small-width) {
                text-align: right;
            }
        }
         .price-range {
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            @media screen and (min-width:$small-width) {
                text-align: right;
            }
            small{
                font-size: 40%;
           }
        }
        .save, .tax, .deposit, .regular-price {
            font-size: 14px;
            text-align: center;
            @media screen and (min-width:$small-width) {
                text-align: right;
            }
        }
        .tax, .deposit {
            color: green;
        }
        .unit-price {
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            margin-top: 20px;
            @media screen and (min-width:$small-width) {
                text-align: right;
                margin-top: 0;
            }
        }
    }
    .container-tab-content {
        background-color: $gray-xlight;
        color: $color-secondary;
        font-family: $font-family--secondary;
        margin-bottom: 60px;
        padding: 20px;
        @media screen and (min-width:$small-width) {
            padding: 30px;
        }
        h2 {
            color: $color-primary;
            margin-bottom: 0;
            text-align: center;
            @media screen and (min-width:$small-width) {
                text-align: left;
            }
            &:not(:first-of-type) {
                margin-top: 40px;
            }
        }
        .container-faq, .container-troubleshoot {
            table {
                td {
                    text-align: left;
                }
            }
        }
        .container-specification {
            table {
                tr:nth-child(even) {
                    background-color: $gray-light;
                }
                td {
                    padding: 10px;
                    &:first-of-type {
                        width: 50%;
                        word-break: break-word;
                    }
                }
            }
        }
        .container-buyers-guide {
            font-size: 14px;
            .row:nth-child(even) {
                background-color: $gray-light;
            }
        }
        .container-description {
            table td {
                text-align: left;
            }
        }
        .container-interchange {
            h2 {
                border-bottom: 1px solid $gray-light;
                margin-bottom: 20px;
            }
            span {
                font-size: 12px;
                /*&:hover {
                    background-color: $gray-base;
                    color: $white;
                    cursor: pointer;
                }*/
            }
            .tooltip {
                display: inline-block;
                position: relative;
            }
            .tooltip .tooltiptext {
                background-color: $color-secondary;
                border-radius: 2px;
                color: #fff;
                min-width: 200px;
                padding: 5px;
                position: absolute;
                text-align: left;
                visibility: hidden;
                z-index: 1;
            }
            .tooltip:hover .tooltiptext {
                visibility: visible;
            }
        }
    }
    .container-button-toggle {
        text-align: center;
        .button-toggle {
            display: inline-block;
            &:hover {
                cursor: pointer;
                color: $color-primary;
            }
        }
    }
    .container-content {
        display: none;
        &.active {
            display: block;
        }
        ul {
            margin: 20px; 
        }
    }
    .container-image {
        @media screen and (min-width:$medium-width) {
            border-right: 1px solid $gray-light;
        }
    }
    .container-logo {
        text-align: center;
        @media screen and (min-width:$small-width) {
            text-align: left;
        }
        img {
            max-width: 100%;
        }
    }
    .container-related-product {
        h2 {
            text-align: center;
            @media screen and (min-width:$small-width) {
                text-align: left;
            }
        }
    }
    .header {
        background-color: $gray-xlight;
        padding: 20px 0 5px;
        .addtocart-button {
            .text--big {
                font-size: 42px;
            }
        }
        .applicative-info {
            display: none;
            font-family: $font-family--secondary;
            font-size: 14px;
            .fits-on {
                color: $color-secondary;
                font-weight: bold;
                margin-bottom: 5px;
                margin-top: 20px;
                text-transform: uppercase;
                &__list {
                    color: $color-secondary;
                    list-style-type: none;
                    margin-bottom: 20px;
                    .header-fit {
                        color: $color-primary;
                        font-weight: 700;
                    }
                    .sub-fits {
                        color: #878787;
                        font-size: 14px;
                        font-weight: 400;
                        margin-left: 25px;
                    }
                }
            }
        }
        .btn {
            height: 80px;
            margin-bottom: 10px;
            width: 100%;
        }
        .divider {
            border-top: 1px solid $gray-light;
        }
        .socials{
            position: absolute;
            top: 40px;
            right: 40px;
            @media screen and (max-width:$small-width ) {
                position: relative;
                padding-bottom: 20px;
                top: 0;
                right: 0;
                text-align: center;
                margin-top: 35px;
            }
            .url-copied{
                position: absolute;
                white-space: nowrap;
                right: 0;
                bottom: 100%;
                @media screen and (max-width:$small-width ) {
                    right: initial;
                    left: 50%;
                    transform: translate(-50%,-5px);
                }
            }
            .btn-social{
                font-size: 13px;
                height: 19px !important;
                width: 19px !important;
                padding: 0 2px;
                margin: 0 !important;
                display: inline-block;
                border-radius: 3px;
                line-height: 19px;
                .fa-envelope{
                    font-size: 12px;
                }
                @media screen and (max-width:$small-width ) {
                    height: 40px !important;
                    width: 40px !important;
                    line-height: 40px;
                    font-size: 20px;
                    .fa-envelope{
                        font-size: 19px;
                    }
                }
            }
        }
    }
    .link-see-more {
        cursor: pointer;
        text-align: center;
        z-index: 1;
        position: relative;
        font-family: $font-family--primary;
        @media screen and (min-width:$small-width) {
            text-align: left;
        }
        &:hover {
            color: $color-primary;
        }

    }
    .made-for {
        color: $gray-base;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
    .nav {
        font-family: $font-family--secondary;
        margin-top: 50px;
        .container-product-info-tab {
           .product-info-tab {
                @extend %transition-base;
                background-color: #ccc;
                color: $color-secondary;
                border: 1px solid #3C3C3C;
                &:not(:last-of-type) {
                    border-right: 0;
                }
                @media screen and (max-width:$small-width) {
                    &:nth-of-type(3) {
                        border-right: 1px solid #3C3C3C;
                    }
                    &:nth-of-type(n+4  ) {
                        border-top: 0;
                    }
                }
                @media screen and (min-width:$small-width) {
                    width: 150px;
                }
                @media screen and (min-width:$medium-width) {
                    width: 200px;
                }
                &.active {
                    background-color: $color-primary; 
                    color: $white;
                }
                a {
                    display: block;
                    font-size: 9px;
                    padding: 12px 10px;
                    text-align: center;
                    text-transform: uppercase;
                    @media screen and (min-width:$small-width) {
                        font-size: 14px;
                    }
                    &:hover {
                        color: #FFF;
                        text-decoration: none;
                    }
                }
                &:hover {
                    @extend %transition-base;
                    background-color: $color-primary;
                    cursor: pointer;
                    @media screen and (min-width:$medium-width) {
                        background-color: $gray-base;
                    }
                }
            }
        }
        .container-product-info-tab {
            width: 100%;
        }
    }
    .part-custom-attribute, .part-attribute {
        td {
            border-bottom: solid #DCDCDC;
            border-bottom-width: 1px;
            width: 50%;
            @media screen and (min-width:$small-width) {
                bottom-border-width: 0;
                width: inherit;
            }
        }
    }
    .parttype-brand {
        color: $color-secondary;
        font-weight: bold;
        text-align: center;
    }
    .part-info {
        font-family: $font-family--secondary;
        text-align: center;
        @media screen and (min-width:$small-width) {
            text-align: left;
        }
        .brand {
            color: $color-secondary;
            font-size: 14px;
            font-weight: bold;
        }
        h2 {
            color: $gray-dark;
            margin-bottom: 5px;
            margin-top: 20px;
        }
        .label {
            color: $color-secondary;
            font-size: 18px;
            font-weight: bold;
        }
        .part-number {
            color: $color-primary;
        }
    }
    .related-product {
        background-color: $gray-xlight;
        font-family: $font-family--secondary;
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
        @media screen and (min-width:$small-width) {
            &:not(:first-of-type) {
                border-left: 10px solid white;
            }
        }
        .btn-detail {
            background: #FFF;
            border: 1px solid #EB2131;
            color: #313236;
            text-transform: uppercase;
        }
        .brand {
            color: $color-primary;
            font-size: 12px;
            font-weight: bold;
            padding: 5px;
        }
        .label {
            color: $color-secondary;
            font-size: 18px;
            font-weight: bold;
        }
        .part-type {
            color: $color-secondary;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .price {
            color: $color-primary;
            font-size: 18px;
            font-weight: bold;
        }
    }
    .slick-slide {
        outline: none;
    }
    table {
        border-collapse: inherit;
        font-size: 14px;
        width: 100%;
        td {
            padding: 5px;
            text-align: left;
            vertical-align: top;
            &:nth-of-type(1) {
                color: $gray-base;
                text-align: right;
                @media screen and (min-width:$small-width) {
                    text-align: left;
                    width: 50%;
                }
            }
        }
    }
    .vehicle {
        color: $color-primary;
        font-size: 50px;
        font-weight: bold;
        line-height: 55px;
        text-align: center;
        text-transform: uppercase;
    }
    .slick-arrow {
         background-color: $gray-base;
        -webkit-mask-image: url(/dist/images/arrow-left-color.svg);
        mask-image: url(/dist/images/arrow-left-color.svg);
        mask-repeat: no-repeat;
        background-repeat: no-repeat;
        background-size: 80%;
        bottom: 5px;
        color: transparent;
        display: none!important;
        height: 45px;
        position: absolute;
        width: 40px;
        @extend %transition-base;
        @media screen and (min-width:$medium-width) {
            display: inline-block!important;
        }
        &:hover {
            cursor: pointer;
            background-color: $color-primary;
            @extend %transition-base;
        }
    }
    .slick-list {
        padding: 0!important;
    }
    .slick-next {
        right: 0;
        transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
    }
    .slick-slide {
        max-width: 100%;
        @media screen and (min-width:$medium-width) {
            width: 550px;
        }
        img {
            margin: auto;
            max-height: 550px;
            max-width: 100%;
        }
    }
    .slick-slider {
        margin-bottom: 0;
        position: relative;
    }
    ul.slickslide {
        max-width: 100%;
        padding-left: 0;
    }
    .slick-slider .slick-track {
        min-width: 100%;
    }
    .slick-dots button img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        &:hover {
            cursor: pointer;
        }
    }
    .slick-dots {
        line-height: 0;
        margin-bottom: 20px;
        margin-top: 10px;
        text-align: center;
        vertical-align: bottom;
        @media screen and (min-width:$medium-width) {
            margin-bottom: 0;
        }
    }
    .slick-dots li, .slick-dots li button:before {
        margin: 0;
        padding: 0;
    }
    .slick-dots li {
        border: 1px solid $color-primary;
        display: inline-block;
        margin: 5px;
        width: auto;
        &.slick-active {
            border: 3px solid $color-primary;
        }
    }
    .slick-slider .slick-track {
        min-width: 100%;
    }
    .slick-dots li button {
        height: auto;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .slick-dots li button.tab {
        clear: both;
        display: block;
        height: 50px;
        width: 50px;
    }
    .slick-dots li button img {
        height: auto;
        max-height: 100%;
        width: auto;
    }
    .slick-dots li button:before {
        content: '';
        display: block;
        float: none;
        max-width: 150px;
        overflow: hidden;
        position: absolute;
        width: 100%;
        width: 100%;
    }
    .slick-thumbs {
        left: -9999px;
        position: absolute;
    }

    .btn--where-to-buy{
        width: 100%;
        @media screen and (min-width: $small-width){
            width: inherit;
        }
    }


    .listint-pictos{
        width: 100%;
        text-align:center;
        @media screen and (min-width: $small-width){
            text-align:right;
        }
        &__block{
            /*float: left;*/
            /*width: 100%;*/
            /*height: 64px;*/
            vertical-align: bottom;
            position: relative;
            display: none;
            margin-top:5px;
            &.active{
                display: inline-block;
            }
            &__picto{
                color: #9e9e9e;
                position: relative;
                text-align:center;
                display: inline-block;
                width: 40px;
                height: 40px;
                &:before{
                    position: relative;
                    transform: translateY(-50%);
                    top:50%;
                    display: inline-block;
                }
                &:after{
                    width: 100%;
                    height: 100%;
                    border-radius: 40px;
                    content: '';
                    border: 1px solid;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    color: #9e9e9e;
                }
            }
            &__text{
                color: #9e9e9e;
                float: left;
                position: absolute;
                left: 60px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .filter-contents{
        h5{
            background: $gray-light;
            padding: 5px 10px;
        }
        ul{
            list-style: none;
            padding: 0 10px;
            li{
                padding: 5px 0;
                display: inline-block;
            }
        }
        .label-images{
            display: inline-block;
            text-align: center;
            padding: 10px;
            border: 2px dotted transparent;
            cursor: pointer;
            width: 129px;
            vertical-align: top;
            img{
                border:4px transparent solid;
                display: block;
                max-width: 100%;
                margin: auto auto 5px;
            }
            &:hover{
                border: 2px dotted $color-primary;
            }
        }
        input:checked+.label-images{
            //border: 3px dotted $color-primary;
            img{
                border:4px $color-primary solid;
            }
        }
    }
}
.page-template-tpl-part-php {
    overflow: hidden;
}

.addtocart-container{

    //@media screen and (min-width:$medium-width) {
        @include flex();
        margin: 15px -3px;
        .item-added{
            margin: 3px;
            width: 100%;
            flex: none;
            font-size: 10px;
        }
        .quantity-control{
            flex-grow: 1;
            margin: 3px;
            border: 1px solid $gray-light;
            background-color: $white;
            text-transform: uppercase;
            padding-left: 5px;
            color: $color-secondary;
            width: 105px;
            height: 40px;
            display: inline-flex;
            input.error {
                border: 1px solid red;
                color: red;
            }
        }
        .alternate-numbers__alternative-part__quantity-control{
            flex-grow: 1;
            margin: 0 0 0 3px;
        }
        button.alternate-numbers__alternative-part__btn{
            min-width: 29px;
            height: 28px;
            line-height: 16px;
            border-radius: 0 5px 5px 0;
            flex-grow: 1;
            margin: 0 3px 0 0;
            padding: 6px;
            @media screen and (max-width:$medium-width){
                height: 40px;
                min-width: 40px;
                font-size: 26px;
            }
        }
        button.addtocart-button{
            width: 40px;
            flex-grow: 1;
            margin: 3px;
            padding: 6px;
            .fa {
                font-size: 26px;
            }
        }
    //}
}