.btn {
    
    &--contact {
        color: white!important;
        @media screen and (min-width: $medium-width) {
            margin-left: 100px;
        }
        
    }
}

