// ==========================================================================
// Object slider product-line
// ==========================================================================

.o-slider-pline {
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	//margin: 0 -10px;
	background-color: $white;
	max-height: 90px;

	padding-left: 60px;
	padding-right: 60px;

	margin-left: -($container-padding); // Relative to parent container padding
	margin-right: -($container-padding); // Relative to parent container padding

	@media screen and (min-width: $small-width) {

	}

	// _hack for slider arrows
	@media screen and (min-width: 1280px) {
		margin-left: -80px;
		margin-right: -80px;

		&:before,
		&:after {
		    display: block;
		    content: ''; 
		    position: absolute;
			bottom: -35px; // relative to border-top
			width: 0; height: 0;
			border-top: 35px solid $white;
		}

		&:before {
			left: 0;
			border-left: 60px solid transparent;
		}

		&:after {
		    display: block;
		    content: ''; 
		    position: absolute;
			right: 0;
			border-right: 60px solid transparent;	
		}
	}



	&__item {
		display: inline-block;
		height: 90px;
		line-height: 90px;
		text-align: center;
		outline: 0;
		//margin: 0 10px;

		/*
		@media screen and (min-width: $small-width) {
			text-align: left;
		}*/
		
		a {
			display: inline-block;
			vertical-align: middle;
			outline: 0;
            transform: translate3d(0,0,0);
            @extend %transition-base;
            
            @media screen and (min-width: $nohover-width) {
                &:hover {
                    transform: translate3d(0,-5px,0);
                    opacity: 0.6;
                }
            }
		}

		img {
			vertical-align: middle;
			max-height: 70px; // Bug with firefox + ie
			width: 100%; // Bug with chrome
			padding-right: 15px;
			padding-left: 15px;
		}
	}

	.shadow {
		@include box-shadow(0px, 10px, 45px, rgba(0, 0, 0, 0.8));
		position: absolute;
		left: 0; right: 0;
		top: 0; bottom: 0;
		z-index: 5;
	}


	// target slick-slider element
	.slick-slider {
		z-index: 10;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
		font-size: em(24px);
		color: $color-primary;
		cursor: pointer;
		outline: 0;
        @extend %transition-base;

    	@media screen and (min-width: $small-width) {
	        padding-left: 20px;
	        padding-right: 20px;
		}
        
        @media screen and (min-width: $nohover-width) {
            &:hover {
                opacity: 0.6;
            }
        }
	}

	.slick-prev {
		left: -60px; // -60px + 20px
		@media screen and (min-width: $nohover-width) {
            &:hover {
                opacity: 0.6;
                transform: translate3d(5px,-50%,0);
            }
        }
	}
	
	.slick-next {
		right: -60px; // -60px + 20px
		color: $color-primary;
        @media screen and (min-width: $nohover-width) {
            &:hover {
                opacity: 0.6;
                transform: translate3d(-5px,-50%,0);
            }
        }
	}
}