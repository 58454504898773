.container-partial { 
    &--your-promo { 
       
        padding-bottom: 20px;
        @media screen and (min-width: $small-width) {
            padding-top: inherit;
        }
        button {
            display: none!important;
        }
        .container-promo img {
            width: 100%;
            max-width: 320px;
            padding: 10px;
        }
    } 
}
.container-promo{
    .btn{
        margin-top: 0;
    }
    input.email {
        display: block;
        padding: 10px;
        background-color: #FFF;
        border-radius: 0;
        margin: 0;
        width: 50%;
        min-width: 100px;
        font-family: $font-family--secondary;
        @media screen and (min-width: $small-width) {
            width: 60%;
        }
    }
    .promo-image{
       width: 100%; 
    }
    strong{
        color: $color-primary;
    }
    .subscribe-newsletter{
        background-color: $gray-light;
    }
    .subtitle{
        font-weight: bold;
        color: $color-secondary;
        font-family: $font-family--secondary;
    }
    .text{
        font-family: $font-family--secondary;
        color: $color-secondary;
        font-size: 12px;
    }
    .text-brands{
        color: $color-primary;
        font-weight: bold;
        font-family: $font-family--secondary;
    }
    .title{
        color: $color-secondary;
        font-weight: 700;
        font-size: 40px;
       
    }
}

.container-parallax{
    min-height: 565px;
    position: relative;
    z-index: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 300px;
    perspective: 300px;
    //background: red;
    overflow: hidden;
    @include flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    >.background{
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 200%;
        transform: translate(-50%,-50%);
    }

}

.container-news-letter{
    background: $color-primary;
    padding: 20px 0;
    h2{
        color: white;
        font-size: 35px;
        text-transform: uppercase;
        line-height: 1em;
    }
    p{
        font-size: 20px;
        color: white;
        opacity: 0.7;
        line-height: 0.8em;
    }
    input[type=email]{
        height: 46px;
        margin-right: 5px;
        border:0;
        width:100%;
        max-width: 350px;
        vertical-align: bottom;
    }
    form{
        text-align: right;
    }

    .btn{
        border-color: white !important;
        border-radius: 0;
        color: $color-secondary;
        text-transform: initial;
        font-weight: normal;
    }

    @media screen and (max-width: $medium-width - 1px) {
        text-align: center;

        input[type=email]{
            display: block;
            margin: 20px auto 10px;
        }

        form{
            text-align: center;
        }
    }
}
