.partial__anchor {
    position: fixed;
    top: 0;
    z-index: 1002;
    background-color: transparent;
    width: 50px;
    height: 100%;
    left: 0;
    .fa-chevron-up, .fa-chevron-down {
        color: $color-primary;
    }
    ul {
        list-style: none;
        .circle {
            width: 15px;
            height: 15px;
            background: #fff;
            border-radius: 40px;
            border: 2px solid $gray-base;
            display: inline-block;
            margin-left: 10px;
            top: 0;
            position: relative;
            
            &:hover, &.active {
                background: $color-primary;
            }
        }
    }
}
.add-brand {
    @media screen and (max-width: $medium-width) {
          text-align: center;
    }
}
section {
    &.banner {
        .box--icon {
            text-align: center;
            @media screen and (min-width: $medium-width) {
                text-align: right;
            }
        }   
    }
     .box--button-download-pdf {
            text-align: center;
             @media screen and (min-width: $small-width) {
                text-align: right;
            }

        }
    .box--gray-register {
        
        font-size: 18px;
        padding: 10px;
        border-radius: 5px;
        background-color: #DCDCDC;
        @media screen and (min-width: $small-width) {
            @include flex();
            flex-wrap: nowrap;
            max-width: 500px;
            width: 100%;
            align-items: center;
        }
        span {
            color: $black;
            width: 100%;
            padding-right: 20px;
            text-align: right;
        }
        .btn{
            white-space: nowrap;
        }
    }
    .box--icon-arrow {
        @media screen and (min-width: $small-width) {
            display: none;
        }
        @media screen and (min-width: $medium-width) {
            display: inline-block;
        }
    }
    .copyright {
        position: fixed;
        bottom: 10px;
        width: 90%;
        @media screen and (min-width: $medium-width) {
            width: 40%;
        }
    }
    .cta {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 20px;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            text-align: left;
            margin-top: 40px;
            left: 276px;
        }
        .box--gray-register {
        
            @media screen and (max-width: $medium-width) {
                padding: 0;
                width: inherit;
            }

        }
        span { 
            @media screen and (max-width: $medium-width) {
                display: none;
            }
        }
       
    }
    &.disclaimer {
        h1 {
            font-size: 22px;
            line-height: inherit;
            margin-top: 20px;
        }
        h2 {
            font-size: 20px;
            line-height: inherit;
            margin-top: 20px;
        }
        h3 {
            font-size: 18px;
            line-height: inherit;
            margin-top: 20px;
            font-size: 18px;
            font-weight: normal;
            color: #000;
        }
        h4 {
            line-height: inherit;
            margin-top: 20px;
            font-size: 18px;
            font-weight: normal;
             color: #000;
        }
    }
    .fa-arrow-circle-down {
        color: $color-primary;
        @media screen and (max-height: 520px) {
            display: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    h2 {
        
        margin-bottom: 20px;
        font-size: 20px;
        @media screen and (min-width: $medium-width) {
            font-size: 60px;
            line-height: 60px;
        }

    }
    h4 {
         margin-top: 100px;
         font-weight: normal;
         font-size: 20px;
         @media screen and (min-width: $medium-width) {
            margin-top: 210px; 
             font-size: 30px;
         }
    }
    hr {
        background-color: $gray-light;
        height: 1px;
        margin-top: 20px;
        margin-bottom: 20px;
         @media screen and (min-width: $medium-width) {
             margin-top: 20px;
            margin-bottom: 40px;
         }
    }
    p {
        color: black;
        font-size: 16px;
        line-height: 1.2em;
        @media screen and (min-width: $small-width) {
            font-size: 18px;
            line-height: 24px;
        }
    }
    .text {
        padding-left: 20px;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            padding-left: 100px;
            text-align: left;
        }
    }
}

.footer, .expert, .basic, .installer, .add-brand, .other-ways {
    h2 {
        margin-top: 125px;
        @media screen and (min-width: $medium-width) {
            margin-top: 250px;
        }
    }
}
.mobile-hide {
    @media screen and (max-height: 520px) {
        display: none;
    }
}
.start {
    padding-left: 100px;
}