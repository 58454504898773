 .page-template-tpl-user-order-detail, .page-template-tpl-user-orders, .page-template-tpl-user-registration, .page-template-tpl-cart-checkout {
    .site-page__title {
        background-color: transparent; 
        color: $gray-xdark;
    }
}
.page-template-tpl-user-profile {
      .site-page__title {
        background-color: $color-primary;
    }
}
.page-template-tpl-store-locator {
    footer {
        position: relative;
    }
}

.tpl {
     &--store {
        padding-top: 20px!important;
         @media screen and (min-width: $medium-width) {
            padding-top: 0px!important;
            min-height: 600px;
        }
        a {
            &.link--back{
                 padding-top: 0;
                 .fa {
                    color: $color-primary;
                    padding-right: 5px;
                 }
            }
            display: inline-table;
        }
        button {
            &.button--direction, &.button--share {
                 width: 100%;
                 font-weight: bold;
                 margin: 10px 0 0!important; 
                .fa {
                     float: right;
                }
            }
        }
       
        .block-info-garage {
            background-color: $white;
            padding: 35px 25px;
           
            h3 {
                color: $color-primary;
            }
            .list-info-block {
                 font-family: $font-family--secondary; 
            }
        }
        .desc-block {
             p {
                color: $color-secondary;
                font-family: $font-family--secondary;
            } 
        }
        .list-info-block ul {
            list-style: none;
            font-size: 14px;
            li {

                .fa-absolute {
                    color: $color-primary;
                    left: -10px;
                    position: relative;
                    top: 2px;
                }
                 &:before {
                    content: '';
                }
            }
            .open-hours {
                display: inline-table;
            }
        }
         .map-store {
            height: 300px !important; 
            position: relative; 
            overflow: hidden;
        }
     }

     &--store-locator {
        //margin-bottom: -20px;
        @media screen and (min-width: $medium-width) {
            height: inherit; 
        }
        div[class^=" _ibani_"] {
            box-shadow: 0 5px 5px rgba(0,0,0,.35);
        }
        .bg-tools {
            background-color: $color-primary; 
            width: 440px;
            height: 150px;
            top: 150px; 
            margin: auto;
            z-index: 1;
            left: 0;
            right: 0;
            position: absolute;
            border-radius: 3px; 
        }  
        .border--right-white {
            @media screen and (min-width: $medium-width) {
                border-right: 1px solid rgba(255,255,255,0.25);
            }
        }
        .btn-back {
            background-image: url(/dist/images/arrow-left-white.svg);
            background-position: left 10px center;
            background-repeat: no-repeat;
            background-size: 14%;
            height: 80px;
            margin-bottom: 20px;
            padding-left: 30%;
            text-align: left;
            text-transform: uppercase;
            width: 100%; 
        }
        .caption {
            top: 100px;
            font-family: $font-family--secondary;
            margin-top: 10px;
            font-size: 14px;
             @media screen and (min-width: $small-width) {
                top: inherit;
             }
        }
        .close-map {
            cursor: pointer;
            right: 10px;
            top: 10px;
            position: absolute;
            z-index: 1;
            outline: 0;
            &:before{
                content: "\d7";
                font-size: 75px;
                line-height: 50px;
                width: 50px;
                text-align: center;
                display: inline-block;
            }
        }
        .fa-phone, .fa-map-marker {
            font-size: 20px;
        }
        form {
            div {
                display: inline-block;
            }
        }
        .container-title{
            border-bottom: 1px solid $gray-light;
            margin-bottom: 20px;
            h3{
                margin-bottom: 0;
                border-bottom: 0;
            }
        }
        h3 {
            text-transform: uppercase;
            border-bottom: 1px solid $gray-light;
            font-family: $font-family--primary;  
            font-size: 18px;
            margin-bottom: 20px;
        }
        .info-bubble-close {
            right: 10px!important; 
            top: 10px!important;
        }
        .gm-style-iw {
            top: 15px !important;
            padding: 10px;
            font-size: 18px;
        }
        .infobubble-text {
            font-size: 18px;
            text-transform: uppercase;
        }
        .input--search-map {
            margin-top: 60px;
            background: white;
            padding: 10px;
            font-size: 16px;
            width: 100%;
            border: 1px solid $gray-base; 

        }
        .map {
            height: 700px;
            position: relative;
            width: 100%;
        }

        .map--tools {
            position: relative;
            background-color: $gray-xlight;
            z-index: 1;
            height: 475px;
            padding-top: 15px;
            padding-bottom: 20px;
             @media screen and (min-width: $small-width) {
                height: 140px;
             }
            @media screen and (min-width: $medium-width) {
               
                height: inherit;
            }
           .js-full-screen-map{
             img{
               border-radius: 5px;
               background-color: #fff;
               padding: 10px;
               opacity: .8;
               cursor:pointer;
               &:hover{
                 background-color: #fff;
                 opacity: 1;
               }
             }
           }
            .range {
                top: 40px;
                right: 20px;
            }
             .text, .label, .range {
                position: absolute;
                color: $gray-base;
                top: 10px;
                font-family: $font-family--secondary; 
                font-size: 14px;
            }
        }
  
        .modal--store-info {  
            
            z-index: 97;
            display: none;
            background-color: white;
            padding: 20px;
            top: 500px;
            position: absolute;
            box-shadow: 0 10px 10px rgba(0,0,0,.35);
            margin: auto;
            left: 0;
            right: 0;
            width: 90%;
            max-height: 660px;
            overflow-y: auto;
            
            @media screen and (min-width: $small-width) {
                top: 400px;
                
            }
             @media screen and (min-width: $medium-width) {
                 width: 400px;
                 left: 40px;
                 top: 200px;
                 margin: inherit;
             }
             .addtocard-msg {
                font-size: 10px;
                margin-top: 10px;
                text-align: center;
                @media screen and (min-width:$small-width) {
                    margin-top: 0; 
                    text-align: right; 
                }
            }
            .btn {
                width: 100%;
                font-weight: bold;
                margin: 0;
                margin-bottom: 10px;
            }
            .addtocart-button {
                width: 50px;
                display: inline-block;
                margin-top: 5px;
                padding: 7px;
                margin-left: 5px;
                .fa {
                    font-size: 26px; 
                }
                
            }
            p {
                display: inline-table;
                font-size: 14px;
            }
            .part-info {
                font-size: 14px;
                margin-bottom: 20px;
                font-family: $font-family--secondary;
               
                hr {
                    border-bottom: 1px solid $gray-light;
                }
                img {
                    max-height: 200px;
                    max-width: 100%;
                }
                .tax, .deposit {
                    color: green;
                }
                .title {
                    font-size: 16px;
                }
                .unit-price {
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 20px;
                }
            }
            .listint-pictos{
                width: 100%;
                &__block{
                    display: none;
                    width: 55px;
                    height: 64px;
                    &.active{
                        display: inline-block;
                    }
                    &__picto{
                        color: #9e9e9e;
                        float: left;
                        position: relative;
                        top: 50%;
                        left: 15px;
                        transform: translateY(-50%);
                        &:after{
                            width: 45px;
                            height: 45px;
                            border-radius: 40px;
                            content: '';
                            border: 1px solid;
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            color: #9e9e9e;
                        }
                    }
                    &__text{
                        color: #9e9e9e;
                        float: left;
                        position: absolute;
                        left: 60px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            .quantity-control {
                background-color: #FFF;
                border: 1px solid #DCDCDC;
                color: #313236;
                font-size: 14px;
                height: 40px;
                margin: 20px auto;
                padding: 0 0 0 5px;
                text-transform: uppercase;
                width: 93px;
                display: inline-flex;
                @media screen and (min-width: $small-width) {
                    width: 105px;
                }
               
                input[type=number] {
                    color: $color-primary;
                    float: right;
                    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    padding: 5px 10px;
                    //width: 54px;
                    -moz-appearance: textfield;
                    -webkit-appearance: textfield;
                    appearance: textfield;
                    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                    }
                    &.error {
                        border: 1px solid red;
                    }
                }
            }
            ul {
                list-style: none;
                font-size: 14px;
                font-family: $font-family--secondary;
                li {
                    margin-left: 20px;
                    .fa-absolute {
                        color: $color-primary;
                        left: -10px;
                        position: relative;
                        top: 2px; 
                    }
                     &:before {
                        content: '';
                    }
                }
            }

            .banner-image{
                margin: -20px -20px 20px;
                max-height: 210px;
                overflow: hidden;
                position: relative;
                img{
                    max-width: 100%;
                }
                &-popup{
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    right: 10px;
                    background: white;
                    box-shadow: 2px 2px 7px rgba(0,0,0,0.3);
                    border-radius: 10px;
                    &__foot{
                        padding: 5px;
                    }
                    button{
                        cursor: pointer;
                    }

                    &.left{
                        display: inline-block;
                        left: unset;
                    }
                }
            }

            .container-store{
                border: 1px solid #ccc;
                padding: 10px 0;
                margin-bottom: 20px;
                .js-store-name{
                    border-bottom: 1px solid #ccc;
                    padding: 0 10px 5px;
                    margin-bottom: 10px;
                }
                .js-g_rating{
                    cursor: pointer;
                }
                &-row{
                    position: relative;
                    padding-left: 20px;
                    min-height: 20px;
                    margin-bottom: 10px;
                    .fa-main{
                        position: absolute;
                        left: 0;
                        top:0;
                    }
                }
                &-review{
                    margin:10px 0;
                    &>img{
                        width: 50px;
                    }
                    &>div{
                        width: calc(100% - 50px);
                        display: inline-block;
                        padding-left: 10px;
                        vertical-align: top;
                    }
                }
                .col-md-6{
                    padding: 0px 10px;
                }
                .part-info{
                    margin: 0;
                    padding: 0px 10px 0 0;
                    text-align: right;
                }
                .quantity-control,.btn{
                    margin-bottom: 0;
                    vertical-align: bottom;
                }
                &:hover{
                    border-color: $color-primary;
                    .js-store-name{
                        border-color: $color-primary;
                    }
                }
            }
        }
        p {
            color: $color-secondary;
        }
       
        .slider {
            text-align: center;
            top: 70px;
        }
        .tel {
            font-weight: bold;
            font-size: 16px;
        }
        .text {
            padding-left: 0;
        }

        .no-result{
            text-align: center;
            .title{
                font-size: 30px;
                color: $color-primary;
                font-weight: bold;
            }
            p{
                font-size: 16px;
                padding: 0 20px;
            }
        }
    }

}