.form {
    background-color: $color-primary;
    z-index: 1;
    top: 0;
    right: 0;
    width: 50%;
    position: absolute;
    height: 100%;
    display: none;
    padding-left: 140px;
    padding-right: 100px;
    
    .btn--add-brand {
        width: 80%;
    }
    
    .btn-close-form {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .disabled {
        opacity: .5;
        .btn {
            &:after {
                opacity: 0;
            }
            &:hover {
                cursor: default; 
            } 
        }
    }
    form {
        position: relative;
    }
    h3 {
        color: white;
        font-size: 43px;
        margin-top: 250px;
        margin-bottom: 0;
        font-weight: normal;
        text-transform: none;
        span {
            margin-bottom: 50px;
            float: left;
        }
    }
    hr {
        background-color: white
    }
    input[type=radio] {
        transform: scale(2);
        margin-bottom: 50px;
        float: left;
        margin-top: 10px;
        margin-right: 20px;
    }
    input[type=text], select {
        background-color: white;
        padding: 10px;
        width: 100%;
        margin-top: 20px;
        border: 1px solid $gray-base;
        font-size: 18px;
         &.brand-name {
            display: none;
        }
    }
    label {
        color: black;
        font-size: 40px;
        line-height: 40px;
        text-transform: uppercase;
    }
    .select2 {
        width: 100%!important;
        font-size: 18px;
        .select2-selection {
            padding: 10px;
            height: 45px;
        }
        &.select2-container--default .select2-selection--single .select2-selection__arrow {
            top: 10px;
        }
    }
    p {
        color: white;
        font-size: 24px;
        line-height: inherit;
    }
    .steps {
        position: absolute;
        top: 0;
        width: 100%;
    }
    .step-1, .step-2, .step-3, .step-4, .confirmation {
        display: none;
        font-size: 20px;
        position: absolute;
        top: 0;
        width: 100%;
        h3 {
            margin-top: 80px;
        }
        h4 {
            color: black;
            text-transform: uppercase;
            font-size: 40px;
            font-weight: bold;
        }
    }
    .step-4 {
        .box--gray {
            display: block;
             p {
                color: $color-secondary;
            }
        }
       
    }
}