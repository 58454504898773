.site-header {
    height: 50px;
    width: 100%;
    position: relative;
    z-index: 201;
    top: 0;

    @extend %transition-base;
    @media screen and (min-width: $small-width) {
        height: 40px;
        position: static;
        box-shadow: 0 0 0;
    }
    @media screen and (min-width: $medium-width) {
        width: auto;
        position: relative;
        box-shadow: none;
    }
    .divider-menu {
         @media screen and (min-width: $medium-width) {
            border-left: 1px solid $gray-base;
         }
    }
    .fa {
        font-size: 30px;
        margin-left: 5px;

        @extend %transition-base;
        &.fa-bars,  &.fa-angle-times{
            color: $color-primary; 
            
            &:hover {
                color: $white;
            }
        }
        &.fa-angle-times:before {
            content: "\F105\F104";
            letter-spacing: -0.15em;
        }
        &.fa-user,&.fa-building,&-heart {
            font-size: 14px;
            margin-right: 3px;
        }
        &:hover {
            color: $color-primary;
            cursor: pointer;
            @extend %transition-base;
        }
        &-shopping-cart{
            font-size: 16px; 
        } 
    }
    li {
        display: inline-block;
        color: $black; 
        font-family: $font-family--primary;
        font-size: 14px;
        @media screen and (min-width: $medium-width) {
            font-size: 16px;
        }
    }
    .link-help {
        &:hover {
            cursor: pointer;
        }
    }
    .container-menu-mobile {
        top: 0;
        position: absolute;
        right: 0;
    }
   
    .menu {
         position: relative;
        @media screen and (min-width: $medium-width) {
            top: 12px;

            &.v2{
                top: 19px;
            }
        }
        li {
            margin-right: 0;
            color: $white;
            display: block;
            text-align: center;
            padding: 10px;
            @media screen and (min-width: $medium-width) {
            padding: 0 20px;
            
        } 
            &:not(:last-of-type) { 
                border-bottom: solid 1px $gray-base;
            }
            @media screen and (min-width: $small-width) {
                color: $white;
                display: inline-block;
                border-bottom: 0!important;
            }
            @media screen and (min-width: $medium-width) {
                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
        &.header-navigation {
            .header-navigation__customer-id{
                font-size: 20px;
                color: #bbb;
                .fa{
                    font-size: 22px;
                    margin: 1px 4px 7px 2px;

                    &:hover{

                    }

                }
                span{

                }
            }
            ul {
                @extend %clearfix;
            }
         
            &--secondary {
                position: relative;
                text-transform: uppercase;
                text-align: center;
                li {
                    border-bottom: solid 1px $gray-light; 
                    display: block;
                    padding: 15px;
                    margin-left: 0;
                    &:last-child {
                        border-bottom: 0;
                    }
                    &.link-speed-entry{
                        display: none;
                        @media screen and (min-width: $medium-width) {
                            display: inline; 
                        }
                    }
                }
                @media screen and (min-width: $small-width) {
                   
                    text-transform: inherit;
                   
                    li {
                        border-bottom: 0;
                        display: inline-block;
                        padding: 0 10px;
                        //margin-left: 20px;
                    }
                 @media screen and (min-width: $medium-width) {
                    text-align: right; 
                   
                    }   
                }
                li {
                    color: $white;
                }
            }
        }
    }
    .container {
        position: relative;
       
    } 
    .header-background-mobile {
        background: $color-secondary;
        //box-shadow: 0 0 20px #000;
        width: 100%;
        height: 50px;
        z-index: 1;
        position: absolute;
         @media screen and (min-width: $small-width) {
            display: none;
         }
    }
    .header-logo {
        height: 25px;
        margin-top: 5px;
        margin-left: 10px;
        &:hover {
            cursor: pointer;
        }
      
    }
    .menu__trigger {
        position: absolute;
        right: $spacing-medium-vertical;
        top: $spacing-base;
        z-index: 2;
        @media screen and (min-width: $small-width) {
            top: $spacing-base-vertical;
        }
        @media screen and (min-width: $small-width) {
            display: none;
        }
    }

    .nav {
        z-index: 200;
        margin-top: 5px;
        @media screen and (min-width: $small-width) {
            padding-left: 0;
        } 
      
        ul {
            list-style: none;
            display: inline-flex;
            width: 100%;
        }
        li {
            @extend %transition-base;
            &:last-of-type{
                margin-right: 0;
            }
            /*@media screen and (min-width: $small-width) {
                margin-right: 10px;
                width: 120px;
                &:last-of-type{
                    margin-right: 4.5px;
                }
            }*/
             @media screen and (min-width: $medium-width) {
                    //width: 200px;
                }
           
            &.active {
                color: $white;
                font-weight: bold;
                 @media screen and (min-width: $medium-width) {
                    //width: 200px;
                }
                a {
                    opacity: 1;
                    color: $color-primary;
                }
            }
        }
        li > a {
            display: block;
            color: $white;
            opacity: 0.5;
            font-size: 14px;
            text-align: center;
             @media screen and (min-width: $small-width) {
                //padding: 0 10px;
                font-size: 14px;
                
            }
            @media screen and (min-width: $medium-width) {
                font-size: 16px;
                margin-top: 5px;
               
            }
            img,.fa{
                display: none;
            }
            &:hover {
                text-decoration: none;
                cursor: pointer;
                color: $white;

                .fa{
                    color: $white !important;
                }

                img{
                    opacity: 1;
                }
            }
        }
        .sidebar-menu-icon {
            display: none;
            @media screen and (min-width: $small-width) {
                display: block;
                text-align: left;
            }
        }
  
    }
    .nav__container {
        background-color: $color-secondary; 
        top: -0; 
        transform: translateY(-100%);
        @extend %transition-base;
        @media screen and (max-width: $small-width - 1px) {
            max-height: calc(100vh - 40px);
            overflow: auto;
        }
        @media screen and (min-width: $small-width) {
            margin-top: 0;
            top: 0;
            text-align: left;
            background-color: transparent; 
            transform: translateY(0%);
        }
        &.is-open {
            @extend %transition-base;
            transform: translateY(0%);
        }
        &--secondary, &--tertiary {
            display: block;
            height: 45px;
            .container-segment {
                 position: relative;
                z-index: 2;
                min-height: 50px;
                @media screen and (min-width: $small-width) {
                    min-height: auto;
                }
            } 
            li {
                color: $white;
            }
        }
        &--secondary {
            background-color: $color-secondary;
            // DISPLAY FLEX 
            @media screen and (max-width: $small-width - 1px) {
                .container-segment {
                    display: inline-block;
                } 
            }
            @media screen and (min-width: $small-width) {
                @include flex();
                flex-wrap: nowrap;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;

                .container-segment {
                    flex: 1 1 auto;
                }

                .container-search-engine {
                    //flex: 1 1 auto;
                    
                    form.input-search-bar{
                        border: 0;
                        background: transparent;

                        button {
                            background: transparent;
                            color: $white !important;
                            .fa{
                                font-size: 25px;
                                color: $gray-light !important;
                            }
                            &:hover{
                                background: transparent  !important;
                                .fa{
                                    color: $white !important;
                                }
                            }
                        }
                        input[type=search]{
                            padding: 0;
                            width: 0;
                        }
                    }
                    
                }
                .nav__container {
                    flex: none;
                }

                .menu{
                    top:0;
                }

                .nav{
                    margin-top: 0;
                    >ul{
                        @include flex();
                        flex-wrap: nowrap;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center;
                        max-width: 55vw; // replace col-1-8
                        >li{
                            &.nav_container--logo,&.nav__container--change-location{
                                flex:none; 
                            }
                            flex: 1 1 auto;
                            flex-basis: 0;

                            img,a,button{
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }

        &--tertiary {
            // DISPLAY FLEX 
            @media screen and (max-width: $small-width - 1px) {
                background-color: $color-secondary;
                .container-segment {
                    display: inline-block;
                } 
            }
            @media screen and (min-width: $small-width) and  (max-width: $medium-width - 1px){
                background-color: $color-secondary;
                @include flex();
                flex-wrap: nowrap;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;

                .container-segment {
                    flex: 1 1 auto;
                }
                .nav__container {
                    flex: none;
                }

                .menu{
                    top:0;
                }

                .nav{
                    margin-top: 0;
                    >ul{
                        @include flex();
                        flex-wrap: nowrap;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center;
                        max-width: 55vw; // replace col-1-8
                        >li{
                            &.nav_container--logo,&.nav__container--change-location{
                                flex:none; 
                            }
                            flex: 1 1 auto;
                            flex-basis: 0;

                            img,a,button{
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
       
    }
    &.nav-up {
        top: -150px;
    }

    @media (min-width:$medium-width){
        &-light{
            position: absolute;
            top: 0;
            width: 100%;
            .nav__container--secondary {
                background-color: transparent;
            }
            .container-segment{
                width: inherit;
                left: 50%;
                transform: translateX(-50%);
                &:before,
                &:after{
                    content: "";
                    display: block;
                    background: $color-primary;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 75%;
                    z-index: -1;
                }
                &:before{
                    left: 0;
                    transform: skewX(20deg);
                }
                &:after{
                    right: 0;
                    transform: skewX(-20deg);
                }
                #tools-and-accessories,.nav ul li:first-child,.nav .nav__container--change-location{display: none;}
                .nav{
                    margin-top: 0;
                    >ul{
                       padding-bottom: 10px;
                    }
                }
                .nav ul li{
                    width: inherit; 
                    white-space: nowrap;
                    margin: 0;
                    &#recreational-vehicles{
                        margin-right: 0;
                    }
                    a{
                        margin-top: 0;
                        &:hover{
                            img{
                                opacity: 1;
                            }
                        }
                    }
                    &.active a{
                        color: white!important;
                        font-weight: normal;
                        img,.fa{
                            opacity: 1;
                        }
                    }
                    img,.fa{
                        display: inline-block;
                        filter: brightness(0) invert(1);
                        color:white;
                        //opacity: 0.5;
                        //padding-bottom: 10px;
                        height: 40px;
                        vertical-align: text-bottom;
                    }
                    .fa{
                        height:inherit;
                        vertical-align: inherit;
                        padding-top: 6px;
                        //position: relative;
                        /*&:before{
                            position: absolute;
                            bottom: 0;
                        }*/
                    }
                }   
            }
            

            .nav__container{
                position: absolute;
                right: 0;
            }
        }
    }
   
}
