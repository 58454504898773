.c--popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;

    z-index: 1001;
    color:black;

    &.js--is-open{
        display: block;
    }
    .js--prev{
        position: absolute;
        top:10px;
        left: 10px;
        font-size: 20px;
    }
   
    &----bg{
        background: rgba(0,0,0,.5) url("/wp-content/themes/marcor/dist/images/spinner_ajax.svg") no-repeat center center;
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        bottom: 0;
        z-index: -1;

        @keyframes popup-bg-animation {
            from {opacity: 0;}
            to {opacity: 1;}
        }
        animation-name: popup-bg-animation;
        animation-duration: 1s;
        animation-fill-mode: both;
        
    }
    &-box{
        @keyframes popup-box-animation {
            from {opacity: 0; top: calc(50% + 100px); }
            to {opacity: 1; top: 50%;}
        }

        box-shadow: 5px 5px 20px rgba(0,0,0,.5);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        max-height: 75%;
        max-width: 95%;
        overflow: hidden;
        background: white;
        z-index: 100000;
        padding: 30px;
        min-height: 100px;
        min-width: 100px;
        >span{
            position: absolute;
            right:10px;
            top: 10px;
            font-size:20px;
        }
    }
    &-title{
        font-size: 22px;
        text-align: center;
        margin-top: 50px;
        font-weight: bold;
        margin-bottom: 10px;    
    }
    &-error{
        font-size: 22px;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 10px;
        small{
            display: block
        }
        overflow-y: auto;
    }
    &-footer{
        margin-top: 20px;
        .btn{
            padding: 5px 10px;
            margin: 0 20px;
            font-size: 16px;
        }
    }

    &-table-holder{
        overflow: auto;
        max-height: 600px;
        border: 1px solid black;
        font-size: 16px;
        table{
            thead{
                tr{
                    background: $gray-light;
                }
            }

            tbody{
                tr:nth-child(2n){
                    background: $gray-xlight;
                }
                td{
                    padding: 0 10px;
                }
            }
            select{
                width: 100%;
            }
        }
    }    
}

.guest-detected{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 115;
    top: 0;
    left: 0;
    .loading-icon{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(169, 169, 169, 0.5);
        top: 0;
        left: 0;
        z-index: 1;
        display: none;
    }
    .fa{
        z-index: 1000;
        position: absolute;
        left: calc(50% - 30px);
        top: calc(50% - 30px);
        display: none;
    }
    &.loader{
        .loading-icon,.fa{
            display: initial;
        }
    }
    &__content{
        width: 100%;
        max-width: 500px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 10px;
        overflow: hidden;
        &__container{
            &__title {
                color: #313131;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                margin: 30px 0 10px;
            }
            &__message {
                color: #313131;
                font-size: 16px;
                text-align: center;
                margin: 20px 0 30px;
            }
            &__btn{
                width: 50%;
                border: 0;
                margin: 0;
                float: left;
                color: #fff;
                padding: 14px 0;
                text-transform: uppercase;
                cursor: pointer;
                transition: all .5s ease;
                &__yes{
                    background: $color-primary;
                    &:hover{
                        background: $color-primary--lighten;
                    }
                }
                &__no{
                    background: $color-warning-dark;
                    &:hover{
                        background: $color-warning;
                    }
                }
            }

        }
    }
    .voile {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
        position: absolute;
        top: 0;
        left: 0;
    }


}

.marker-price-label {
    background: $white;
    padding: 5px;
    color: $black;
    border: $color-primary 3px solid;
    border-radius: 5px;
    font-size: 16px;
    //font-weight: bold;
    position: relative;
    overflow: visible !important;
    transform: translate(-50%,-100%);
    text-align: center;
    &:before,&:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        transform: skewX(-30deg) translateX(-37%);
    }
    &:before {
        border-width: 15px 15px 0 0;
        border-color: $color-primary transparent transparent transparent;
        bottom: -15px;
        left: 50%;
        
    }
    &:after {
        border-width: 10px 10px 0 0;
        border-color: $white transparent transparent transparent;
        bottom: -8px;
        left: calc(50% + 3px);
    }
}