.container-form {
    &.container-partial--login, &.container-partial--forgot-password, &.container-partial--user-account, &.container-checkout, &.container-contact, &.container-confirmation, &.container-partial--registration, &.container-partial--reset-password, &.container-partial--export{  
        text-align: center;
        padding: 20px;
        font-family: $font-family--secondary; 
        position: relative;
        button{
            margin: 10px 0;
        } 
        h1{
            font-size: 35px;
             @media screen and (min-width: $small-width) {
                font-size: 50px;
            }
            font-family: $font-family--primary;
            color: $color-secondary; 
            
        }
        .form-group{
            margin-bottom: 10px;
            &-captcha{
                font-size: 10px;
                //text-align: left;
                margin: 6px 0;
            }
        }
        label{
            padding-bottom: 5px;
            margin-top: 5px!important;
        }
        .selectbox{
            display: block;
            position: relative;
            select{
                appearance: none;
                @media screen and (min-width: $small-width) {
                    //width: 50%;
                }
            }
            >select{
                width: 100%;
                padding-right: 26px;
            }
             &:after {
                top: 50%;
                right: 13px;
                margin: 0!important;
                transform: translate(0,-50%);
            }
        }
    }
    &.container-checkout{
        background-color: $white;
        padding: 0px;
        @media screen and (min-width: $small-width) {
            padding: 20px;
        }
        @media screen and (min-width: $medium-width) {
            padding: 60px;
        }

        .btn-checkout{
            width: 50%;
        }
        .form-group{
            input[type=checkbox] {
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;
                @media screen and (min-width: $medium-width) {
                    float: none;
                }
            }
            input[type=text]:disabled {
                background-color: $gray-light;
                color: $gray-base;
            }
             .label-checkbox{
                 text-align: left;
                @media screen and (min-width: $medium-width) {
                    display: inline-block;
                    text-align: left;
                }
            }
        }
        .logo-stripe, .logo-credit-cards{
            max-width: 175px;
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
        }
        .store-pick-up{
            display: none;
        }
        .wysiwyg-content {
            text-align: right;
            @media screen and (min-width: $small-width) {
                margin-bottom: 10px;
            }
        }

    }
    &.container-partial--registration, &.container-partial--forgot-password{
         padding: 0;
         @media screen and (min-width: $small-width) {
            padding: 20px;
         }
         .google-policies{
            max-width: 240px;
            text-align: left;
            display: inline-block;
        }
    }
    &.container-checkout,  &.container-partial--registration{
        .container-secure-shopping{
            background-color: $gray-light;
            padding: 19px; 
            min-height: 200px;
            max-width: 400px;
            margin-top: 80px; 
            margin-bottom: 40px;
            @media screen and (min-width: $small-width) {
                margin-top: 0;
                margin-left:0;
            }
            p{
                color:$gray-base;
            }

            .btn-checkout:disabled {
                background: $gray-base;
                &:hover {
                    cursor: default;
                    &:after{
                        opacity: 0;
                    }
                }
            }
            .item-parttype {
                max-width: 75%;
                word-break: break-word;
                display: inline-block;
            }
            .item-cost-price{
                width: 100%;
                height: 0;
                overflow: hidden;
                color:$black;
                transition:all .5s ease;               
            }
             .loader{ 
                background-color: rgba(220,220,220,.75);
                width: 90%;
                position: absolute;
                height: 253px;
                z-index: 10;
            }
            hr{
                background-color: $gray-xlight;
                height: 1px;
                margin: 20px 0;
            }
            .title {
                font-size: 14px;
                margin-bottom: 20px;
                @media screen and (min-width: $medium-width) {
                    font-size: inherit;   
                }  
            }
           
         }        
         label:first-child{
            text-align: left;
            padding-left: 0;
            @media screen and (min-width: $small-width) {
                text-align: right;
            }
        }
    }

    .container-description {
        padding-left: 0;
        padding-right: 0;
     @media screen and (min-width: $small-width) {
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
    }
        
    }

    &.container-partial--export {
        background-color: $color-secondary;
        z-index: 1001;
        position:fixed!important;
        width: 90%;
        margin: auto;
        top: 100px;
        padding: 25px;
        left: 0;
        right: 0;
         @media screen and (min-width: $small-width) {
            top: 200px;
            width: 500px;
         }
       .btn {
            width: 100%;
       }
       .chk-export {
           width: 25px;
            height: 25px;
       }
       .close-export {
        display: block;
        width: 30px;
        height: 30px;
        top: 0;
        right: 10px;
        &:before {
            font-size: 40px;
        }
       }
        p, label {
            font-family: $font-family--secondary;
            font-size: 13px;
            color: $white;
        }
        .selectbox {
             select {
                width: 100%!important;
            }
            &::after {
                border-color: transparent;
            }
        }
           
        .title {
            font-size: 20px;
            font-family: $font-family--primary;
            border-bottom: 1px solid $gray-light; 
            color: $white;
        } 
    }

    &.container-partial--login{
        //background-color: $white;
        padding: 0;
         @media screen and (min-width: $small-width) {
            padding: 20px;
         }
        
        .container-login {
            margin: auto;
            padding: 10px;
            .loading-icon {
              font-size: 16px;
              text-align: center;
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
              background-color: rgba(0, 0, 0, 0.75);
              padding: 30px;
              color: #fff;
              margin-top: 5px;
              .fa-spin {
                font-size: 100px;
                top: calc(50% - 50px);
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                width: auto;
                display: block;
              }
            }
        }
        .container-subscription {
            background-color: $gray-xlight;
            border: 1px solid $gray-light; 
            padding: 10px;
            width: 400px; 
            margin: auto;
            margin-top: 40px;
            
        }
        .fa {
            font-size: 20px;
            position: relative;
            left: 25px;
        }
        .form-group:first-of-type {
            margin-top: 40px; 
        }
        input[type=text], input[type=password]{
            width: 240px; 
            padding-left: 35px;
        }
        .input-password{
            width: 240px;
        }
        .google-policies{
            max-width: 220px;
            text-align: left;
            display: inline-block;
        }
    }
    &.container-partial--user-account {
        
        .container-profile, .container-address{
            margin-bottom: 50px;
            @media screen and (min-width: $small-width) {
                padding: 40px 0 20px;
                background-color: $white;
            }
             .selectbox:after, before {
                display: none 
            }
        }
     
        .email{
            margin: 5px 0 0;
            font-weight: bold;
            padding-left: 0;
            word-break: break-all;
        }
        h1{
            margin-bottom: 20px;
        }
        label:first-child{
            text-align: left;
            padding-left: 0;
            @media screen and (min-width: $small-width) {
                text-align: right;
            }
        }


    }
 
    form {
        @media screen and (min-width: 500px) {
            margin: 0 auto;
        }
        .form-group {
            @extend %clearfix;
            position: relative;
            label:first-child {
                display: block;
                margin: 15px 0 0;
            }
            label{
                input:not([type=radio], [type=checkbox]), textarea, select{
                    width: 100%;
                }
                img{
                    width: 100%;
                    height: auto;
                }
            }
            input{
                z-index: 1;
            }
            table{
                width: 100%;
                table-layout: fixed;
                th,td{
                    padding: 5px;
                    width: auto;
                    &:last-child{
                        width: 1%;
                    }
                }
                input[type=text],input[type=password],input[type=phone],input[type=email], textarea, select{
                    width: 100%;
                }
                .select2-container--default .select2-selection--single {
                    padding: 8px 8px;
                    text-align: left;
                    height: auto;

                }
            }
        }
    }
    .fields-enterprise {
        display: none;
    }
    &.has-error, .error {
        color: $color-warning;
    }
    input[type=text], input[type=email], input[type=password],input[type=phone], input[type=number], textarea{
        background-color: $white; 
        border: 1px solid $gray-base;
        color: $black;
        font-family: $font-family--secondary; 
        font-size: 14px;
        padding: 16px 10px;
        @media screen and (min-width: $medium-width) {
                padding: 8px 10px;
            }
        
        &.has-error {
            background-color: rgba($color-warning,0.1); 
            border-color:$color-warning;
            color: $color-warning;
        }
    }

    .input-password{
        background-color: $white; 
        border: 1px solid $gray-base;
        display: inline-flex;
        align-items: center;
        .fa:first-child{
            position: static!important;
            padding-left: 5px;
        }
        input{
            border: none !important;
            padding-left: 15px !important;
            width: auto !important;
            flex-grow: 1;
        }
        button{
            margin: 0;
            position: static !important;
            padding-right: 5px;
        }
    }

    .message-sucess{
        padding-bottom: 20px;
        color: $color-secondary;
    }
    .selectbox {
        position: relative;
        vertical-align: middle;
        @extend %transition-base;
        display: block;
        margin-bottom: 10px;
         @media screen and (min-width: $small-width) {
             display: inline-block;
        }
        select {
            background-color: $white;
            color: $base__font-color;
            border: 0;
            margin: 0;
            height: 50px;
            border: 1px solid rgba($gray-base, 1);
            font-family: $font-family--secondary;
            font-size: 13px;
            @extend %transition-base;
            min-width: 100px;
            padding: 0px 10px;
            max-width: 100%;
            @media screen and (min-width: $medium-width) {
                padding: 6px 10px;
                 height: 35px;
            }
        }
        label + div select{
            width: 100%;
        }
        &:after,
        &:before {
            content: "";
            position: absolute;
            pointer-events: none;
            cursor: pointer;
            @extend %transition-base;
        }
    }
}

.js--fake_input_number{
    background-color: #FFF;
    border: 1px solid $color-primary;
    color: #313236;
    font-size: 14px;
    height: 28px;
    padding: 0;
    width: 100%;
    @include flex();
    flex-wrap: nowrap;

    .js--fake_input_control{
        height: 100%;
        flex: none;
        .more,.less{
            width: 21px;
            height:50%;
            position: relative;
            display: block;
            cursor: pointer;
            border-left: 1px $gray-light solid;
            &:hover{
                background: $gray-xxlight;
            }
            &:active{
                background: $gray-xdark;
            }
            &:before{
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
    .more:before{
        border-width: 0 5px 10px 5px;
        border-color: transparent transparent $gray-xdark transparent;
    }
    .less:before{
        border-width: 10px 5px 0 5px;
        border-color: $gray-xdark transparent transparent transparent;
    }

    .more:active:before{
        border-color: transparent transparent $white transparent;
    }
    .less:active:before{
        border-color: $white transparent transparent transparent;
    }

    label{
        align-self: center;
    }
   
    input[type=number] {
        //color: $color-primary;
        font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 20px;
        //font-weight: 700;
        margin-top: -10px;
        padding: 5px 10px;
        width: 10px;
        -moz-appearance: textfield;
        -webkit-appearance: textfield; 
        appearance: textfield;
        margin: 0;
        outline: 0;
        border: 0 !important;
        max-height: 100%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none; 
            margin: 0; 
        }
    }
    
    @media screen and (max-width:$medium-width){
        height: 40px;
        min-width: 175px;
        .js--fake_input_control{
            .more,.less{
                height: 100% !important;
                float: right;
                width: 39px;
                &:after {
                    content: '';
                    border: 1px solid  $gray-xdark;
                    border-radius: 30px;
                    top: 50%;
                    left: 50%;
                    height: 24px;
                    width: 24px;
                    -webkit-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                    display: block;
                    position: absolute;
                }
                &:before{
                    border: 0 !important;
                    height: inherit!important;
                    width: inherit!important;
                    font-style: normal;
                    font-size: 27px;
                    text-align: center;
                    color:  $gray-xdark;
                }

                &:active{
                    &:after {
                        border: 1px solid  $white;
                    }
                    &:before{
                        color:  $white;
                    }
                }
            }
            .more{
                &:before{
                    content: "+"!important;
                }
            }
            .less{
                &:before{
                    content: "_"!important;
                    transform: translate(-50%,-50%) translateY(-11px)!important;
                }
            }
        }
        
    }
}
.repeater-type{
    [data-repeater]{
        >*{
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $black;
        }
    }
}
.container-description {
    .box--gray {
        font-size: 18px;
        text-align: justify;
        font-family: $font-family--primary;
        text-transform: uppercase; 
        margin: 20px 0; 
        img {
            height: 40px;
        }
    }
}

input.input-hidden{
    display: none;
    &[type=checkbox],&[type=radio]{
        +.input-checkbox--fake{
            display: block;
            width: 18px;
            height: 18px;
            min-width: 18px;
            float: left;
            margin-right: 10px;
            border: 1px solid $color-primary;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            padding: 0 !important;
            &:after{
                content: "";
                position: absolute;
                display: block;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                background: transparent;
            }
        }
        &:checked{
            +.input-checkbox--fake{
                &:after{
                    background: $color-primary;
                }
            }
        }
        &:indeterminate{
            +.input-checkbox--fake{
                &:after{
                    background: $color-primary;
                    top:calc(50% - 1px);
                    bottom:calc(50% - 2px);
                }
            }
        }

        &:disabled,&[read-only]{
            +.input-checkbox--fake{
                border-color: $gray-light ;
                cursor: default;
            }

            &:indeterminate,&:checked{
                +.input-checkbox--fake{
                    &:after{
                        background:  $gray-light ;
                    }
                }
            }
        }
    }
    &[type=radio]{
        +.input-checkbox--fake{
            border-radius: 50%;
            &:after{
                border-radius: 50%;
            }
        }
    }
    &.hide{
        +.input-checkbox--fake{
            display: none;
        }
    }
}

.container-partial--forgot-password {
    .fa-envelope {
        font-size: 20px;
        position: relative;
        left: 10px;
    }
    input[type=text] {
        width: 230px;
        padding-left: 35px;
        margin-left: -20px; 
        @media screen and (min-width: $small-width) {
            width: 380px;
        }
    }
}

input[type=checkbox], input[type=radio] {
    border: 1px solid #878787;
}
input[type=radio]:checked {
    background-color: black;
}

.download-pdf, .share-page {
    width: 350px;
    z-index: 99;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    background: $gray-light;
    padding: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    font-family: $font-family--secondary;
    button {
      margin-top: 20px;
      font-family: $font-family--primary; 
      font-size: 23px;
    }
    input, textarea {
        margin-left: 0;
        margin-right: 0;
        font-family: $font-family--secondary;
        background-color: white;
        margin-bottom: 10px;
        padding: 10px;
        margin-top: 10px;
    }
    .fa-close:hover {
      cursor: pointer;
    }
  }


 .store-form {
     .administration-panel {
         background: #eee;
     }
 }


form.input-search-bar{
    @include flex;
    flex-wrap: nowrap;
    border: 1px solid $color-primary;
    input[type=search]{
        -webkit-appearance: none;
        border-right: 0;
        border-right: 0;
        height: 33px;
        padding: 0 10px;
        flex-grow: 1;
        width: 50px;
        background: $white;
    }
    button{
        height: 33px;
        background: $white;
        color: $color-primary;
        border-left: 0;
        border-right: 0;
        padding:0 5px;
        cursor: pointer;
        .fa{
            margin: 0;
        }
        .fa,.fa:hover{
            color: $color-primary;
        }
    }
}

.suggestions-list{
    background-color: #fff;
    border: 1px solid #aaa;
    box-sizing: border-box;
    display: block;
    z-index: 1051;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    li{
        padding: 6px;
        font-family: $font-family--secondary;
        font-size: 14px;

        &.selected{
            background-color: #ddd;
        }
        &:hover{
            background-color: #5897fb;
            color: #fff; 
        }
    }
}

/* select2 responsive*/
@media screen and (max-width:$small-width  - 1px){
    .select2{
        &-container{
            &:not(.select2){
                position: fixed !important;
                z-index: 9999999;
                top: 0 !important;
                left: 0 !important;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.75);
                padding: 20px;
            }

            &--open{
                .select2-dropdown{
                    border-radius: 4px !important;
                }
            }
        }
        &-dropdown{
            width: 100% !important;
            position: static;
        }
        &-results{
            .select2-results__options{
                max-height: calc(100vh - 75px) !important;
            }
        }
    }
}