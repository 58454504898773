/*html.landing{
    height: 100%;
    overflow: auto;
    body {
        overflow: visible;
    }
}*/
body {
	background-color: $white; 
    margin: 0; 
}

.site-container{
    min-height: 100vh;
    display: block;
    grid-template-rows: auto 1fr auto;
    @media screen and (max-width: $container-width) {
		display: block;
	}	
}