.price-list-container {
  margin-top: 20px;
  overflow-y: scroll;
  display: block;
  height: 95%;
}


.table-p-5{
  td, th{
    padding: 5px;
  }
}

.table-ph-10{
  td, th{
    padding-right: 10px;
    padding-left: 10px;
  }
}