// ========================================================
// Wysiwyg Components
// ========================================================

.wysiwyg-container,
body#tinymce.wp-editor,
.mce-content-body {
    @extend %clearfix;
    @media screen and (min-width: 850px) {
        padding-bottom: 0;
    }

    //padding: $spacing-base-vertical;

    //height: 100%;
    
	background-color: $white;
    position: relative;

	//font-family: $font-family--primary;
	//font-family: 'Lato';
    
    width: 100%;
    color: $base__font-color;

	h1, .h1 {
		color: $base__font-color;
		font-weight: bold;
		font-size: 40px;
		margin: 1em 0 0.5em 0;
		letter-spacing: $letter-space;
		line-height: 1em;
		text-transform: uppercase;
	}

	h2, .h2 {
		color: $base__font-color;
		font-weight: normal;
		font-size: 22px;
		text-transform: uppercase;
		letter-spacing: $letter-space;
		margin: 1em 0 0.5em 0;
        line-height: 1.2em;
        font-weight: bold;
	}

	h3, .h3 {
		color: $base__font-color;
		font-size: em(22px);
		text-transform: uppercase;
		margin: 1em 0 0.5em 0;
		letter-spacing: $letter-space;
		line-height: 40px;
	}

	h4, .h4 {
		color: $base__font-color;
		font-size: em(18px);
		line-height: 20px;
		letter-spacing: $letter-space;
		font-weight: normal;
		margin: 1em 0 0.5em 0;
	}

	p {
		color: $base__font-color;
		font-size: em(16px);
		line-height: $base__line;
		letter-spacing: $letter-space;
		margin-bottom: 1em;

		&.white { color: $white; }
		&.black { color: $black; }
		&.bolder { font-weight: bold; }
	}

	a {
		color: $color-primary;
        font-weight: 500; 
        font-size: em(16px);
        text-decoration: none;
        transition: all 250ms ease;
		letter-spacing: $letter-space;
 
		&:active,
		&:focus,
		&:visited {
            color: $color-primary--lighten;
            text-decoration: none;
		}
        
        @media only screen and (min-width: $nohover-width) {
            &:hover {
                color: rgba($color-primary--darken, 0.8);
            }
        }
	}

    ul {
        color: $base__font-color;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 20px;
        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }

            &:before {
                position: absolute;
                content: "";
                left: 7px;     
                top: 4px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-primary;
            }
        }
        &.style-check{
            li:before {
                content: "\f00c";
                top:0;
                font: normal normal normal 16px/1 FontAwesome;
                color: $color-primary;
                background: transparent;
            }
        }
    }


    ol {
        font-size: 1em;
        color: $gray-base;
        padding: 0;
        margin: 0 0 20px 0;
        list-style-type: none;
        margin-bottom: 20px;
        
        li {
            counter-increment: step-counter;
            position: relative;
            padding: 0 0 0 30px;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            &:before {
                position: absolute;
                left: 0;
                top: 2px;
                content: counter(step-counter);
                font-size: 16px;
                //background-color: $color-primary;
                color: $color-primary;
                width: 20px;
                height: 20px;
                display: inline-block;
                text-align: center;
            }
        }
    }

    blockquote,
    blockquote p {
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: $gray-dark;
        margin: 40px 0;
        position: relative;
        padding: 20px 0;
    }
    
    blockquote p {
        margin: 0;
    }
    
    blockquote:before, 
    blockquote:after {
        content: '';
        background: $gray-dark;
        height: 4px;
        position: absolute;
        width: 60%;
        left: 50%;
        margin-left: -30%
    }
    
    blockquote:before { top: 0; }
    
    blockquote:after { bottom: 0; }
    
    img { 
        @extend .img-responsive;
    }
    
    a.btn, .btn {
        color: $white;
        @media only screen and (min-width: $nohover-width) {
            &:hover {
                color: $white;
            }
        }
    }

    .wp-block-button{
        
        &__link{
            background-color: rgba($color-primary, 0.3);
            color: $black;
            border-radius: 5px;
            font-weight: bold;
            text-transform: uppercase;
            border: 1px solid $color-primary;
            font-family: $font-family--secondary;

            
            &:hover {
                color: $black;
                background-color: rgba($color-primary, 0.5);
            }
        }

        &.is-style-outline{
            .wp-block-button__link{
                background:transparent;
                color: $black;

                &:hover {
                    background-color: rgba($color-primary, 0.5);
                }
            }
        }
    }
    
    .media-container {
        position:relative;
        margin-bottom:30px;
    }

    &--bordered {
        border: 1px solid $gray-base;
        padding: 15px;
    }

    &--background{
        &-transparent{
            background: transparent;
        }
    }

}