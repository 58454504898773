.container-settings {
    &--catalog {
        .container-toggle-mode {
            font-size: 14px;
            padding: 5px;
            text-align: center;
            border: 1px solid #DCDCDC;
            background-color: #F5F5F5;
            .toggle-button {
                text-align: center;
                font-size: 14px;
                margin-top: 15px;
            }
            .toggle-button--text {
                font-family: $font-family--secondary;
                padding: 5px;
                font-size: 14px;
                text-align: center;
            }
        }
        .icon-loading {
            z-index: 201;
            display: none;
            top: 50%;
            transform: translateY(-50%);
            position: fixed;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
        .left-side {
            @media screen and (max-width: $xsmall-width) {
                text-align: center;
             }
             .box--gray {
                @media screen and (max-width: $xsmall-width) {
                    text-align: left;
                    margin: 10px;
                 }
             }
            .btn {
                margin: 10px;
                min-width: 62px; 
                width: 90%;
                @media screen and (min-width: $medium-width) {
                    margin-left: 0;
                    width: 100%;
                }
            }
            .fa-trash {
                cursor: pointer;
                @extend %transition-base;
                &:hover {
                    color: $color-primary;
                    @extend %transition-base;
                }
            }
            .title {
                color: $color-secondary;
                margin: 20px 0 0 0;
                 @media screen and (min-width: $small-width) {
                    display: inline-block;
                }
            }
            .toggle-list {
                @extend %transition-base;
                &:hover {
                    color: $color-primary;
                    @extend %transition-base;
                }
            }
        }
        .right-side {
            .container-brands {
                 .container-brand {
                     @media screen and (max-width: $small-width) {
                        width: 30%;
                        margin: 4px;
                    }
                    .brand-item {
                        @media screen and (max-width: $small-width) {
                            height: 90px;
                        }
                    }
                }
                .manufacturer {
                    .container-droplist-part-type {
                        @media screen and (max-width: $small-width) {
                            top: inherit;
                        }
                    }
                }
                .text {
                    padding: 10px;
                    max-width: 1100px;
                    font-family: $font-family--secondary;
                    color: black;
                     @media screen and (min-width: $small-width) { 
                        padding: 0;
                     }
                }
                .title {
                    margin-top: 20px;
                }
            }
        }
        .fa-save {
            font-size: 22px; 
        }
        .site-page__title {
            background-color: $color-primary;
        }
        .tabs {
            background-color: #DCDCDC;
            .active {
                background-color: $color-primary; 
                color: white;  
            }
            .tab {
                padding: 20px 10px;
                @media screen and (min-width: $small-width) {
                    padding: 20px;
                    
                }
            }
        }
    }
    &--dashboard {
        .box--gray {
            .image {
                background-color: $color-primary;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 150px;
                margin: auto;
                mask-position: center;
                mask-image: url(/dist/images/icon-product-directory.svg);
                mask-repeat: no-repeat;
                &--linecode {
                    mask-image: url(/dist/images/icon-linecode-editor.svg);
                }
                &--profile {
                    mask-image: url(/dist/images/icon-profile.svg);
                }
                &--multy-user {
                    mask-image: url(/dist/images/icon-multy-user.svg);
                }
                &--jobs {
                    mask-image: url(/dist/images/icon-jobs.svg);
                }
                &--location {
                    mask-image: url(/dist/images/icon-location.svg);
                }
            }
            @extend %transition-base;
            border: 3px $gray-light solid;
            &:hover {
                @extend %transition-slow;
                border: 3px $color-primary solid;
            }
             
            p {
                font-size: 13px; 
                &.bold{
                    font-weight: 600;
                }
            }
            &__locked{
                &:hover {
                    @extend %transition-slow;
                    border: 3px $gray-light solid!important;
                    cursor: default;
                }
            }
        }
        .site-page__title {
            background-color: $color-primary;
        }
    }
    &--custom-manager {
        form p{
            font-family: $font-family--secondary;
        }
    }
    &--linecode {
        .btn--save-linecode {
            min-width: 62px;
        }
        .btn--edit {
            &:disabled {
                background-color: $gray-light!important;
                &:hover {
                    cursor: default;
                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
        .buttons {
            text-align: center;
            @media screen and (min-width: $small-width) {
                text-align: right;
            }
        }
        .category {
            border-bottom: 2px solid $color-primary;
            margin-top: 20px;
            @include flex();
            flex-wrap:  nowrap;
            p {
                font-size: 20px;
                color: $color-primary; 
                margin-top: 10px;
            }

            .fieldset-text{
                flex-grow: 1;
                padding: 0 5px;
            }

            .fieldset-btn{
                white-space: nowrap;
            }
        }
        hr {
            background-color: $gray-light;
        }
        input {
            border: 1px solid $color-secondary; 
            background-color: $white;
            padding: 2px 5px;
            width: 100%; 
            max-width: 90px;
            &:disabled {
                background-color: $gray-xlight;
                border: 1px solid $gray-base; 
            }

            &[type=checkbox]{
                width: 16px;
                height: 16px;
            }
        }
        .linecode {
            text-align: center;
            margin-bottom: 20px;
            @media screen and (min-width: $small-width) {
                text-align: left;
                margin-bottom: 0;
            }
        }
        .linecode-brand {
            @media screen and (min-width: $small-width) {
                margin-left: 20px;
            }
        }
        .logo {
            max-height: 80px;
        }
        .my-brand {
            font-size: 14px;
        }
        .my-parts-brand {
            p {
                font-family: $font-family--secondary;
            }
        }
        .my-parts {
            border-top: 2px solid $gray-light; 
        }
        select {
            background-color: white;
            border: 1px solid $gray-light;
            padding: 5px;
            width: 100%;
            font-family: $font-family--secondary; 
            -webkit-appearance: none;  
        }
        .selectbox {
            margin-bottom: 40px;
            @media screen and (min-width: $small-width) {
                margin-bottom: 0; 
            }
        }
        .site-page__title {
            background-color: $color-primary;
            p {
                font-weight: normal;
                font-size: 14px;
                text-transform: initial;
                font-family: $font-family--secondary;
                text-align: left;
            }
        }
        .subcategory {
            border-bottom: 1px solid $gray-light; 
            @extend %transition-base;
            &:hover {
                background-color: $gray-light;
                @extend %transition-base;
            }
        }

        .fa.js-job-input{
            font-size: 20px;
            margin-left: 10px;
        }
        .js-category{
            margin:0;
            background: $white;
            position: relative;
            .handle{
                background: $white;
                cursor: move;
                position: absolute;
                top: 0;
                left: -0;
                height: 100%;
                width: 0;
                border-radius: 10px 0 0 10px;
                box-shadow: -3px 3px 4px -1px rgba(0,0,0,0.3);
                border: 0px solid #efefef;
                -webkit-transition: all 200ms ease;
                -moz-transition: all 200ms ease;
                -ms-transition: all 200ms ease;
                -o-transition: all 200ms ease;
                transition: all 200ms ease;
                &:before{
                    display: block;;
                    content: '';
                    width:  calc(100% - 10px);
                    height: calc(100% - 10px);
                    margin:5px;
                    background-image: radial-gradient(#e0e0e0 35%, transparent 0%);
                    background-size: 6px 6px;
                    background-position: center;
                }
            }

            &:hover{
                .handle{
                    width: 30px;
                    border: 1px solid #efefef;
                    left: -30px;
                }
            }
        }

        .repeat-job{
            background-color: $gray-light;
            min-height: 225px;
            max-width: 260px;
            margin: auto;
            position: relative;
            overflow: hidden;
            display: block;
            .fa{
                position: absolute;
                top:50%;
                left: 50%;
                font-size: 100px;
                transform: translate(-50%,-50%);
            }
            span{
                position: absolute;
                bottom: 20px;
                left: 5%;
                width: 90%;
                text-align: center;
            }
        }
    }

    &--map-store-editor{
        .h3{
            color: $color-secondary;
        }
        display: table;
        aside{
            .container{
                width:100%;
                min-width:300px;
                position: absolute;
                padding: 10px;
                height: 100%;
                @include flex(column);

            }
            .search-geo-preset{
                margin-bottom: 10px;
                position: relative;
                input{
                    width: 100%;
                    border: 1px solid $color-secondary;
                    padding: 5px;
                }
                ul{
                    
                    background: white;
                    border: 1px solid $color-secondary;
                    border-top:0;
                    position: absolute;
                    list-style: none;
                    top: 100%;    
                    width: 100%;
                    z-index: 1000;
                    max-height: 250px;
                    overflow: auto;

                    li{
                        border-top: 1px solid #DCDCDC
                    }
                }
                button{
                    cursor: pointer;
                    padding: 10px;
                    width: 100%;
                    text-align: left;
                    &:hover{
                        background: #eee;
                    }
                }

                &-title{
                    color:black;
                    display: block;
                    font-size:1.1em;
                }
                &-description{
                    color:black;
                    display: block;
                    font-size:0.8em;
                }

            }
            

            .js--layer-demo{
                display: none !important; 
            }
            &.open{
                min-width: 300px;
            }

            .layers-viewport{
                flex-grow: 1;
                border-bottom: 1px solid #DCDCDC;
                position: relative;
            }

            .layers{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: auto;
                button{
                    cursor: pointer;
                }
                li{
                    list-style: none;
                    
                }
                &-layer{
                    flex-direction: row;
                    display: flex;
                    align-content: stretch;
                    align-items: stretch;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    padding: 10px 0;
                    border-top: 1px solid #DCDCDC;
                    >*{
                        flex-grow: 0;
                        flex-shrink: 1;
                        order: 0;
                        flex-basis: auto;
                        align-self: auto;
                        float: none;
                    }

                    &-preview{
                        max-width: 40px;
                        min-width: 40px;
                        height: 40px;
                        transform: scaleY(-1);
                    }
                    &-name{
                        flex-grow: 1;
                        padding: 0 10px;
                    }
                    
                }
            }
            min-width:1px;
            display: table-cell;
            height:100%;
            overflow: hidden;
            overflow-y: auto;
            position: relative;

            -webkit-transition: min-width 500ms ease;
            -moz-transition: min-width 500ms ease;
            -ms-transition: min-width 500ms ease;
            -o-transition: min-width 500ms ease;
            transition: min-width 500ms ease;
        }
        .map{
            width: 100%;
            height:100%;
            display: table-cell;
            >*{
                width: 100%;
                height: 100%;
            }
        }
    }

}

.icon{
    &--ebay{
        height: 25px;
        width: 30px;
        margin-right: 10px;
        display: inline-block;
        mask-image: url("../images/icons/ebay-brands.svg");
        background-color: $color-primary;
        mask-repeat: no-repeat;
        &__small{
            height: 25px;
            width: 25px;
        }
        &__gray{
            background-color: $gray-base ;
        }
    }
    &--amazon{
        height: 25px;
        width: 25px;
        margin-right: 10px;
        display: inline-block;
        mask-image: url("../images/icons/amazon-brands.svg");
        background-color: $black;
        mask-repeat: no-repeat;
        &__small{
            height: 20px;
            width: 20px;
        }

        &__gray{
            background-color: $gray-base ;
        }
    }
    &--shopify{
        height: 25px;
        width: 25px;
        margin-right: 10px;
        display: inline-block;
        mask-image: url("../images/icons/shopify-brands.svg");
        background-color: $black;
        mask-repeat: no-repeat;
        &__small{
            height: 20px;
            width: 20px;
        }
        &__gray{
            background-color: $gray-base ;
        }
    }
    &--edit{
        height: 25px;
        width: 30px;
        margin-right: 10px;
        display: inline-block;
        mask-image: url("../images/icons/edit-icon.svg");
        background-color: $color-primary;
        mask-repeat: no-repeat;
    }
    &--export{
        height: 16px;
        width: 16px;
        margin-right: 10px;
        display: inline-block;
        mask-image: url("../images/icons/export-icon.svg");
        background-color: white;
    }
}

