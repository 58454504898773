.divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: $gray-light;
	margin-top: 20px;
	margin-bottom: 20px;

	// Modifiers

	&--center {
		margin-left: auto;
		margin-right: auto; }

	&--dark {
		background-color: $gray-dark; }

	&--light {
		background-color: $white; }

	&--w90 { width: 90%; }
	&--w80 { width: 80%; }
	&--w70 { width: 70%; }
	&--w60 { width: 60%; }
	&--w50 { width: 50%; }
}

.bordered-r {
	border-right: 1px solid $gray-light;
}
.bordered-l{
	border-left: 1px solid $gray-light;
}

.box{
	padding: $spacing-medium;
    border: 1px solid $gray-light;
    height: 100%;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-decoration: none;
    background: $white;
	@include flex(column);
	justify-content: space-between;


	img{
		max-width: 100%;
	}
	&__content{
		flex-grow: 1;
	}

	&__footer{
		border-top: 1px solid #DCDCDC;
		margin-left: -$spacing-medium;
		margin-right: -$spacing-medium;
		padding: $spacing-medium $spacing-medium 0 $spacing-medium ;
	}

}