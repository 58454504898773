
    .availability{
        transition: all .5s ease;
        transform: translate(0);
        width: 100%;
        &__row-cell{
            float: left;
            transition: all .5s ease;
            //width: calc(50% - 2px);
            border:1px solid transparent;
            text-align: left;
            padding-left: 5px;
            @media screen and (min-width: $medium-width) {
                text-align: right;
                padding: 0;
            }
            &:first-child:not(:last-child){
                font-weight: bold;
                text-align: right;
                padding-right: 5px;
                @media screen and (min-width: $medium-width) {
                    text-align: left;
                    padding: 0;
                }
            }
            &:first-child:last-child{
                text-align: center;
                width: 100%;
                @media screen and (min-width: $small-width) {
                    text-align: right;
                }
            }
        }
        .stock{
            display:grid;
            justify-content: right;
            justify-items: right;
        }
        &__show-hover{
            width: 100%;
            height: 0;
            overflow: hidden;
            color:$color-primary;
        }
        &:hover{
            .availability__show-hover{
                height: 30px;
            }
        }
    }
.alternate-numbers{

    &__alternative-part{
        &__show-hidden-row-hover:hover{
            .alternate-numbers__alternative-part__hidden-row{
                height: 20px!important;
            }
        }
        &__hidden-row,&__row{
            transform: translateX(-20px);
            float: left;
            background: #fff;
            border-left: 1px solid #a2a2a2;
            color: #000;
            border-right: 1px solid #bdbdbd;
            text-align: center;
            width: calc(100% + 40px);
            display: block;
            transition: all .5s ease;
        }
        &__hidden-row{
            height: 0;
        }
        &__price {
            float: left;
            padding: 7px 10px;
            font-weight: bold;
        }
        &__stock {
            float: right;
            padding: 7px 10px;
            color: #000;
        }
        &__first-row{
            display: block;
            width: calc(100% + 40px);
            transform: translateX(-20px);
            float: left;
            background: $gray-light;
            background:rgba($color-primary, $alpha: 0.2);
            border-left: 1px solid #a2a2a2;
            border-top: 1px solid #cccccc;
            border-right: 1px solid #bdbdbd;
            .addtocart-container{
                flex-wrap: nowrap;
            }

            @media screen and(max-width:1500px) {
                .addtocart-container{
                    width: calc(100% - 10px);
                    float: none;
                    margin: 5px;
                }
            }
            
        }
        &__second-row{
             display: block;
             width: calc(100% + 40px);
             -webkit-transform: translateX(-20px);
             transform: translateX(-20px);
             float: left;
             background: $gray-light;
             background:rgba($color-primary, $alpha: 0.2);
             border-bottom: 1px solid #a2a2a2;
             border-left: 1px solid #a2a2a2;
             border-right: 1px solid #bdbdbd;
        }
        &__infos{
            float: left;
            width: 50%;
            min-height: 50px;
            &__text {
                color: #000;
                padding: 5px 10px;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                
            }
            @media screen and(max-width:1500px) {
                width: 100%;
                min-height: 0px;
                &__text {
                    position: static;
                    transform: none; 
                }
            }
        }
        /*&__quantity-control{
            float: left;
            max-width: 25%;
            padding: 0;
            margin: 0;
            min-height: 40px;
            margin-top: 4px;
            transform: translateX(-5px);
            .qty-num {
                margin: 7px 0;
                background: #fff;
                //transform: translateX(-5px);
                width: 100%;
            }
            @media screen and (min-width:1025px) and (max-width:1650px){ // JUST TOO SMALL
                .js--fake_input_control{
                    display: none;
                }
            }
        }*/
        &__btn{
            float: left;
            width: 23%!important;
            margin: 0;
            height: 40px;
        }
    }
    &__btn-alternate{
        font-size: 14px!important;
        font-weight: normal!important;
        position: absolute;
        bottom: 10px;
        outline: none;
        left: 0;
    }
}
.container-step3 {
    background-color: white;
    button.btn--buyers-guide {
        letter-spacing: -0.2px;
    }

    .btn--filter {
        background: $color-primary;
        text-align: center;
        color: $white;
        padding: 15px;
        font-size: 20px;
        font-weight: 700;
        margin: 20px;
        width: 90%;
        @media screen and (min-width: $medium-width) {
            display: none;
        }
    }
    .btn-expand {
        left: 0;
        top: 0;
        position: absolute;
        background-color: $color-primary;
        padding: 7px 10px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        font-size: 20px;
         @media screen and (max-width: $small-width) {
            display: none;
        }

    }
    .btn-mobile-cart {
        right: 150px;
    }
    .btn-see-more {
        &:hover {
            cursor: pointer;
            color: $color-primary;
        }
        .fa-plus-circle, .fa-minus-circle {
            font-size: 13px;
            display: inline-block;
            float: none;
            padding-right: 5px;
        }
    }
    .button-toggle {
        display: inline-block;
        margin-bottom: 10px;
        .fa {
            font-size: 16px;
            float: none;
        }
        &:hover {
            cursor: pointer;
        }
    }


    .left-side {
        padding: 0;
        display: none;
        opacity: 0;
        width: 100%;
        @extend %transition-base;

        @media screen and (min-width: $medium-width) {
            transform: inherit;
            display: block;
            position: inherit;
            width: 20%;
            padding: 20px;
            padding-right: 40px;
            opacity: 1;
        }
        &.is-open {
            display: block;
            opacity: 1;
            background-color: $white;
        }

        .container-categories, .container-filter-diagrams, .container-toggle-diagrams {
            font-family: $font-family--secondary;
            font-size: 13px;
            background-color: $gray-xlight;
            border: 1px solid $gray-light;

            &__slider-container{
                &__title{
                    font-size: 14px;
                    font-weight: bold;
                    color: #000;
                    padding: 20px 64px;
                    text-transform: uppercase;
                    @media screen and (max-width: $medium-width) {
                        padding: 20px;
                    }
                }
                &__slider{
                    -webkit-appearance: none;
                    background: transparent;
                    outline: none;
                    cursor: pointer;
                    border-color: transparent;
                    color: transparent;
                    width: 80%;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: 0 0 20px 0;
                    @media screen and (max-width: $medium-width) {
                        width: 100%;
                    }
                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: 1px solid #000000;
                        height: 36px;
                        width: 16px;
                        border-radius: 3px;
                        background: #ffffff;
                        cursor: pointer;
                        margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
                    }
                    &::-moz-range-thumb {
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border: 1px solid #000000;
                        height: 36px;
                        width: 16px;
                        border-radius: 3px;
                        background: #ffffff;
                        cursor: pointer;
                    }
                    &::-ms-thumb {
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border: 1px solid #000000;
                        height: 36px;
                        width: 16px;
                        border-radius: 3px;
                        background: #ffffff;
                        cursor: pointer;
                    }
                    &::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 8.4px;
                        cursor: pointer;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border-radius: 1.3px;
                        border: 0.2px solid #010101;
                    }
                    &::-moz-range-track {
                        width: 100%;
                        height: 8.4px;
                        cursor: pointer;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border-radius: 1.3px;
                        border: 0.2px solid #010101;
                    }
                    &::-ms-track {
                        width: 100%;
                        height: 8.4px;
                        cursor: pointer;
                        background: transparent;
                        border-color: transparent;
                        border-width: 16px 0;
                        color: transparent;
                    }
                    &::-ms-fill-lower {
                        border: 0.2px solid #010101;
                        border-radius: 2.6px;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                    }
                    &::-ms-fill-upper {
                        border: 0.2px solid #010101;
                        border-radius: 2.6px;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                    }
                }
            }
            @media screen and (min-width: $small-width) {
                padding: 0 50px;
            }
            @media screen and (min-width: $medium-width) {
                padding: 0;
            }
            .categories {
                clear: both;
                background: transparent;
            }

            .filter-icon{
                float: left;
            }
             .filter-name{
                display: block;
                padding-left: 15px;
                word-break: break-word;
            }
            > ul > li {
                @media screen and (min-width: $medium-width) {
                    margin: 0 30px;
                }
            }
            ul {
                padding-bottom: 20px;
                li {
                    padding: 10px;
                    list-style: none;
                     @media screen and (min-width: $medium-width) {
                        padding: 4px;
                     }
                }
            }
            > ul > li:not(:last-of-type) {
                border-bottom: 1px solid $gray-light;
                list-style: none;
            }
            .submenu-handle {
                display: block;
                cursor: pointer;
                width: 95%;
                font-weight: bold;
                font-size: 14px;
                text-transform: uppercase;
                color: $color-secondary;
                padding-top: 20px;
                padding-bottom: 10px;
            }
            span,
            i {
                font-style: normal;
            }
        }
        .container-toggle-diagrams {
            height: 120px;
            padding: 5px;
            font-size: 14px;
            text-align: center;
            margin-bottom: 0;
            border-bottom: 0;
            @media screen and (min-width: $medium-width) {
                height: 100px;
                text-align: left;
                margin-bottom: 20px;
                border: 1px solid $gray-light;
            }
        }
        .container-filter-diagrams {
            margin-bottom: 20px;
        }
        .chk {
            width: 18px;
            height: 18px;
            float: left;
            margin-right: 10px;
        }
        .container-btn--reset {
            padding: 10px 0;
            position: relative;
            text-align: center;
            margin: 0 0 20px;
            background: linear-gradient(0deg, $gray-light 0%, $gray-light 63%, $gray-light 100%); /* w3c */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gray-light', endColorstr='$gray-lighty',GradientType=0 ); /* ie6-9 */
            transform-style: preserve-3d;
            @extend %transition-base;
            display: block;
            .reset-icon {
                height: 40px;
                width: 40px;
                background-image: url('/dist/images/icon-reset-red.svg');
                background-repeat: no-repeat;
                background-size: cover;
                margin: auto;
            }
            .reset-text {
                color: $color-primary;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                margin-top: 10px;
            }
        }
        .filter {
            label {
                word-break: break-word;
                display: block;
                font-size: 13px;
            }
        }
    }
    .parttype-sticky-header{
        position: fixed;
        top: 0;
        z-index: 99;
        background-color: $color-primary!important;
        display: none;
    }
    .right-side {
        padding: 0px;
        @media screen and (min-width: $medium-width) {
            width: 80%;
            padding: 20px;
        }
        .listint-pictos{
            width: 100%;
            text-align:right;
            &__block{
                /*float: left;*/
                /*width: 100%;*/
                /*height: 64px;*/
                vertical-align: bottom;
                position: relative;
                display: none;
                margin-top:5px;
                &.active{
                    display: inline-block;
                }
                &__picto{
                    color: #9e9e9e;
                    position: relative;
                    text-align:center;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    &:before{
                        position: relative;
                        transform: translateY(-50%);
                        top:50%;
                        display: inline-block;
                    }
                    &:after{
                        width: 100%;
                        height: 100%;
                        border-radius: 40px;
                        content: '';
                        border: 1px solid;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        color: #9e9e9e;
                    }
                }
                &__text{
                    color: #9e9e9e;
                    float: left;
                    position: absolute;
                    left: 60px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .container-button{
            .button{
                padding: 12px 0px 12px 20px;
            }
        }
        .brand-info {
            padding-right: 0;
        }
        .diagram {
            img {
                width: 100%;
            }
        }
        .part {
            padding: 0;
            margin: 20px 0;
            border: 1px solid #d6d6d6;
            background-color: $white;
            position: relative;
            font-family: $font-family--secondary;
            font-size: 14px;
            //min-height: 402px;

            @include flex();

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 100%;
                left: -5px;
                position: absolute;
                transform: translateX(-100%);
                transition: all .3s ease;
            }
            &.zoned,&.in-territory{
                position: relative;
                &:before {

                }
                .image-gallery{
                    height: 380px;
                }
                .part-middle{
                    //height: 400px!important;
                }
                &:before {
                    width: 10px;
                }
                .part-right{
                    position: relative;
                    &:before {
                        background: $color-primary;
                        content: "";
                        //min-height: 400px;
                        width: 100%;
                        left: 0;
                        top: 0;
                        opacity: .15;
                        position: absolute;
                        height: 100%;
                    }
                }
            }

            &.zoned{
                &:before {
                    background: #ed760d;
                }
                .part-right{
                    &:before {
                        background: $color-primary;
                    }
                }
            }
            &.in-territory{
                &:before {
                    background:  #5df200 !important;
                }
                .part-right{
                    &:before {
                        background: #5df200;
                    }
                }
            }

            .part-middle {
                padding: 0;
                background-color: #f8f8f8;
                background-position: right -100px top -98px;
                background-repeat: no-repeat;
                background-size: 80%;
                @media screen and (min-width: $small-width) {
                    min-height: 320px;
                }
                .attributes {
                    a {
                        color: #EE0000;
                        font-weight: bold;
                    }
                    h3 {
                        padding: 7px 7px 7px 0;
                        font-size: 18px;
                        font-weight: bold;
                        background: transparent;
                        color: $color-secondary;
                        text-transform: none;
                    }
                    label {
                        font-weight: bold;
                    }
                    ul {
                        margin-left: 15px;
                        margin-top: 0;
                        li a {
                            color: #878787;
                            font-weight: normal;
                            &:hover {
                                background-color: #969696;
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                    }

                }
                .brand-logo {
                    background-color: $white;
                    height: 100%;
                    max-width: 100%;
                    text-align: center;
                    border-left: 1px solid $gray-xlight;
                    padding-bottom: 40px;
                    padding-top: 20px;

                    @media screen and (min-width: $small-width) {
                        padding: 0;
                        top: 50%;
                        position: relative;
                        transform: translateY(-50%);
                    }
                    img {
                         @media screen and (min-width: $small-width) {
                            top: 50%;
                            width: 90%;
                            position: relative;
                            transform: translateY(-50%);
                        }
                    }
                }
                .container-brand-info {
                    background-color: rgba(20,20,20,.15);
                    padding: 0px;
                    padding-bottom: 0!important;
                    text-align: center;
                    @media screen and (min-width: $small-width) {
                        text-align: left;
                        height: 85px;
                    }
                }
                .fits-on {
                    a {
                        color: #EE0000;
                        font-weight: bold;
                    }
                    .fit{
                        list-style: none;
                    }
                    h3 {
                        padding: 7px 7px 7px 0;
                        font-size: 14px;
                        font-weight: bold;
                        background: transparent;
                        color: $color-secondary;
                        text-transform: none;
                        margin-bottom: 0;
                    }
                    .header-fit{
                        color: $color-primary;
                        font-weight: bold;
                    }

                    label {
                        font-weight: bold;
                    }
                    ul {
                        li {
                            padding-top: 3px;
                            ul {
                                margin-left: 15px;
                                margin-top: 0;
                                li a {
                                    color: #878787;
                                    font-weight: normal;
                                    &:hover {
                                        background-color: #969696;
                                        color: #fff;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .container {
                    padding-bottom: 20px;
                }
                .part-info {
                    padding: 0;
                    font-size: 1.125em;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    height: 100%;
                    text-align: left;
                    .partnumber {
                        width: 100%;
                        font-size: 18px;
                        color: $color-primary;
                        font-family: $font-family--secondary;
                        font-weight: bold;
                        border-right: 1px solid $gray-light;
                        @media screen and (min-width: $medium-width) {
                            border-right: 0;
                        }
                    }
                    .partname {
                        word-break: break-word;
                        width: 100%;
                        font-size: 14px;
                        font-weight: bold;
                        font-family: $font-family--secondary;
                        color: $color-secondary;
                        letter-spacing: 2px;
                        @media screen and (min-width: $medium-width) {
                            font-size: 16px;
                        }
                    }
                    .partlabel {
                        color: $gray-dark;
                        font-weight: normal;
                        font-size: 15px;
                    }
                    .qualifier {
                        width: 100%;
                        padding: 0px 5px;
                        font-size: 18px;
                    }
                }
                .part-spec {
                    padding: 10px 0;
                    font-weight: normal;
                    .infos {
                        color: $color-secondary;
                        .full-width {
                            width: 100%;
                        }
                        h3 {
                            padding: 10px 7px 10px 0px;
                            font-size: 14px;
                            font-weight: bold;
                            background: transparent;
                            color: $color-secondary;
                            text-transform: none;
                        }
                        label {
                            font-weight: bold;
                            color: $black;
                            display: block
                        }
                        .note {
                            width: 100%;
                            color: $gray-base;
                        }
                        .position {
                            color: $gray-base;
                            margin-bottom: 15px;
                        }

                        .position,.note,.qualifier,.qty {
                            color: $gray-base;
                            @include flex();
                            flex-wrap: nowrap;
                            h3{
                                flex:none;
                                margin: 0;
                            }
                            span{
                                padding: 10px 0;
                            }
                        }
                    }
                    .description{
                        h3 {
                            padding: 10px 0px;
                            font-size: 18px;
                            font-weight: normal;
                            background: transparent;
                            color: $color-secondary;
                            text-transform: none;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .part-right{
                text-align: center;
                .cost{
                    font-size: 18px;
                    overflow: hidden;
                    width: 100%;
                    height: 0;
                    text-align: right;
                    transition: all .5s ease;
                }
                 .container-price {
                    text-align: center;
                    margin: 20px 0;
                    @media screen and (min-width: $medium-width) {
                        margin: 0px;
                        text-align: left;
                    }
                    .call-price{
                        text-align: center;
                         @media screen and (min-width: $medium-width) {
                            text-align: right;
                         }
                        &:hover{
                            .cost{
                                height: 25px;
                            }
                        }
                    }
                }
                .item-added{
                    font-size: 10px;
                    @extend %transition-base;
                }
                .part-quantity {
                    padding-top: 15px;
                    min-height: 80px;
                    max-height: 105px;
                    @media screen and (min-width: $medium-width) {
                        max-height: inherit;

                    }
                    .price-range {
                        font-weight: bold;
                        font-size: 30px;
                        color: $color-primary;
                        display: block;
                        text-align: center;
                        @media screen and (min-width: $medium-width) {
                            text-align: right;
                       }
                       small{
                            font-size: 40%;
                       }
                    }
                    .unit-price {
                        font-weight: bold;
                        font-size: 30px;
                        color: $color-primary;
                        display: block;
                        text-align: center;
                        @media screen and (min-width: $medium-width) {
                            text-align: right;
                       }
                        &:hover{
                            .cost{
                                height: 25px;
                            }
                        }
                    }
                    .deposit{
                        @media screen and (min-width: $medium-width) {
                            text-align: right;
                        }
                    }
                    /*.quantity-control {
                        border: 1px solid $gray-light;
                        background-color: $white;
                        text-transform: uppercase;
                        padding-left: 5px;
                        color: $color-secondary;
                        width: 105px;
                        margin: 20px auto;
                        height: 40px;
                        display: inline-flex;
                        input.error {
                            border: 1px solid red;
                            color: red;
                        }
                    }*/
                    input[type=number] {
                        color: $color-primary;
                        font-family: $font-family--secondary;
                        font-weight: bold;
                        font-size: 20px;
                        padding: 5px 10px;
                        -moz-appearance: textfield;
                        -webkit-appearance: textfield;
                        appearance: textfield;
                    }
                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                    }
                    .save, .availability,.cost-price {
                        display: block;
                        color: $color-secondary;
                        @media screen and (min-width: $medium-width) {
                            text-align: right;
                        }
                    }
                }
            }
        }
        button {
            font-size: 17px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            cursor: pointer;
            @media screen and (min-width: $small-width) {
                width: 50%
            }
            @media screen and (min-width: $medium-width) {
                width: 100%;
            }
        }
        .part-overlay {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: block;
            background-color: $black;
            opacity: 0.5;
            z-index: 100;
            transition: all 620ms ease;
            &-message {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: block;
                z-index: 100;
                font-size: 16px;
                opacity: 1;
                .overlay-box {
                    border-radius: 2px;
                    background-color: #fff;
                    height: 100px;
                    max-width: 350px;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                    line-height: 100px;
                    vertical-align: middle;
                    .fa{
                        margin-right: 10px;
                    }
                    .success {
                        color: $color-secondary;
                    }
                    .error {
                        color: $color-primary;
                    }
                }
            }
            &-loading {
                position: absolute;
                top: 40%;
                left: 48%;
                display: block;
                z-index: 100;
                font-size: 60px;
                opacity: 1;
                color: #fff;
            }
        }
         .vehicle-specific {
            color: $color-secondary;
            padding: 4px;
            display: inline-block;
            font-family: $font-family--secondary;
            text-transform: uppercase;
            font-size: 8px;
            width: 100%;
            text-align: center;
            border-radius: 3px;
            margin-top: 5px;
            margin-right: 5px;
            font-weight: bold;
            @media screen and (min-width: $small-width) {
                //margin-bottom: 10px;
                width: 60px;
                float: right;
                margin-top: 3px;
                width: 80px;
                font-size: 10px;
            }
        }

    }

    .fa-minus-circle,
    .fa-plus-circle {
        float: left;
        font-size: 25px;
    }
    .fixed-element{
        width: 100%;
    }
     h3 {
        color: $white;
        background-color: $color-secondary;
        padding: 15px;
        margin-bottom: 10px;
        text-align: center;
    }
    h4 {
        padding: 10px;
        color: $color-secondary;
    }
    .part-left {
        background-color: $white;
        text-align: center;
        padding: 0;
        @media screen and (min-width: $small-width) {
            max-height: inherit;
        }
        .part-thumb {
            display: block;
            width: 100%;
            text-align: center;
            padding: 10px;
            img {
                max-width: 100%;
                max-height: 250px;
            }
            .image-gallery{
                  list-style: none;
            }

        }
    }
    .parttype-header {
        width: 100%;
        padding: 10px;
        vertical-align: middle;
        font-size: 23px;
        font-weight: normal;
        background-color: $color-secondary;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        font-family: $font-family--secondary;
        @extend %transition-base;
        &:hover {
            cursor: pointer;
            background-color: $color-primary;
            @extend %transition-base;
        }
    }

     .toolbar {
        .container-button {
            &:first-child {
                padding-left: 0;
                padding-right: 20px;
            }
            button.btn-back {
                padding: 25px;
                background-image: url(/dist/images/arrow-left-white.svg);
                background-repeat: no-repeat;
                background-size: 22%;
                background-position: left 10px center;
                padding-left: 30%;
            }
        }
        .container-categories{
            padding: 0 10px;
            .container-box{
                border: 1px solid #ccc;
                padding: 10px;
                min-height:100%;
                //@include flex(column);
            }
            .icon-instore{
                color:$color-primary;
                &:before {
                    font-size: 34px;
                }
            }
            .icon-delivery{
                color:$color-warning;
                &:before {
                    font-size: 23px;
                }
            }
            .input-checkbox--fake{
                float: none;
                display: inline-block;
                min-width: 18px;
            }
            ul{
                li.flex{
                    max-width: 250px;
                    margin: auto;
                    label.flex{
                        border-bottom: 1px solid #ccc;
                        margin: 5px;
                        padding-bottom: 5px;
                        font-size: 13px;
                    }
                    &:last-child label.flex{
                        border-bottom: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
            

            .listint-pictos__block__picto {
                position: relative;
                width: 60px;
                height: 40px;
                line-height: 30px;
            }

            &__slider-container{
                &__title{
                    font-size: 14px;
                    font-weight: bold;
                    color: #000;
                    text-align: left;
                    margin-left: 40px;
                    //padding: 20px 64px;
                    text-transform: uppercase;
                }
                output{
                    width: 35px;
                    display: inline-block;
                    text-align: left;
                }
                &__slider{
                    -webkit-appearance: none;
                    background: transparent;
                    outline: none;
                    cursor: pointer;
                    border-color: transparent;
                    color: transparent;
                    margin: 20px 0;
                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: 1px solid #000000;
                        height: 36px;
                        width: 16px;
                        border-radius: 3px;
                        background: #ffffff;
                        cursor: pointer;
                        margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
                    }
                    &::-moz-range-thumb {
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border: 1px solid #000000;
                        height: 36px;
                        width: 16px;
                        border-radius: 3px;
                        background: #ffffff;
                        cursor: pointer;
                    }
                    &::-ms-thumb {
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border: 1px solid #000000;
                        height: 36px;
                        width: 16px;
                        border-radius: 3px;
                        background: #ffffff;
                        cursor: pointer;
                    }
                    &::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 8.4px;
                        cursor: pointer;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border-radius: 1.3px;
                        border: 0.2px solid #010101;
                    }
                    &::-moz-range-track {
                        width: 100%;
                        height: 8.4px;
                        cursor: pointer;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                        border-radius: 1.3px;
                        border: 0.2px solid #010101;
                    }
                    &::-ms-track {
                        width: 100%;
                        height: 8.4px;
                        cursor: pointer;
                        background: transparent;
                        border-color: transparent;
                        border-width: 16px 0;
                        color: transparent;
                    }
                    &::-ms-fill-lower {
                        border: 0.2px solid #010101;
                        border-radius: 2.6px;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                    }
                    &::-ms-fill-upper {
                        border: 0.2px solid #010101;
                        border-radius: 2.6px;
                        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                    }
                }
            }

            
        }

        h2 {
            color: $gray-dark;
            background-color: transparent;
        }
    }
}
pre {
    overflow: inherit;
}

.scroll-to-top{
    background-color: $color-secondary;
    width: 60px;
    height: 60px;
    color: $white;
    font-size: 20px;
    text-align: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    padding: 18px;
    display: none;
    z-index: 50;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0,0,0,.5);
    @extend %transition-base;
     @media screen and (min-width: $medium-width) {
        right: 90px;
     }
    &:hover{
        cursor: pointer;
        background-color: $color-primary;
        box-shadow: 0 2px 10px rgba(0,0,0,.2), 0 4px 40px rgba(0,0,0,.3);
        @extend %transition-base;
    }
}
.title--step__text{
    .vehicle-specific{
        color: $white;
        background-color: $color-secondary;
        padding: 4px;
        border-radius: 3px;
        display: inline-block;
        font-size: 7px;
        font-family: $font-family--secondary;
        text-transform: uppercase;
        width: 30px;
        text-align: center;
    }
}
.show-buyersguide{
    input:checked+.slider:not(.disabled) {
        background-color: #000;
    }
}

.tpl--store-locator.map-store-locator-popup{
    position: fixed;
    top: 0;
    left: 100%;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: white;
    z-index: 100;
    img{
        max-width: 100%;
    }
    .banner-image{
        margin: -20px -20px 20px;
        max-height: 210px;
        overflow: hidden;
        position: relative;
        &-popup{
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            background: white;
            box-shadow: 2px 2px 7px rgba(0,0,0,0.3);
            border-radius: 10px;
            &__foot{
                padding: 5px;
               
            }
            button{
                cursor: pointer;
            }
        }
    }
    .map{
        position: relative;
        overflow: hidden;
        height: 250px;
        background: gray;
    }
    .modal--store-info{
        display: block;
        position: static;
        box-shadow: none;
        width: 100%;
        max-height: auto;
        overflow-y: auto;

        hr{display: none;}
    }

    .js-close{
        font-size: 60px;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.4);
    }

    @media screen and (min-width: $medium-width) {
        @include flex();
        overflow: hidden;
        .map{
            height: 100%;
            width: calc(100vw - 400px);
            -webkit-order: 01;
            -ms-flex-order: 01;
            order: 01;
        }
        .modal--store-info{
            width: 400px;
            max-height: 100%;
            overflow: auto;
            box-shadow: 0 10px 10px rgba(0,0,0,.35);
        }
    }
}
