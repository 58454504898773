.price-list-import-line-code-association-container {
    width: 100%;
    margin: 0;

    .select2-selection--multiple {
        max-height: 80px;
        overflow: auto;
    }
    .select2,
    .select2-selection,
    .select2-container {
        width:100% !important;
        min-width: 0 !important;
    }
    .row{
        margin: 0;
        border: 1px solid black;
        
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .col{
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

    }
    .btn{
        margin:0;
    }
}