// ==========================================================================
// Helpers
// ==========================================================================

// Clearfix
.text{
	&--uppercase{
		text-transform: uppercase;
	}
	&--center,&-center{
		text-align: center;
	}
	&-left {
		text-align: left!important;
	}
	
	&-right {
		text-align: right!important;
	}
	
	&-justify {
		text-align: justify;
	}
	&-bold{
		font-weight: bold;
	}
	&-italic{
		font-style: italic;
	}
}

.clear-both{
	clear:both;
}

#intelligent-search{
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background: #fff;
}
.display-block{
	display: block!important;
}
.float--right {
	float: right!important;
}

.container-select-keyword.container-form,.container-select-jobs.container-form {
position: relative;
}
.display-none{
	display:none!important;
}
.container-price{
	.tax,.deposit{
		color:green;
	}
}
.full-height{
	height: 100%!important;
}

.show-alternate{
	cursor: pointer;
	text-transform: uppercase;
	font-weight: bold;
	width: 100%;
	position: relative;
	display: inline-block;
	padding-left:40px;
	//text-align: left;
	&:before{
		width: 10px;
		height: 2px;
		top: 10px;
		left: 20px;
		content:'';
		background: #000;
		display: inline-block;
		position: absolute;
		transition: all .5s ease;

	}
	&:after{
		width: 2px;
		height: 10px;
		top: 6px;
		left: 24px;
		content:'';
		background: #000;
		display: inline-block;
		position: absolute;
		transition: all .5s ease;

	}
	&.active{
		&:before{
			transform: rotate(-180deg);
		}
		&:after{
			transform: rotate(-90deg);
		}
	}
}
.responsive-fix{
	&__show-acessories{
		transform: translate(15px, -50%);
		&__text{
			transform: translate(31px, -50%);
		}
		&__button{
			margin-left: -15px;
		}
	}
}
.display-none{
	display: none;
}

.full-sm{
	@media screen and (max-width:$medium-width) {
		width: 100%;
		font-size: 13px;
	}
}
/*
		Standards Sizes

$large-width       : 1200px;
$container-width   : 1170px;
$medium-width      : 1025px;
$small-width       : 768px;
$xsmall-width      : 480px;

*/

	.responsive{



		&__top-searchar{
			&__lead-page {
				margin: 0!important;
				padding: 0 10px!important;
				&:first-child{
					padding-left: 0!important;
				}
				&:last-child{
					padding-right: 0!important;
				}
			}
			&__full-width-button{
				width: calc(100% - 41px)!important;
				margin-left: 21px!important;
				padding: 17px!important;
			}
			&__container-button{
				&:first-child{
					.responsive__top-searchar__container-button__text{
						right: 20px;
						position: absolute;
						top: 50%;
						left: 43px;
						text-align: right;
						transform: translateY(-50%);
					}
				}
				background-size: contain!important;
				padding: 0 10px!important;
				border-radius: 5px;
				&__search{
					margin-right: 4px;
				}
				&:first-child{
					padding-left: 0!important;
				}
				&:last-child{
					padding: 0px!important;
					margin-right: 0!important;
				}
				&__text-aside {
					margin-left: 43px;
					width: 110px!important;
					margin-top: -1px;
				}
				&__switch {
					top: 4px;
					left: 18px;
				}
				&__button{
					&__text{

					}
				}
			}
		}

	}

@media screen and (max-width: 1640px) {
	.responsive__top-searchar__container-button__resset-text{
		margin-top: -6px;
		display: block;
	}
}

@media screen and (max-width: 1640px) {
	.responsive__top-searchar__container-button__text-aside{
		width: 84px!important;
	}
}
@media screen and (max-width: 1250px) {
	.responsive{
		&__top-searchar{
			&__container-button{
				background-size: contain!important;
				padding: 0 10px!important;
				border-radius: 5px;
				&__search{
					margin-right: 4px;
				}
				&:first-child{
					padding-left: 0!important;
				}
				&:last-child{
					padding: 0px!important;
				}
				&__text-aside {
					margin-left: 27px;
					width: 81px!important;
					margin-top: -1px;
				}
				&__switch {
					top: 4px;
					left: -12px;
				}
				&__button{
					&__text{

					}
				}
			}
		}

	}
}

@media screen and (max-width: 1170px) {
	.responsive__top-searchar__container-button:not(.responsive__top-searchar__container-button--first-child){
		width: calc((100% - 25%) /3 );
	}
	.responsive__top-searchar__container-button__text-aside{
		margin-left: 15px;
	}
	.responsive__top-searchar__container-button:last-child{
		.responsive__top-searchar__container-button__button__text{
			display: block;
			margin-left: -22px;
		}
	}
}
@media screen and (max-width: $medium-width) {
	.js-modify-criteria.modify-criteria {
		margin-bottom: 20px;
	}
	.responsive__top-searchar__lead-page:first-child {
		padding-left: 20px!important;
	}
	.responsive__top-searchar__lead-page:last-child {
		padding-right: 20px!important;
	}
	.container-step2 .toolbar .container-button button.btn-results{
		background-position: calc(100% - 10px)!important;
	}
	.responsive__top-searchar__container-button__resset-text{
		margin-top: 6px;
	}
	.responsive__top-searchar__container-button:last-child{
		.responsive__top-searchar__container-button__button__text{
			width: 120px;
			margin: -30px 10px 16px;
			text-align: left;
		}
	}
	.responsive{
		&__top-searchar{
			&__container-button{
				width:25%!important;
				padding: 0!important;
				&:first-child{
					.responsive__top-searchar__container-button__text{
						right: 20px;
						position: absolute;
						top: 50%;
						left: 43px;
						text-align: right;
						transform: translateY(-50%);
					}




				}
				&:last-child{
					background-position: calc(100% - 10px)!important;
				}
				&__text-aside {
					transform: none;
					top: 10px;
				}
				&__switch {
					margin-left: 10px!important;
				}
				&__search-button{
					background-position: 10px;
				}
				&__button{
					&__text{

					}
				}
			}
		}

	}
}
@media screen and (max-width: $small-width) {
	.responsive__top-searchar__lead-page:first-child {
		padding-left: 10px!important;
	}
	.responsive__top-searchar__lead-page:last-child {
		padding-right: 10px!important;
	}
	.responsive__top-searchar__container-button{
		width:100%!important;
		&__switch{
			width:100%!important;
		}
		&__text-aside{
			width: 100%!important;
			margin: 0!important;
			padding: 0 0 23px!important;
		}
	}
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
	@extend %clearfix;
}


// Center with bootstrap

%col-center {
	float: none;
	margin: 0 auto;
}

.col-center {
	@extend %col-center;
}

select {
   /* -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;*/
}


// Screen reader text

.srt {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Images

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

// Position
	.inlined { display: inline-block; }
    .blocked { display: block; }
	.absolute { position: absolute!important; }
	.static { position: static!important; }


// Width

	.quarter { width: 25%;}
	.fifty   { width: 50%;}
	.seven   { width: 75%;}
	.full    { width: 100%!important;}
	.mw-full { max-width: 100%!important;}

// Height
    .full-height { height: 100%;}


// Align
.centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.text-center-mobile{
	@media screen and (max-width:$medium-width) {
		text-align: center!important;
	}
}

.relative {
	position: relative;
}

.font-family--primary{
    font-family: $font-family--primary;
}
.font-family--secondary{
    font-family: $font-family--secondary;
}
.vertical-center{
    top: 50%;
    position: relative;
    transform: translateY(-50%);
}
.vertical-center-absolute{
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}
// Modifiers
.text-uppercase {
	text-transform: uppercase;
}

.white {
	color: $white!important;
}
.black{
	color: #000;
}
.color--secondary{
    color: $color-secondary;
}

.color--primary{
    color: $color-primary!important;
}
.color--gray-base{
    color: $gray-base;
}

.color--gray-xdark{
    color: $gray-xdark!important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide,.option-hide {
  display: none !important;
}

.show {
  display: block !important;
}

.overflow-hide {
  overflow: hidden;
}

.overflow-show {
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

// For pseudo-elements

%pseudos {
  display: block;
  content: '';
  position: absolute;
}


// Transition
%transition-base {
    transition: all 240ms ease;
}

%transition-slow {
    transition: all 480ms ease;
}

%transition-xslow {
    transition: all 620ms ease;
}

%transition-xxslow {
    transition: all 840ms ease;
}


// Remove default style of list

%unlist {
	list-style-type: none;
}


.vcenter {
	display: table;
    width: 100%;
    height: 100%;
}

.vertical-center-relative {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
}

.vcenter-element {
	display:table-cell;
	vertical-align:middle;
    width: 100%;
    height: 100%;

    &--padding {
    	padding: 10px 0;
    }
}


// Table display
%table {
	display: table;
}

%table-cell {
	display: table-cell;
}

// Spacing
// Exemple: <p class="spacing--xxlarge spacing--0bottom">

.spacing {

	// Modifiers

	&--base {
		padding-top: $spacing-base-vertical;
		padding-bottom: $spacing-base-vertical;
	}

	&--medium {
		padding-top: $spacing-medium-vertical;
		padding-bottom: $spacing-medium-vertical;
	}

	&--large {
		padding-top: $spacing-large-vertical;
		padding-bottom: $spacing-large-vertical;
	}

	&--xlarge {
		padding-top: $spacing-xlarge-vertical;
		padding-bottom: $spacing-xlarge-vertical;
	}

	&--xxlarge {
		padding-top: $spacing-xxlarge-vertical;
		padding-bottom: $spacing-xxlarge-vertical;
	}

	&--jumbo {
		padding-top: $spacing-jumbo-vertical;
		padding-bottom:$spacing-jumbo-vertical;
	}

	&--0top {
		padding-top: 0;
	}

	&--0bottom {
		padding-bottom: 0;
	}
	&--0left {
		padding-left: 0;
	}
	&--0right {
		padding-right: 0;
	}

}

// Spacing 

@mixin breakpoint($point: md) {
	@if $point == xs {
	  @media (max-width: $small-width) { @content ; }
   }
	@else if $point == sm {
	  @media (max-width: $medium-width - 1px) { @content ; }
   }
	 @else if $point == md {
	  @media (min-width: $medium-width) { @content ; }
   }
	@else if $point == lg {
	  @media (min-width: $large-width)  { @content ; }
   }
}

@for $i from 0 through 5{
	/*.m#{$i}{
		margin:   0!important;
	}*/
	$spaceVal: 0;
	@if $i == 1{ $spaceVal:$spacing-base; }
	@else if $i == 2{ $spaceVal:$spacing-medium; }
	@else if $i == 3{ $spaceVal:$spacing-large; }
	@else if $i == 4{ $spaceVal:$spacing-xlarge; }
	@else if $i == 5{ $spaceVal:$spacing-xxlarge; }
	.m#{$i}{ 
		margin: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				margin: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				margin: $spaceVal !important;
			}
		}
	}
	.mt#{$i}{ 
		margin-top: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				margin-top: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				margin-top: $spaceVal !important;
			}
		}
	}
	.mr#{$i}{ 
		margin-right: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				margin-right: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				margin-right: $spaceVal !important;
			}
		}
	}
	.mb#{$i}{
		margin-bottom: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				margin-bottom: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				margin-bottom: $spaceVal !important;
			}
		}
	}
	.ml#{$i}{
		margin-left: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				margin-left: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				margin-left: $spaceVal !important;
			}
		}
	}
	.mx#{$i}{
		margin-left: $spaceVal !important; margin-right: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				margin-left: $spaceVal !important; margin-right: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				margin-left: $spaceVal !important; margin-right: $spaceVal !important;
			}
		}
	}
	.my#{$i}{
		margin-top: $spaceVal !important; margin-bottom: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				margin-top: $spaceVal !important; margin-bottom: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				margin-top: $spaceVal !important; margin-bottom: $spaceVal !important;
			}
		}
	}
	.p#{$i}{
		padding: $spaceVal !important; 
		&-sm{
			@include breakpoint(sm){
				padding: $spaceVal !important; 
			}
		}
		&-md{
			@include breakpoint(md){
				padding: $spaceVal !important; 
			}
		}
	}
	.pt#{$i}{
		padding-top: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				padding-top: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				padding-top: $spaceVal !important;
			}
		}
	}
	.pr#{$i}{
		padding-right: $spaceVal !important; 
		&-sm{
			@include breakpoint(sm){
				padding-right: $spaceVal !important; 
			}
		}
		&-md{
			@include breakpoint(md){
				padding-right: $spaceVal !important; 
			}
		}
	}
	.pb#{$i}{
		padding-bottom: $spaceVal !important; 
		&-sm{
			@include breakpoint(sm){
				padding-bottom: $spaceVal !important; 
			}
		}
		&-md{
			@include breakpoint(md){
				padding-bottom: $spaceVal !important; 
			}
		}
	}
	.pl#{$i}{
		padding-left: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				padding-left: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				padding-left: $spaceVal !important;
			}
		}
	}
	.px#{$i}{
		padding-left: $spaceVal !important; padding-right: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				padding-left: $spaceVal !important; padding-right: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				padding-left: $spaceVal !important; padding-right: $spaceVal !important;
			}
		}
	}
	.py#{$i}{
		padding-top: $spaceVal !important; padding-bottom: $spaceVal !important;
		&-sm{
			@include breakpoint(sm){
				padding-top: $spaceVal !important; padding-bottom: $spaceVal !important;
			}
		}
		&-md{
			@include breakpoint(md){
				padding-top: $spaceVal !important; padding-bottom: $spaceVal !important;
			}
		}
	}

	.ex#{$i}{font-size: 1em + (.5em * $i);}
}

.bordered {
	border: 1px solid $gray-base;
	padding-top: 15px;
	padding-bottom: 15px;
}

.border-top-white{
    border-top: 10px solid $white;
}
.row-centered {
    text-align:center;
}
.col-centered {
    display: inline-block;
    float: none;
}

.fixed-element {
    position:fixed;
    top:0;
    z-index:200;
}

.sticky-nav{
	position:fixed !important;
	top:0;
	z-index:200;
	width: 100% !important;
	&-placeholder{
		display: block;
		width: auto;
		height: 0;
	}
}
.inline-block{
	display: inline-block;
}

.flex{
	flex-direction: row;
	flex-wrap: wrap;
	display: flex;
	align-content: stretch;
	align-items: stretch;
	justify-content: flex-start;

	&-column{
        flex-direction: column;
    }

    &-center{
        align-items:center;
    }

    &-bottom{
        align-items:flex-end;
    }
	&-space-between{
		justify-content: space-between;
	}
    &-no_wrap{
        flex-wrap: nowrap;
    }
    &-j-end{
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    &-j-center{
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
	}
	&-inline{
		display:  inline-flex;
	}

    >*{
		flex-grow: 0;
		flex-shrink: 1;
		order: 0;
		flex-basis: auto;
		align-self: auto;
		float: none;
        &.grow{
            flex-grow: 1;
        }
        &.align-center{
            align-self:center;
        }
        &.align-bottom{
            align-self:flex-end;
        }
        &.align-stretch{
            -webkit-align-self: stretch;
            -ms-flex-item-align: stretch;
            align-self: stretch;
        }
        &.fixed{
            flex: none;
		}
	}
}

.hover {
    &:hover {
        cursor: pointer;
    }
}
.w{
	&-100{
		width: 100%;
	}
	&-sm{
		@include breakpoint(sm){
			&-100{
				width: 100%;
			}
		}
	}
	&-md{
		@include breakpoint(md){
			&-100{
				width: 100%;
			}
		}
	}
}
.h{
	&-100{
		height: 100%;
	}
}
.multi-columns{
	.btn{
		&:after{
			content: none !important;
		}
	}
	columns: 2;
   	-webkit-columns: 2;
	-moz-columns: 2;
	column-gap: $spacing-medium-vertical;

	@media screen and (min-width:$small-width) {
		columns: 3;
   		-webkit-columns: 3;
		-moz-columns: 3;
	}

	@media screen and (min-width:$medium-width) {
		columns: 5;
    	-webkit-columns: 5;
		-moz-columns: 5;
	}
}
ul.multi-columns{
	list-style-type: none;
	li{
		margin: 0 10px;
		@media screen and (min-width:$small-width) {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			height: 30px;
		}
	}
	a:not(.btn){
		display: inline-block;
		min-width: 100%;
		margin: 5px 0;
		&:before{
			content:"";
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 7px 0 7px 7px;
			border-color: transparent transparent transparent $color-primary;
			vertical-align: baseline;
			margin-right: 5px;
		}
	}

	@media screen and (max-width:$small-width - 1px) {
		columns: auto;
   		-webkit-columns: auto;
		-moz-columns: auto;
		display: flex;
		flex-wrap: wrap;
		column-gap: 0;
		li{
			width: 50%;
			margin: 0;
			padding: 0 10px;
		}
	}
}