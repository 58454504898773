.container-step2, .container-step3 {
    background-color: white;
    padding: 0;
    @media screen and (min-width: $small-width) {
        padding: inherit;
    }
    .brands .container-brand, .brands .container-letter-separator {
        width: 30%;
        height: 127px;
         @media screen and (min-width: $small-width) {
            width: 150px;
            margin-left: 9px;
            margin-right: 9px;
            display: block;
            height: 137px;
        }
          @media screen and (min-width: $medium-width) {
            margin-left: 17px;
            margin-right: 17px;
        }
        h3 {
            padding: 15px;
        }
        .letter-separator {
            padding: 15px 0; 
        }
    }
    .container-fluid{
        padding: 0;
    }
    .container-step2 {
        background-color: $white;
        padding: 0px;
        @media screen and (min-width: $small-width) {
            padding-left: 20px;
            padding-right: 20px;
        }
        
    }
    &.reorder-xs{
        @media screen and (max-width:$medium-width){
            @include flex(column);
            >.left-side{
                //order: 1;
            }
            >.right-side{
                //order: 0;
            }
        }
    }
    .dropdown-manufacturer-label{
        display: block;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        //text-transform: capitalize;
        @media screen and (min-width: $medium-width) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .dropdown-manufacturer-chk{
        float: left;
        margin-top: 10px;
        @media screen and (min-width: $medium-width) {
            margin-top: 3px;
        }
    }
    .left-side {
        padding: 0;
        @media screen and (min-width: $small-width) {
            padding: 20px; 
          } 
        .categories {
            font-family: $font-family--secondary;
            font-size: 15px;
            background-color: $gray-light;
            //margin-bottom: 20px;
            > ul > li {
                border-bottom: 1px solid $gray-light;
                list-style: none;
            }
            .manufacturer {
                margin-left: 25px;
            }
            .subcategories{
                li{
                    padding: 2px 10px;
                }
            }
            .subcategories,
            .parttypes,
            .manufacturers {
                margin-left: 15px;
                li {
                    list-style: none;
                }
            }
            .subcategories > li > .submenu-handle > label {
                color: $black;
            }
            .manufacturer label {
                color: $black;
            }
            .submenu-handle {
                display: block;
                cursor: pointer;
                margin-left: 15px;
                padding-bottom: 10px;
                padding-top: 10px;
                width: 95%;
                input, i {
                    font-style: normal;
                   float: left; 
                }
                i{
                    margin-right: 5px;
                }
                label{
                    word-break: break-word;
                    display: block;
                    padding-left: 30px;
                }
            }
        }
        .chk-selected {
            font-weight: bold; 
        }
        .container-partial--latest-promo .container-promo img {
            width: 100%;
            padding: 10px;
        }
        .container-select-jobs {
            padding: 0px 13px 17px;
             @media screen and (min-width: $small-width) {
                background-color: $gray-light;
                border: 1px solid $gray-light;
                margin-top: 15px;
            }
            h3 {
                padding-top: 40px;
                @media screen and (min-width: $small-width) {
                    padding: 10px 0;
                }
            }
        }
        .container-category {
            h3 {
                padding: 15px;
            }
        }
        .container-select-keyword{
            padding: 0 13px 17px;
           
            .select2-selection__arrow b{
                display: none;
            }
        }
        .select2-container--default{
            &:after{
                border-color: #888 transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0;
                content: ' ';
                height: 0;
                right: 7px;
                position: absolute;
                top: 45%;
                width: 0;
            }
             .select2-results>.select2-results__options {
                max-height: 1000px;
            }
        }
        .select2-dropdown{
            nax-height: 400px;
        }
        .select2-selection--single{
            height: inherit;
            //margin: 0 12px;
        }
    }
    .dropdown-template {
        position: absolute;
        display: block;
        height: 250px;
        overflow-y: scroll;
        border: 1px solid #c6c6c6;
        background-color: #fff;
        color: $gray-base;
        z-index: 202;
        width: 85%;
        margin: auto;
        left: 0;
        right: 0;
        position: fixed;
        @media screen and (min-width: $small-width) {
            width: 500px; 
        }

        &-flex{
            overflow-y: hidden;
            @include flex(column);
            >.fixed{
                flex: none;
                width: 100%;
            }
            >.scrollable{
                flex-grow: 1;
                overflow: hidden;
                position: relative;
                width: 100%;

                .scrollable-body{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    overflow: auto;
                }
            }
        }

        .dropdown-template-row {
            padding: 5px 10px;
            span {
                font-family: $font-family--secondary;
                font-size: 13px;
            }
        }
        &.active-search{
            min-height: 207px!important;
            height: calc(100vh/2);
            margin-top: 42px;
        }
    }
    .parttype-pop-footer{

    }
    .fixed-element{
        width: 100%;
    }
    h3 {
        color: #565656;
        padding: 15px 0;
        margin-bottom: 0px;
    }
    h4,.h4 {
        padding: 10px;
        font-family: $font-family--secondary;
        display: inline-block;
    }
    label {
        font-family: $font-family--secondary; 
    }
    .modify-criteria {
        display: inline-block;
        cursor: pointer;
        &:hover {
            color: $color-primary;
        }
         @media screen and (max-width: $small-width) {
            padding-bottom: 20px;
        }
    }
    .partial-selection {
        z-index: 2;
        position: absolute;
        font-size: 24px;
        font-weight: 700;
        left: 7px;
        top: 6px;
        height: 13px;
        line-height: 1px;
        background-color: white;
        pointer-events: none;
    }
    .right-side, .toolbar {
        padding: 0;
          @media screen and (min-width: $small-width) {
            padding: 20px;
          }
          .box--gray {
            border: 0;
         }
        .chk {
            width: 18px;
            height: 18px;
        }
        .container {
            padding: 0;
            @media screen and (min-width: $small-width) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        .container-buttons{
            background-color: $white;
            @extend %transition-base;
            margin-top: 15px;
               @media screen and (min-width: $small-width) {
                    margin-top: 0;
               }
        }
        .container-search-part-number{
            background-color: $gray-light;
            border: 1px solid $gray-light;
            padding: 10px; 
            margin-top: 15px;
             @media screen and (min-width: $small-width) {
                 margin-top: 0px;
             }
             @media screen and (min-width: $medium-width) {
                 margin-top: 15px;
             }
            .go-button-validate{
                color: $gray-base;
                padding: 10px 20px;
                font-weight: 700;
                margin-top: 0;
                text-transform: uppercase;
                background-color: $gray-light;
                @media screen and (min-width: $small-width) {
                     width: inherit;
                }
                &.valid{
                    background-color: $color-primary; 
                    color: $white;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            h3 {
                padding-bottom: 10px!important;
            }
            input[type=text]{
                background-color: white;
                padding: 11px 16px;
                font-family: $font-family--secondary;
                font-size: 14px;
                width: 85%;
                margin-bottom: 5px;
                @media screen and (min-width: $small-width) {
                    width: -moz-calc(100% - 65px);
                    width: -webkit-calc(100% - 65px);
                    width: -o-calc(100% - 65px);
                    width: calc(100% - 65px);
                }
            }
        }
        .container-toggle-applicative {
            font-size: 14px;
            padding: 5px;
            @extend %transition-base;
            text-align: center;
            border: 1px solid $gray-light;
            background-color: $gray-light;
            @media screen and (min-width: $small-width) {
                height: 80px;
                text-align: left;
            }
             @media screen and (min-width: $medium-width) {
                margin-top: 15px; 
            }
            .toggle-button {
                text-align: center;
                font-size: 14px;
                margin-top: 15px;
                 @media screen and (min-width: $small-width) {
                    margin-top: 17px;

                 }
                @media screen and (min-width: $medium-width) {
                     font-size: 16px;
                }
                &--text{
                    font-family: $font-family--secondary;
                    padding: 5px;
                    font-size: 14px;
                    text-align: center;
                    @media screen and (min-width: $medium-width) {
                        top: 50%;
                        position: relative;
                        transform: translateY(-50%);
                        text-align: left;
                    }
                }
            }
        }
        .container-toggle-applicative.disabled{
            background-color: #F5F5F5 !important;
            color: $white;
        }        
        .container-brands{
            padding: 0;
            padding-bottom: 20px;
            .title {
                text-align: center;
                margin-top: 20px;
                @media screen and (min-width: $small-width) {
                    margin-top: 0;
                    text-align: left;
                }
            }
        }
        .container-button {
            display: none;
            @media screen and (min-width: $small-width) {
                display: block;
            }
            @media screen and (min-width: $medium-width) {
                padding-left: 20px;
                padding-right: 20px; 
            }
            button {
                width: 100%;
                margin: 0;
                height: 80px;
                background-color: $color-primary;
                font-weight: bold;
                font-family: $font-family--secondary;
                text-transform: uppercase;
                text-align: left;
                color: #fff;
                font-size: 16.5px; 
                padding: 10px;
                cursor: pointer;
                background-size: 14%;
                background-repeat: no-repeat;
                background-position: right 10px center;
                @extend %transition-base;
                    @media screen and (min-width: $small-width) {
                        width: 98%;
                        height: 80px;
                        padding-top: 50px;
                        text-align: center;
                        background-position: center top 10px;
                        margin: 0;
                    }
                    @media screen and (min-width: $medium-width) {
                        padding: 25px;
                        padding-right: 25%;
                        text-align: left;
                        background-position: right 10px center;
                         margin: 15px 0 0;
                    }
                &.btn-results {
                    background-image: url('/dist/images/arrow-right-white.svg');
                    background-size: contain;
                    background-position: calc(100% - 10px) center;
                }
                &.btn-back {
                    background-image: url('/dist/images/arrow-left-white.svg');
                        background-position: left 10px center;
                        background-size: 22%;
                        padding: 25px 25px 25px 25%;
                        background-size: contain!important;
                }
                &.btn-reset {
                    background-image: url('../images/icon-reset.svg');
                    background-size: 20%;
                }
                &.btn-collapse {
                    position: relative;
                    .fa{
                        position: absolute;
                        right: 5px;
                        font-size: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &.js-collapse {
                        .text-expand{
                            display: none;
                        }
                        .fa:before{
                            content: "\f066";
                        }
                    }
                    &.js-expand {
                        .text-collapse{
                            display: none;
                        }
                        .fa:before{
                            content: "\f065";
                        }
                    }
                }
                i {
                    padding-right: 10px;
                }
            }
            button.disabled {
                cursor: default;
                background-color: $gray-xlight;
            }
        }
        .container-toggle-and-buttons{
            margin: 0;
        }
        .go-button, .go-button-validate{
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            padding: 16px 20px;
            background-color: $gray-light;
            color: #000;
            font-family: $font-family--secondary;
            @media screen and (min-width: $medium-width) {
                padding: 16px 20px;
            }
        }
        .go-button{
             &:hover{
                color: #FFF;
                cursor: pointer;
            }
        }
        .go-button-validate{
            color: $gray-base;
            &:hover{
                cursor: default;
                background-color: $gray-xlight;
                color: $gray-base;
                }
            &.valid{
                background-color: $color-primary; 
                color: $white;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .manufacturers {
            .manufacturer {
                @media screen and (max-width: $small-width) { 
                    margin: 4px;
                }
                @media screen and (min-width: $small-width) { 
                    width: 150px;
                }
                .container-droplist-part-type {
                    background-color: $gray-light;
                    color: $gray-base;
                    top: -20px;
                    height: 35px;
                    position: relative;
                    margin-top: 1px;
                    @extend %transition-base;
                    &:hover{
                        cursor: pointer;
                        background-color: $color-primary;
                        color: white;
                        @extend %transition-base;
                    }
                    @media screen and (min-width: $small-width) { 
                        top: -10px;
                    }
                }
                .dropdown-icon {
                    position: absolute;
                    bottom: 10px;
                    right: 5px;
                    cursor: pointer;
                }
                img {
                    width: 90%;
                    max-height: 90%;
                }
                input[type=checkbox],.input-checkbox--fake {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                }
                span {
                    display: block;
                    text-align: center;
                    font-family: $font-family--secondary;
                    text-transform: uppercase;
                    font-size: 10px;
                    line-height: 10px;
                    font-weight: bold;
                    padding: 5px 13px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                }
            }
        }
        .search-part-number-text{
            width: -moz-calc(100% - 65px);
            width: -webkit-calc(100% - 65px);
            width: -o-calc(100% - 65px);
            width: calc(100% - 65px);
            padding: 15px 10px;
         }

       
    }
    select {
        border: 1px solid #c6c6c6;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        background-color: #FFF;
        color: #878787;
        margin: 0;
        height: 50px; 
        border: 1px solid #878787;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 13px;
        min-width: 100px;
        padding: 0 10px; 
        width: 100%;
         @media screen and (min-width: $small-width) {
             padding: 8px 10px;
         }        
    }

}
.switch {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: auto;
    margin-right: auto;

}
.switch input {
    display:none;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-secondary;
    @extend %transition-base;
    &.disabled {
        background-color: $gray-xlight !important;
        cursor: default;
    }
    &:hover::not(.disabled) {
        background-color: $color-primary;
    }
}

.socials{
    position: absolute;
    top: 40px;
    right: 40px;
    @media screen and (max-width:$small-width ) {
        position: relative;
        padding-bottom: 20px;
        top: 0;
        right: 0;
    }
    .url-copied{
        position: absolute;
        white-space: nowrap;
        right: 0;
        bottom: 100%;
        @media screen and (max-width:$small-width ) {
            right: initial;
            left: 50%;
            transform: translate(-50%,-5px);
        }
    }
    .btn-social{
        font-size: 13px;
        height: 19px !important;
        width: 19px !important;
        padding: 0 2px;
        margin: 0 !important;
        display: inline-block;
        border-radius: 3px;
        line-height: 19px;
        .fa-envelope{
            font-size: 12px;
        }
        @media screen and (max-width:$small-width ) {
            height: 40px !important;
            width: 40px !important;
            line-height: 40px;
        }
    }
}

.categories{
    &__category{
        width: calc((100% / 6) - 10px);
        @media screen and (max-width: $medium-width) {
            width: calc((100% / 3) - 10px);
        }
        @media screen and (max-width: $small-width) {
            width: calc((100% / 2) - 10px);
        }
        margin: 13px 5px;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        float: left;
        height: 223px;
        cursor: pointer;
        position: relative;
        display: block;
        &:after{
            content: '';
            border-color: transparent transparent #333 transparent;
            border-width: 0;
            border-style: solid;
            position: absolute;
            bottom: -19px;
            left: 50%;
            transform: translate(-50%, 0);
        }
        &.active{
            &:after{
                border-width: 0px 11px 11px 11px;
            }
        }
        &:hover{
            .categories__category__img-container__img{
                transform: translate(-50%,-50%) scale(1.05,1.05);
            }
        }
        &__img-container {
            position: relative;
            width: 100%;
            height: 178px;
            overflow: hidden;
            display: block;
        }
        &__title{
            color: #000;
            width: 100%;
            text-align: center;
            margin: 10px 0;
            font-size: 18px;
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;

            text-overflow: ellipsis;
            padding: 0 10px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        &__img-container{
            position: relative;
            &__img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: auto;
                max-width: 80%;
                max-height: 178px;
                transition: all .5s ease;
            }
            &__icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: auto;
                transition: all .5s ease;
                font-size: 100px
            }
        }
    }

    &__brand{
        width: calc((100% / 10) - 10px) !important;
        @media screen and (max-width: $large-width) {
            width: calc((100% / 6) - 10px) !important;
        }
        @media screen and (max-width: $small-width) {
            width: calc((100% / 3) - 10px) !important;
        }
        margin: 13px 5px !important;
        background-color: #fff;
        border: 1px solid #e1e1e1 !important;
        float: left;
        cursor: pointer;
        position: relative;
    }
}

.displayer{
    &__title{
        text-align: left!important;
        transform: none!important;;
        text-transform: uppercase;
        padding: 32px 20px!important;
        font-size: 20px!important;;
    }
    &__content{
        &__manufacturer{
            &__parttype{
                float: left;
                width: calc(100%/5);
                min-height: 41px;
                border: 1px solid #777;
                border-bottom:1px solid #555;
                border-right:1px solid #222;
                cursor: pointer;
                transition: all .5s ease;
                background: #3a3939;
                color:#fff;
                @media screen and (max-width: $container-width) {
                    width: calc(100%/4);
                }
                @media screen and (max-width:$medium-width) {
                    width: calc(100%/2);
                }
                @media screen and (max-width: $small-width) {
                    width: 100%//calc(100%/2);
                }
                    &:hover{
                    background: #444;
                }
            }
        }
        &__sub-category{
            min-height: 41px;
            border: 1px solid #777;
            border-bottom:1px solid #555;
            border-right:1px solid #222;
            cursor: pointer;
            transition: all .5s ease;
            background: #3a3939;
            &__text{
                transition: transform .5s ease;
                color:#fff;
                display: block;
                cursor: pointer;
                text-align: left;
                padding: 10px;
                width: 100%;

            }
            &:hover{
                background: #444;
            }
            &.active{
                background: #444;
                font-weight: bold;
            }
            &__sub-container{
                color:#fff;
                height: 0;
                overflow: hidden;
                transition: all .5s ease;
                font-weight: normal;
                background: #444;
                &.active{
                    .displayer__content__sub-category__sub-container{
                        height: auto;
                    }
                }
                &__button{
                   
                    position: relative;
                    left: 0;
                    transition: all .5s ease;
                    border-bottom: 1px solid #272727;
                    border-top: 1px solid #a7a7a7;
                    &:hover{
                        background: #555;
                    }
                    &:after {
                        top: 0;
                        left: 0;
                        width: calc(100%);
                        height: 1px;
                        content: '';
                        background: #444;
                        position: absolute;
                    }
                    &__text{
                        padding: 10px;
                        color: #fff;
                        display: block;
                    }
                }
            }
        }
    }
}

.slider:not(.ui-slider):before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    @extend %transition-base;
}
.toolbar {
    background-color: $gray-xlight;

}

input:checked + .slider:not(.disabled) {
    background-color: $color-primary;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:not(.disabled):before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.toolbar {
    .container-button {
        .btn-results {
            position: relative;
            @media screen and (min-width: $medium-width) {
                text-align: right;
            }
            .loading-icon {
                position: absolute;
                top: 15%;
                left: 60%;
            }
        }
    }
}
.parttype-pop-header{
     .override-color-gray:before{
        color : $gray-base;
    }
}
.title--step {
    text-align: center; 
    background-color: $gray-xlight;
    color: $color-primary;
    font-size: 45px;
    text-transform: uppercase;
    padding-bottom: 5px;
    letter-spacing: 2px;
  
    .color--secondary {
        display: block;
        @media screen and (min-width: $medium-width) {
            display: inline-block;
        }
    }
    &__text{
        padding: 20px;
        font-size: 30px;
        font-weight: bold;
        color: $gray-xdark;
         @media screen and (min-width: $medium-width) {
            padding: 10px;
         }

    }
}

.btn-mobile-reset, .btn-mobile-getresults, .btn-mobile-back, .btn-mobile-cart{
    background-color: $color-primary;
    width: 60px;
    height: 60px;
    color: $white;
    font-size: 20px;
    text-align: center;
    position: fixed;
    right: 90px;
    bottom: 20px;
    padding-top: 18px;
    z-index: 50;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0,0,0,.5);
    @media screen and (min-width: $medium-width) {
        display: none;
    }
    &.disabled {
        background-color: $gray-xlight;
    }
    &:hover {
        cursor: pointer;
    }
    
}
.btn-mobile-reset {
    left: 90px;
    background-image: url(/dist/images/icon-reset.svg);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
}
.btn-mobile-getresults {
    right: 90px;
    background-image: url(/dist/images/arrow-right-white.svg);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain!important;
}

.btn-mobile-back {
    left: 16px;
    background-image: url(/dist/images/arrow-left-white.svg);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain!important;
}

.btn-mobile-cart {
    right: 270px;
}

#intelligent-search {
    padding: 10px;
    border: 1px solid;
    //position: fixed;
    //width: 500px;
    //top: calc(50% - 86px);
    //margin-top: -42px;
    background: #fff;
    left: 50%;
    width: 100%;
    //max-width: calc(86% - 2px);
    //transform: translate(-50%, -50%);
    //z-index: 100;
}


.container-select-title{
    @include flex;
    padding: 5px;
    justify-content: center;
    h2{
        display: inline;
        vertical-align: middle;
        
        min-width: 100%;
        flex-grow: 1;
        text-align: left;
        margin: 0;
        padding: 0;
        align-self:center;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            min-width: auto;
            text-align: left !important;
        }
    }
}
.container-select-keyword-otto, .container-select-jobs-otto {
    max-width: 330px;
    width: 100%;
    border-radius: 200px;
    margin: 0;
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    @media screen and (max-width: $small-width) {
        height:inherit;
    }
    .fa-search {
        font-size: 40px;
        /*position: absolute;
        top: 50%;
        left: 33px;
        transform: translate(0,-50%);*/
        margin-right: 10px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
        height: 100%;
    }

    b.presentation{
        display: none;
    }
    .select2-container--default:after {
       content:initial!important;
    }
    .select2-container{
        //width: calc(100% - 150px)!important;
        //right: 0;
        //position: absolute;
        //top: 50%;
        //transform: translate(-50px, -50%);
        height: auto;
    }
    /*.select2-selection__rendered{
        text-align: center;
    }*/
    .select2-selection{
        margin: 0!important;
    }
}

.background-section{
    background: $gray-xlight;
    padding: 20px;
    .categories{
        background: transparent !important;
    }
}

.title--step {
    text-align: center; 
    background-color: $gray-xlight;
    color: $color-primary;
    font-size: 45px;
    text-transform: uppercase;
    padding-bottom: 5px;
    letter-spacing: 2px;

    .color--secondary {
        display: block;
        @media screen and (min-width: $medium-width) {
            display: inline-block;
        }
    }
    &__text{
        //background-color: $gray-xlight;
        padding: 10px;
    }
}

.search-top-container{
    padding: 23px 32px;
    margin-bottom: 20px;
    background-position: center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: $small-width) {
        height: inherit;
        &.search-top-container__right{
            //height: 496px;
        }

    }
    &__elements {
        justify-content: center;
        list-style: none;
    }
    &__element {
        cursor:pointer;
        position: relative;
        border-radius: 10px;
        transition: all .5s ease;
        padding: 10px;
        display: none;
        font-size: 12px;
        width: calc(100% / 3);
        background:transparent;
        font-family: $font-family--secondary;

        &:nth-child(-n+3){
            display: block;
        }

        a{
            display: block;
            text-decoration: none;
        }

        .btn{
            &:after{
                display: none;
            }
            margin-left: 5px;
            margin-right: 5px;
        }
        &:hover{
            transform: translate(0,-10px);
            .search-top-container__element__img{
                transform: scale(1.05,1.05);
            }
        }
        
        @media screen and (min-width: $small-width) {
            width: calc(100% / 4);
            &:nth-child(-n+4){
                display: block;
            }
        }

        @media screen and (min-width: $medium-width) {
            font-size: 16px;
            width: calc(100% / 6);
            &:nth-child(-n+6){
                display: block;
            }
        }

        @media screen and (min-width: $large-width) {
            width: calc(100% / 8);
            &:nth-child(-n+8){
                display: block;
            }
        }

        @media screen and (min-width: $xlarge-width) {
            width: calc(100% / 10);
            &:nth-child(-n+10){
                display: block;
            }
        }

        &.show,&.display_all{
            display: block;
        }
        
            
        /*@media screen and (min-width: $xsmall-width) {
            width: calc(100% / 4 - 16px);
        }
        @media screen and (min-width: $large-width) {
            font-size: 16px;
            width: calc(100% / 6 - 16px);
            &:nth-child(-n+6){
                display: block;
            }
        }
        @media screen and (max-width: $large-width) {
            width: calc(100% / 4 - 10px);
            background: #fff;
            margin-left: 5px;
            margin-right: 5px;
        }
        @media screen and (max-width: $small-width) {
            width: calc(100% / 2 - 10px);
            min-height: 249px;
            margin-bottom: 10px!important;
            &[data-goto-job]{
                min-height: 100px;
                .search-top-container__element__text{
                    height: auto;
                }
            }
            .btn.btn--upper.mb1 {
                position: absolute;
                bottom: 0;
                width: 96%;
                left: 2%;
                margin: 0;
            }
        }*/
        &__img-container {
            display: block;
            /*min-height: 157px;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: $small-width) {
                min-height: 130px;
            }*/
        }
        &__img {
            transition: all .5s ease;
        }
        &__text {
            display: block;
            color: #000;
            text-align: center;
            /* height: 30px; */
            padding: 23px 0 0 0;
        }

    }
    &__filter-view{
        background-color: rgba(0,0,0,.32);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 358px;
        z-index: 1;
        @media screen and (max-width: $small-width) {
            height: inherit;

        }
    }
    &__content{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 358px;
        z-index: 2;
        padding: 23px 32px;
        @media screen and (max-width: $small-width) {
            height: inherit;
            position: relative;

        }
        @media screen and (max-width: $large-width) {
            padding: 10px;
        }
    }
    h2{
        text-align: left!important;
        font-size: 27px;
        @media screen and (max-width:$medium-width){
			text-align: center !important;
		}
    }
    &__left{
        h2{
            color:#fff!important;
        }
    }
    &__right{
        background-color: #f2f2f2;
        padding: 20px;
        
    }
    &__left{
        .search-top-container__element__text--left {
            color:#000;
            text-align: center;
        }
    }
}

.ten-row-displayer{
    display: none;
    font-family: $font-family--secondary;
}

.six-row-displayer{
    background: #333;
    width: calc(100% - 10px);
    overflow: hidden;
    height: 0;
    transition: height .5s ease;
    float: left;
    @include flex();
    display: inline-flex;
    font-family: $font-family--secondary;
    &.active{
        height: auto;
        margin: 5px 6px;
        padding: 30px;
    }
}

.three-row-displayer{
    height: 0;
    overflow: hidden;
    @include flex();
    display: inline-flex;
    font-family: $font-family--secondary;

}

.two-row-displayer{
    height: 0;
    overflow: hidden;
    font-family: $font-family--secondary;
}

@media screen and (min-width: calc($large-width + 1px)) {
    .ten-row-displayer{
        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;
        @include flex();
        display: inline-flex;
        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 30px;
        }
    }

    .container-brands .six-row-displayer{
        display: none;
    }
}

@media screen and (min-width: calc($medium-width + 1px)) {
    .three-row-displayer{
        display: none;
    }
}
@media screen and (max-width: $medium-width) {
    .six-row-displayer{
        height: 0!important;
        display: none;
        padding: 0;
    }

    .three-row-displayer{
        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;
        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 30px;

        }
    }

    .container-brands{
        .three-row-displayer{
            &,&.active{
                display: none;
            }
        }
        .six-row-displayer{
            display: block;
            &.active{
                padding: 10px;
                display: block;
                height: inherit!important;
            }
        }
    }
}

@media screen and (max-width: $small-width) {
    .three-row-displayer{
        height: 0!important;
        display: none;
        padding: 0;
    }


    .two-row-displayer{
        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;
        display: block;
        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 10px;
        }
    }

    .container-brands{
        .six-row-displayer{
            &,&.active{
                display: none;  
            }
        }
        .three-row-displayer{
            display: block;
            &.active{
                padding: 10px;
                display: block;
                height: inherit!important;
            }
        }
    }
}
