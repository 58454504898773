.ajax-loading {
	position:fixed; 
	left:0; 
	top:0;
	width:100%; 
	height:100%; 
	display:none; 
	z-index: 9999;

	.lock { 
		position:fixed; 
		left:0; 
		top:0; 
		width:100%; 
		height:120%; 
		display:block; 
		background:transparent; 
	}

	.overlay { 
		position:fixed; 
		left:0; 
		top:0;
		width:100%; 
		height:120%;
		 display:block; 
		 background:#000102;
		 opacity:0.5; 
	}

	.content { 
		display:table;
		height: 100%;
		width: 100%; 
		font-size:0.9em; 

		&__align {
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			text-align: center;
		}
	}

	p { 
		width:160px; 
		margin:auto; 
		padding:10px 20px; 
		text-align:center; 
		color:#252a2d; 
		background:#e2e6e9; 
		border:#252a2d solid 1px; 
		border-radius:7px;
		opacity:0.9; 
	}

	img,
	svg {
		position: relative;
		z-index: 99999;
		
		width : 15%; 
		height: 15%;
		margin: 0 auto;
	}
}
.parttype-select{
	&__loader{
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgb(49, 50, 54);
		position: absolute;
		display: none;
		opacity: 0;
		transition: all .5s ease;
		font-weight: normal;
		&__text{
			text-align: center;
			margin-top: 20px;
			font-size: 16px;
			font-family: "Open sans";
			margin-bottom: 5px;
			&--first{
				margin-top: 10px;
			}
			span{
				display: block;
				font-size: 12px;
				margin-top: 5px;
				margin-bottom: 8px;
			}
		}
		.btn{
			padding: 8px;
			width: 266px!important;
			max-width: 94%;
			display: block;
			margin: auto;
			&:first-child{
				margin-bottom: 10px;
			}
		}
		.fa{
			margin-right: 7px;
		}
		&.active{
			display: inline;
		}
		&.visible{
			opacity: 1;
		}
		&__message{
			padding: 10px;
			background-color: #fff;
			color: #000;
			border: 1px solid #000;
			width: auto;
			display: inline-block;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
