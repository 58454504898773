 .container-partial {
   
    &--step-breadcrumb {
        background-color: $color-primary--darken;
        z-index: 2;
        padding: 0!important;
        //top: 50px;
        position: relative;
        @media screen and (min-width: $small-width){
            top: 0px;
        }
       
        .breadcrumb {
            text-align: center;
            padding: 0;
            clear: both;
            
            .container-step { 
                padding: 10px 5px;
                text-align: center;
                @media screen and (min-width: $medium-width){
                    text-align: right;
                }
                @media screen and (min-width: $medium-width){
                     padding: 25px 50px 25px 25px;
                }
                &:nth-child(n+1) {
                    border-bottom: 1px solid $color-primary--lighten;
                     @media screen and (min-width: $small-width){
                        border-left: 1px solid $color-primary--lighten;
                        border-bottom: 0;
                     }
                }
                
                @media screen and (min-width: 1250px) {
                    position: relative;
                }
                
                &:hover {
                    cursor: pointer;
                    background-color: $color-primary;
                }
                &.inactive {

                    &:hover {
                        cursor: default;
                        background-color: $color-primary--darken;
                    }
                    hr {
                        background-color:  rgba(255, 255, 255, .25);
                    }
                    .container-content-step {
                        color: rgba(255, 255, 255, .25);
                    }
                    .step-click-zone:hover {
                        cursor: default;
                    }
                }
                &.disabled {
                    .step-click-zone:hover{ 
                        cursor: inherit;
                    }
                }
                &.active {
                    background-color: $color-primary;
                } 
              
                .container-content-step {
                    color: $white;
                    font-weight: bold;
                    
                }
                .step {
                    @media screen and (max-width: $small-width) {
                        font-weight: normal;
                        font-size: 14px;
                    }
                }
                .text--big {
                    font-size: 15px;
                    clear: both;
                    @media screen and (min-width: $medium-width) {
                        font-size: 42px;
                    }

                }
            }
            hr {
                display: none;
                @media screen and (min-width: $medium-width) {
                    float: right;
                    height: 1px;
                    width: 30%;
                    background-color: white;
                    margin: auto;
                    display: block;
                }
            }


            .marker {
                display: none;
                 @media screen and (min-width: $small-width){
                    display: block;
                    width: 0px;
                    height: 0px;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-top: 20px solid $color-primary;
                    position: absolute;
                    
                    margin: auto;
                    right: 0;
                    left: 0;
                }
                 @media screen and (min-width: $medium-width){
                    top: 125px;
                 }
            }
            .menu-cart-count {
                font-size: 15px;
                 @media screen and (min-width: $medium-width){
                    font-size: 31px;
                }
            }
            .step-click-zone{
                &:hover{ 
                    cursor: pointer;
                }
            }
            
        }
         &.inactive {
            background-color: $color-primary;
            .container-step {
                &:hover {
                    cursor: default;
                }
            }
        }
        
    }
}  


