main {
    //min-height: calc(100vh - 100px);
    .bg-cheat {
        position: fixed;
        bottom: 0;
        width: 50%;
        height: 50%;
        background-color: $color-primary;  
        right: 0;
    }
    .check {
        display: none;
        z-index: 10;
        position: fixed;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 1100px 90px 0 0px;
        border-color: #fff transparent transparent transparent;
        line-height: 0px;
        _border-color: #fff #000000 #000000 #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
    .container--fixed-content {
        font-family: $font-family--primary; 
        color: black;
        a {
            color: $color-primary;
            cursor: pointer;
        }
        .fixed-slide {
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            width: 95%;
            opacity: 0;
            @extend %transition-xslow;
            &.show {
                opacity: 1;
                @extend %transition-xslow; 
                z-index: 99;
            }
        }
    }
    .container--scroll-content {
        @extend %transition-xslow; 
        .swipe-slide {
            height: 100%;
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            .bar {
                top: 140px;
                position: absolute;
                height: 145px;
                width: 100%;
                 left: 112px;
                 background-repeat: no-repeat;
                background-image: url(/dist/images/bar.svg); 
                @media screen and (max-width: $medium-width) {
                    display: none;
                }
            }
            .title {
                top: 120px;
                position: absolute;
                left: 200px;
                color: white;
                font-weight: bold;
                font-size: 80px;
                line-height: 70px;
                text-transform: uppercase;
                text-shadow: 3px 1px 4px rgba(0,0,0,0.8);
                 @media screen and (max-width: $medium-width) {
                    display: none; 
                }
            }
        }
        .swipe-slide:nth-of-type(1) {
                background-image: url(/dist/images/ipad-mobile.png);
           
            @media screen and (min-width: $medium-width) {
                background-size: 100%; 
                background-image: url(/dist/images/ipad-landing-en.png);
            }
        }
        .swipe-slide:nth-of-type(2) {
            background-image: url(/dist/images/expert-mobile.png);
            background-size: 100% auto;
          
             @media screen and (min-width: $medium-width) {
                background-size: cover;
                background-image: url(/dist/images/expert.png); 
            }
        }
        .swipe-slide:nth-of-type(3) {
            background-image: url(/dist/images/installer-mobile.png);
            background-size: 100% auto;
       
            @media screen and (min-width: $medium-width) {
                background-size: cover;
                background-image: url(/dist/images/installer.png);
            }
        }
        .swipe-slide:nth-of-type(4) {
            background-image: url(/dist/images/basic-mobile.png);
            background-size: 100% auto;
          
            @media screen and (min-width: $medium-width) {
                background-size: cover;
                background-image: url(/dist/images/basic.png);
            }
        }
        .swipe-slide:nth-of-type(5) {
            background-image: url(/dist/images/add-brand-mobile.png);
            background-size: 100% auto;
            @media screen and (min-width: $medium-width) {
                background-size: cover;
                background-image: url(/dist/images/add-brand.png);
            }
        }
         .swipe-slide:nth-of-type(6) {
            background-size: 0;
            @media screen and (min-width: $medium-width) {
                background-size: cover;
                background-image: url(/dist/images/other-ways-en.png);
            }
        }
         .swipe-slide:nth-of-type(7) {
            background-size: 0;
            @media screen and (min-width: $medium-width) {
                background-size: cover;
                background-image: url(/dist/images/netcom-orange-back.png);
            }
        }
    }
}
html:lang(fr){
    .swipe-slide:nth-of-type(1) {
        @media screen and (min-width: $medium-width) {
            background-size: 100%;
            background-image: url(/dist/images/ipad-landing-fr.png);
        }
    }   
}
/*section.container-partial--step-breadcrumb+main{
    min-height: calc(100vh - 198px);
}*/
