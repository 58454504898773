@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?rq6tgk');
  src:  url('../fonts/icomoon.eot?rq6tgk#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?rq6tgk') format('truetype'),
  url('../fonts/icomoon.woff?rq6tgk') format('woff'),
  url('../fonts/icomoon.svg?rq6tgk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instore:before {
  content: "\e900";
  font-size: 24px;
}
.icon-delivery:before {
  content: "\e901";
  font-size: 12px;
}

.icon-wallet,.icon-region,.icon-leaf{
  width: 100%;
  max-width: 125px;
  display: inline-block;
  &:before{
    content: "";
    width: 100%;
    padding-top:100%;
    display: block;
  }
}

.icon-wallet:before{
  background: url(../images/portefeuillegris.png) center center no-repeat;
  background-size: contain;
}


.icon-region:before{
  background: url(../images/economielocalgris.png) center center no-repeat;
  background-size: contain;
}

.icon-leaf:before{
  background: url(../images/environementgris.png) center center no-repeat;
  background-size: contain;
}

.icon-man{
  content: "";
  background: $black;
  //background: url(../images/icon.png) center center no-repeat;
  -webkit-mask-image: url(../images/icon.png);
  mask-image: url(../images/icon.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  width: 70px;
  height: 70px;
  display: inline-block;
}
@for $var from 1 to 18 {
  .icon-why_otto_#{$var}{
    content: "";
    background: $black;
    background-size: contain;
    width: 70px;
    height: 70px;
    display: inline-block;
    -webkit-mask-image: url(../images/why_otto_#{$var}.svg);
    mask-image: url(../images/why_otto_#{$var}.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
}

.icon-svg{
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  -webkit-mask-size: contain;
    mask-size: contain;
}

.icon-primary{
  background: $color-primary;
}

.icon-white{
  background:#fff;
}

.icon-mouse{
  width: 100%;
  max-width: 44px;
  display: inline-block;
  &:before{
    content: "";
    background: url(../images/mousedownorange.png) center center no-repeat;
    background-size: contain;
    width: 100%;
    padding-top:227.27272%;
    display: block;
  }
}
