 .speed-entry{
    border-top: 0;
    position: relative;
    display: inline-block;
    width: 100%;
    @media screen and (min-width: $medium-width) { 
        margin-bottom: 10px!important;
    }
     .btn-download {
        width: 100%;
     }

    .border-top-half{
        display: none;
        position: absolute;
        top: 0;
        width: 50%;
        height: 1px;
        background-color: $gray-base;
        @media screen and (min-width: $medium-width) { 
            display: block;
        }
    }

    .container-button-action{
        @media screen and (min-width: $medium-width) { 
            text-align:right;
            margin-right: 20px;
        }
        button{
            margin-top: 0;
            &.btn-reset {
                width: 100%;
                @media screen and (min-width: $small-width) { 
                    width: inherit;
                }

            }
        }
    }
    .selectbox select {
        height: 50px;
    }
    .container-quantity{
        display: inline-block; 
        border: 1px solid #DCDCDC;
        background-color: #FFF;
        text-transform: uppercase;
        color: #313236;
        padding: 8px 0;
        @media screen and (min-width: $medium-width) { 
            padding: 8px 0;
        }
    }
    .fa-check, .fa-times{
        color: $white;
        font-size: 25px;
        &:hover{
            cursor: default;
            color: $white;
        }
    }
    .fa-circle{
        color: transparent;
        border: 2px solid $gray-base;
        border-radius: 100%;
        width: 40px;
        height: 40px;
    }
    .fa-inverse{
        color: $gray-base;
        font-family: $font-family--secondary;
        width: 95%;
        font-size: 16px;
    }
    .fa-spin{
        font-size: 16px;
        &:hover{
            cursor: default;
            color: $color-secondary;
        }
    }
    .fa-stack{
        display: none;
        margin-right: 15px;
        @media screen and (min-width: $small-width) { 
            display: inline-block;
        }
    }
    .form-repeater{
        position: relative;
        padding: 0;
        
        .btn-submit{
            margin: 20px 0;
            width: 100%;
            @media screen and (min-width: $small-width) {
                width: 50%;
                max-width: 320px;
            }
            &:disabled{
                cursor: default;
                background: $gray-base;
                &:after{
                    background: transparent;
                }
            }
        }
        .btn-reset{
            @media screen and (min-width: $small-width) {
                margin-right: 20px;
            }
        }
    }
    input[type=number]{
        color: $color-primary;
        font-family: Khand,"Helvetica Neue",Helvetica,Arial,sans-serif;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        width: 50px;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        opacity: 1;
    }
    input[type=text]{
         padding: 16px 10px;
         @media screen and (min-width: $medium-width) { 
             padding: 15px 10px;
         }
    }
    .line{
        padding: 0 0 20px 0;

        .container-fields{
            padding: 0;
            padding-bottom: 10px;
            text-align: right;
            @media screen and (min-width: $medium-width) { 
             padding-right: 20px;
             padding-bottom: 0;
         }
        }
        .fa-spin{
            position:absolute; 
            top: 15px; 
            right: 30px;
        }
        
    }
    .selectbox {
        margin-bottom: 0;
        display: inline-block;
        width: 100%!important;
       
        &:after {
            right: 15px!important;
        }
        &.container-quantity{
             width: 55px!important;
           
        }
        .select-linecode{
            @media screen and (min-width: $medium-width) {   
                padding: 9px 10px;
            }
             &:disabled{
                background-color: $gray-light;
             }
        }
       
        &:last-of-type:after{
            border: 0;
        }
    }
 
    .title{
        font-size: 20px;
        color: $white;
        text-align: left;
        border-bottom: 1px solid #878787;
        margin-bottom: 20px;
    }

}

.import-file-notice{
        display: inline-block;
        position: relative;
        padding: 20px 0;
        text-align: left;
       
        @media screen and (min-width: $medium-width) {
            top: 0; 
        }
       
        .fa-file-excel-o{
            font-size: 18px;
            margin-right: 5px;
        }
        p{
            color: $gray-dark;
            font-size: 12px;
            font-family: $font-family--secondary;
            &.color--primary{
                font-style: italic;
                font-size: 11px;
            }
        }
        .file-upload {
            position: relative;
            overflow: hidden;
            background-color: $color-primary;
            color: $white;
            padding: 12px 10px;
            margin-top: 20px;
            font-weight: 700;
            font-family: $font-family--secondary;
            vertical-align: middle;
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
        }
        .file-upload input.upload {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
        }
        .upload-loading-icon, .upload-loading-icon:before{
            font-size: 20px;
        }
    }


